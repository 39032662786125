import { generateBanksWithLogos } from "constants/banks";
import Actions from "redux/actions";
import store from "../../redux";
import ApiManager from "../index";

class Api extends ApiManager {
	constructor() {
		if (!Api.instance) {
			super();
			Api.instance = this;
		}

		return (Api.instance = this);
	}

	getHostUrl = (props = {}) => {
		const onSuccess = (response) => {
			let api = {
				base_url: response.data.host,
				get_methods: response.data.get_methods,
				get_url: response.data.get_url,
			};
			this._updateApiParams(api);
		};
		return this._execute(props, "getHostUrl", onSuccess);
	};

	generalDeclaration = (props = {}) => {
		const onSuccess = (response) => {
			store.dispatch(Actions.setGd(response.data));
		};

		return this._execute(props, "GdRegister", onSuccess);
	};

	getStartLeadInfo = (props) => {
		const onSuccess = (response) => {
			typeof props.onSuccess === "function" && props.onSuccess(response);
		};

		const onFailure = (response) => {
			typeof props.onFailure === "function" && props.onFailure(response);
		};

		props.payload.lead_id = store.getState().leadId;

		return this._execute(props, "GetStartLeadInfo", onSuccess, onFailure);
	};

	getRoleOptions = (props = {}) => {
		const onSuccess = (response) => {
			const rolesArr = [];

			let idx = 1;

			for (let [roleId, roleName] of Object.entries(response.data.roles_options)) {
				rolesArr.push({ text: roleName, id: idx, payload: { roleName: roleName } });
				idx++;
			}

			store.dispatch(Actions.setRoleOptions(rolesArr));
		};

		return this._execute(props, "GetRolesOptoins", onSuccess);
	};

	getOccupations = (props = {}) => {
		const onSuccess = (response) => {
			store.dispatch(Actions.setOccupationAreasOptions(response.data));
			typeof props.onSuccess === "function" && props.onSuccess(response);
		};

		return this._execute(props, "GetOccupations", onSuccess);
	};

	getOtp = (props = {}) => {
		const onSuccess = (response) => {
			typeof props.onSuccess === "function" && props.onSuccess(response);
		};

		const onFailure = (response) => {
			typeof props.onFailure === "function" && props.onFailure(response);
		};

		props.payload.lead_id = store.getState().leadId;

		return this._execute(props, "GetCustomerOtp", onSuccess, onFailure);
	};

	validOtp = (props = {}) => {
		const onSuccess = (response) => {
			typeof props.onSuccess === "function" && props.onSuccess(response);
		};

		const onFailure = (response) => {
			typeof props.onFailure === "function" && props.onFailure(response);
		};

		props.payload.lead_id = store.getState().leadId;

		return this._execute(props, "ValidOtp", onSuccess, onFailure);
	};

	updateBusinessNumberType = (props = {}) => {
		const onSuccess = (response) => {
			typeof props.onSuccess === "function" && props.onSuccess(response);
		};

		const onFailure = (response) => {
			typeof props.onFailure === "function" && props.onFailure(response);
		};

		props.payload.lead_id = store.getState().leadId;

		return this._execute(props, "CheckBusiness", onSuccess, onFailure);
	};

	updateBusinessName = (props = {}) => {
		const onSuccess = (response) => {
			typeof props.onSuccess === "function" && props.onSuccess(response);
		};

		const onFailure = (response) => {
			typeof props.onFailure === "function" && props.onFailure(response);
		};

		props.payload.lead_id = store.getState().leadId;

		return this._execute(props, "BusinessName", onSuccess, onFailure);
	};

	getAddresses = (props = {}) => {
		const onSuccess = (response) => {
			typeof props.onSuccess === "function" && props.onSuccess(response);
		};

		const onFailure = (response) => {
			typeof props.onFailure === "function" && props.onFailure(response);
		};

		props.payload.lead_id = store.getState().leadId;
		props.payload.hideLoader = true;

		return this._execute(props, "GetAddresses", onSuccess, onFailure);
	};

	getPostalCode = (props = {}) => {
		const onSuccess = (response) => {
			typeof props.onSuccess === "function" && props.onSuccess(response);
		};

		const onFailure = (response) => {
			typeof props.onFailure === "function" && props.onFailure(response);
		};

		props.payload.lead_id = store.getState().leadId;

		return this._execute(props, "GetPostalCode", onSuccess, onFailure);
	};

	upsertAddress = (props = {}) => {
		const onSuccess = (response) => {
			typeof props.onSuccess === "function" && props.onSuccess(response);
		};

		const onFailure = (response) => {
			typeof props.onFailure === "function" && props.onFailure(response);
		};

		props.payload.lead_id = store.getState().leadId;

		return this._execute(props, "UpsertAddress", onSuccess, onFailure);
	};

	validateOwner = (props = {}) => {
		const onSuccess = (response) => {
			typeof props.onSuccess === "function" && props.onSuccess(response);
		};

		const onFailure = (response) => {
			typeof props.onFailure === "function" && props.onFailure(response);
		};

		props.payload.lead_id = store.getState().leadId;

		return this._execute(props, "OwnerDetails", onSuccess, onFailure);
	};

	validateContact = (props = {}) => {
		const onSuccess = (response) => {
			typeof props.onSuccess === "function" && props.onSuccess(response);
		};

		const onFailure = (response) => {
			typeof props.onFailure === "function" && props.onFailure(response);
		};

		props.payload.lead_id = store.getState().leadId;

		return this._execute(props, "ValidContact", onSuccess, onFailure);
	};

	confirmCustomerContact = (props = {}) => {
		const onSuccess = (response) => {
			typeof props.onSuccess === "function" && props.onSuccess(response);
		};

		const onFailure = (response) => {
			typeof props.onFailure === "function" && props.onFailure(response);
		};

		props.payload.lead_id = store.getState().leadId;

		return this._execute(props, "CustomersContact", onSuccess, onFailure);
	};

	updateOccupations = (props = {}) => {
		const onSuccess = (response) => {
			typeof props.onSuccess === "function" && props.onSuccess(response);
		};

		const onFailure = (response) => {
			typeof props.onFailure === "function" && props.onFailure(response);
		};

		props.payload.lead_id = store.getState().leadId;

		return this._execute(props, "UpsertOccupationRegister", onSuccess, onFailure);
	};

	generalProductQuestions = (props = {}) => {
		const onSuccess = (response) => {
			typeof props.onSuccess === "function" && props.onSuccess(response);
		};

		const onFailure = (response) => {
			typeof props.onFailure === "function" && props.onFailure(response);
		};

		props.payload.lead_id = store.getState().leadId;

		return this._execute(props, "IsChecked", onSuccess, onFailure);
	};

	generalServiceQuestions = (props = {}) => {
		const onSuccess = (response) => {
			typeof props.onSuccess === "function" && props.onSuccess(response);
		};

		const onFailure = (response) => {
			typeof props.onFailure === "function" && props.onFailure(response);
		};

		props.payload.lead_id = store.getState().leadId;

		return this._execute(props, "ServiceProductQuestions", onSuccess, onFailure);
	};

	numbersQuestions = (props = {}) => {
		const onSuccess = (response) => {
			typeof props.onSuccess === "function" && props.onSuccess(response);
		};

		const onFailure = (response) => {
			typeof props.onFailure === "function" && props.onFailure(response);
		};

		props.payload.lead_id = store.getState().leadId;

		return this._execute(props, "NumbersQuestions", onSuccess, onFailure);
	};

	socialMedia = (props = {}) => {
		const onSuccess = (response) => {
			typeof props.onSuccess === "function" && props.onSuccess(response);
		};

		const onFailure = (response) => {
			typeof props.onFailure === "function" && props.onFailure(response);
		};

		props.payload.lead_id = store.getState().leadId;

		return this._execute(props, "SocialMedia", onSuccess, onFailure);
	};

	getBanks = (props = {}) => {
		props = { ...props, config: { method: "GET" } };

		const onSuccess = (response) => {
			typeof props.onSuccess === "function" && props.onSuccess(response);

			const newBanks = generateBanksWithLogos(response.data);
			store.dispatch(Actions.setBankBrands(newBanks));
		};

		const onFailure = (response) => {
			typeof props.onFailure === "function" && props.onFailure(response);
		};

		return this._execute(props, "GetBanks", onSuccess, onFailure);
	};

	updateBankBrand = (props = {}) => {
		const onSuccess = (response) => {
			typeof props.onSuccess === "function" && props.onSuccess(response);
		};

		const onFailure = (response) => {
			typeof props.onFailure === "function" && props.onFailure(response);
		};

		props.payload.lead_id = store.getState().leadId;

		return this._execute(props, "ValidBank", onSuccess, onFailure);
	};

	updateBankData = (props = {}) => {
		const onSuccess = (response) => {
			typeof props.onSuccess === "function" && props.onSuccess(response);
		};

		const onFailure = (response) => {
			typeof props.onFailure === "function" && props.onFailure(response);
		};

		props.payload.lead_id = store.getState().leadId;

		return this._execute(props, "BankAccounts", onSuccess, onFailure);
	};

	getSecureMeLink = (props = {}) => {
		const onSuccess = (response) => {
			typeof props.onSuccess === "function" && props.onSuccess(response);
		};

		const onFailure = (response) => {
			typeof props.onFailure === "function" && props.onFailure(response);
		};

		props.payload = {
			...props?.payload,
			lead_id: store.getState().leadId,
		};

		return this._execute(props, "GetSecureMeURL", onSuccess, onFailure);
	};

	checkSecureMeStatus = (props = {}) => {
		const onSuccess = (response) => {
			typeof props.onSuccess === "function" && props.onSuccess(response);
		};

		const onFailure = (response) => {
			typeof props.onFailure === "function" && props.onFailure(response);
		};

		const onCatch = (response) => {
			typeof props.onCatch === "function" && props.onCatch(response);
		};

		props.payload = {
			...props?.payload,
			lead_id: store.getState().leadId,
		};

		return this._execute(props, "SecureMeStatus", onSuccess, onFailure, onCatch);
	};

	approveTerms = (props = {}) => {
		const onSuccess = (response) => {
			typeof props.onSuccess === "function" && props.onSuccess(response);
		};

		const onFailure = (response) => {
			typeof props.onFailure === "function" && props.onFailure(response);
		};

		props.payload.lead_id = store.getState().leadId;

		return this._execute(props, "ApproveConditions", onSuccess, onFailure);
	};

	buildBusiness = (props = { payload: {} }) => {
		const onSuccess = (response) => {
			typeof props.onSuccess === "function" && props.onSuccess(response);
		};

		const onFailure = (response) => {
			typeof props.onFailure === "function" && props.onFailure(response);
		};

		props.payload.lead_id = store.getState().leadId;

		return this._execute(props, "BuildBusiness", onSuccess, onFailure);
	};

	uploadOccupationDocument = (props = {}) => {
		const onSuccess = (response) => {
			typeof props.onSuccess === "function" && props.onSuccess(response);
		};

		const onFailure = (response) => {
			typeof props.onFailure === "function" && props.onFailure(response);
		};

		props.payload.lead_id = store.getState().leadId;

		return this._execute(props, "UploadFileOccupation", onSuccess, onFailure);
	};

	getLeadData = (props = {}) => {
		const onSuccess = (response) => {
			typeof props.onSuccess === "function" && props.onSuccess(response);
		};

		const onFailure = (response) => {
			typeof props.onFailure === "function" && props.onFailure(response);
		};

		return this._execute(props, "UpdateOccupation", onSuccess, onFailure);
	};

	updateFailedAu10tix = (props = {}) => {
		const onSuccess = (response) => {
			typeof props.onSuccess === "function" && props.onSuccess(response);
		};

		const onFailure = (response) => {
			typeof props.onFailure === "function" && props.onFailure(response);
		};

		props.payload.lead_id = store.getState().leadId;

		return this._execute(props, "UpdateAuthentication", onSuccess, onFailure);
	};

	uploadManualIdData = (props = {}) => {
		const onSuccess = (response) => {
			typeof props.onSuccess === "function" && props.onSuccess(response);
		};

		const onFailure = (response) => {
			typeof props.onFailure === "function" && props.onFailure(response);
		};

		props.payload.lead_id = store.getState().leadId;

		return this._execute(props, "GetAuthenticationData", onSuccess, onFailure);
	};
}

const instance = new Api();
Object.freeze(instance);
export default instance;
