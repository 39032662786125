import useTranslate from "app/hooks/useTranslate";
import "./page404.css";

const Page404 = () => {
    const translate = useTranslate();

    return (
        <div className="404_wrapper container">
            <h1 className="title">{translate("error_404_text")}</h1>
        </div>
    );
};
export default Page404;
