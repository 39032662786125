import { createSlice } from "@reduxjs/toolkit";

let Slices = [];
let dataReducers = {};
let dataActions = {};

export const gdSlice = createSlice({
    name: "gd",
    initialState: false,
    reducers: {
        setGd: (state, action) => action.payload,
    },
});
Slices.push(gdSlice);

/*---------------------------------------------------------------*/

export const openTextSlice = createSlice({
    name: "openText",
    initialState: false,
    reducers: {
        setOpenText: (state, action) => action.payload,
    },
});
Slices.push(openTextSlice);

/*---------------------------------------------------------------*/

export const roleOptionsSlice = createSlice({
    name: "roleOptions",
    initialState: false,
    reducers: {
        setRoleOptions: (state, action) => action.payload,
    },
});
Slices.push(roleOptionsSlice);

/*---------------------------------------------------------------*/

export const userSlice = createSlice({
    name: "userData",
    initialState: false,
    reducers: {
        setUser: (state, action) => action.payload,
    },
});

// Action creators are generated for each case reducer function
Slices.push(userSlice);

/*---------------------------------------------------------------*/

export const metaTagsSlice = createSlice({
    name: "metaTags",
    initialState: false,
    reducers: {
        setMetaTags: (state, action) => action.payload,
    },
});

// Action creators are generated for each case reducer function
Slices.push(metaTagsSlice);

/*---------------------------------------------------------------*/

export const translationsSlice = createSlice({
    name: "translations",
    initialState: false,
    reducers: {
        setTranslations: (state, action) => action.payload,
    },
});

// Action creators are generated for each case reducer function
Slices.push(translationsSlice);

/*---------------------------------------------------------------*/

export const leadSlice = createSlice({
    name: "leadId",
    initialState: false,
    reducers: {
        setLeadId: (state, action) => action.payload,
    },
});

// Action creators are generated for each case reducer function
Slices.push(leadSlice);

/*---------------------------------------------------------------*/

export const seonSlice = createSlice({
    name: "seonSession",
    initialState: false,
    reducers: {
        setSeonSession: (state, action) => action.payload,
    },
});

// Action creators are generated for each case reducer function
Slices.push(seonSlice);

/*---------------------------------------------------------------*/

export const bankBrands = createSlice({
    name: "bankBrands",
    initialState: [],
    reducers: {
        setBankBrands: (state, action) => action.payload,
    },
});

// Action creators are generated for each case reducer function
Slices.push(bankBrands);

/*---------------------------------------------------------------*/

export const bankBranches = createSlice({
    name: "bankBranches",
    initialState: [],
    reducers: {
        setBankBranches: (state, action) => action.payload,
    },
});

// Action creators are generated for each case reducer function
Slices.push(bankBranches);

/*---------------------------------------------------------------*/

export const occupationAreasOptionsSlice = createSlice({
    name: "occupationAreasOptions",
    initialState: false,
    reducers: {
        setOccupationAreasOptions: (state, action) => action.payload,
    },
});

// Action creators are generated for each case reducer function
Slices.push(occupationAreasOptionsSlice);

//build export objects
for (const Slice of Slices) {
    dataActions = { ...dataActions, ...Slice.actions };
    let reducer = { [Slice.name]: Slice.reducer };
    dataReducers = { ...dataReducers, ...reducer };
}

export { dataActions };
export { dataReducers };
