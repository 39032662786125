import useTranslate from "app/hooks/useTranslate";
import BankIcon from "assets/icons/begin-bank-icon.svg";
import IdIcon from "assets/icons/begin-id-icon.svg";
import GrowLogo from "assets/images/grow-logo.svg";
import Loader from "components/common/loaders/screen";
import Button from "components/forms/button";
import { validateRegistrationData } from "constants/data-validation";
import { CUSTOMER_VALIDATION_OTP } from "constants/input-fields";
import { useEffect } from "react";
import HtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Actions from "redux/actions";
import "./index.scss";

const BeginProcess = () => {
	const translations = useSelector((store) => store.translations);
	const requestingState = useSelector((store) => store.requestingState);
	const signupForm = useSelector((store) => store.signupForm);

	const openText = useSelector((store) => store.openText);
	const isLead = useSelector((store) => store.leadId);
	const isDesktop = useSelector((store) => store.deviceState.isDesktop);
	const isLeadExpired = useSelector((store) => store.isLeadExpired);
	const isLeadOccupationEdit = useSelector((store) => store.isLeadOccupationEdit);
	const isLeadDataReady = useSelector((store) => store.isLeadDataReady);

	const dispatch = useDispatch();
	const translate = useTranslate();

	useEffect(() => {
		dispatch(Actions.setIsInitialPage(true));
		dispatch(Actions.setOtpStateToken(false));

		const phoneNumberKey = CUSTOMER_VALIDATION_OTP.phoneNumber.key;
		const isPhoneNumberInForm = signupForm[phoneNumberKey];

		if (isPhoneNumberInForm) {
			dispatch(Actions.removeFromSignupForm([phoneNumberKey]));
		}

		return () => dispatch(Actions.setIsInitialPage(false));
	}, []);

	// * CHECKING FOR DIFFERENT STATES AND RETURNING TEXT BASED STATE
	const getOpenText = () => {
		if (isLead && !isLeadDataReady) {
			return translate("register_open_loading_text");
		} else if (isLeadExpired) {
			return translate("register_expired_lead_open_text");
		} else if (isLeadOccupationEdit) {
			return translate("register_lead_occupation_edit_open_text");
		}

		return openText || translate("default_register_open_text");
	};

	// * SENDING BASIC DATA TO EVERY COMPONENT THAT MIGHT NEED IT
	const componentsData = {
		openText: getOpenText(),
		showInstructions: isLeadDataReady && isLead && !isLeadExpired && !isLeadOccupationEdit,
	};

	return (
		<div className="begin-process-container">
			{requestingState && translations ? <Loader /> : <></>}
			{isDesktop ? <></> : <MainContent {...componentsData} />}
			{translations ? <BottomSection {...componentsData} /> : <></>}
		</div>
	);
};

const MainContent = (props) => {
	const { openText } = props;
	const translations = useSelector((store) => store.translations);
	const translate = useTranslate();

	return (
		<div className="begin-process-main-wrapper">
			{translations ? (
				<div className="begin-process-main-content">
					<div className="grow-logo">
						<img src={GrowLogo} alt={translate("grow_logo_alt")} />
					</div>

					<div className="begin-process-header">{translate("begin_process_header")}</div>
					<div className="begin-process-content">{HtmlParser(openText)}</div>
				</div>
			) : (
				<></>
			)}
		</div>
	);
};

const BottomSection = (props) => {
	const { openText, showInstructions = false } = props;
	const translate = useTranslate();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const isDesktop = useSelector((store) => store.deviceState.isDesktop);
	const signupForm = useSelector((store) => store.signupForm);

	const handleNextButtonClick = (e) => {
		if (e) {
			e.preventDefault();
		}

		if (!showInstructions) {
			return;
		}

		dispatch(Actions.setIsInitialPage(false));
		const { mainPath, subRoute } = validateRegistrationData(signupForm);
		const fullPath = `/${mainPath}/${subRoute}`;
		navigate(fullPath);
	};

	if (!isDesktop && !showInstructions) {
		return <></>;
	}

	return (
		<>
			<div className="begin-process-bottom-section">
				<div className="begin-process-bottom-wrapper">
					{isDesktop ? <div className="begin-process-content">{HtmlParser(openText)}</div> : <></>}

					{showInstructions && (
						<>
							<div className="begin-process-validate-items">{translate("begin_process_validate_items")}</div>

							<div className="begin-process-items-wrapper">
								<div className="item-wrapper">
									<div className="item-icon">
										<img src={IdIcon} alt={translate("begin_process_valid_id_alt")} />
									</div>

									<div className="item-text">{translate("begin_process_valid_id")}</div>
								</div>

								<div className="item-wrapper">
									<div className="item-icon">
										<img src={BankIcon} alt={translate("begin_process_valid_bank_alt")} />
									</div>

									<div className="item-text">{translate("begin_process_valid_bank")}</div>
								</div>
							</div>
						</>
					)}
				</div>

				{showInstructions && !isDesktop && (
					<Button disabled={!showInstructions} onClick={handleNextButtonClick} isNext>
						{translate("begin_process_start")}
					</Button>
				)}
			</div>

			{showInstructions && isDesktop && (
				<Button disabled={!showInstructions} onClick={handleNextButtonClick} isNext>
					{translate("begin_process_start")}
				</Button>
			)}
		</>
	);
};

export default BeginProcess;
