import { useRef, useState } from "react";
import "./index.scss";

const TextArea = (props) => {
    const { label, placeholder = "", showError, errorMessage, maxLength, onChange, value, name } = props;

    const textareaRef = useRef();
    const [characterCounter, setCharacterCounter] = useState(value.length || 0);

    const handleTextareaChange = () => {
        const textareaLength = textareaRef.current.value.length;

        if (textareaLength > maxLength) {
            return;
        }

        setCharacterCounter(textareaLength);
        onChange(textareaRef.current);
    };

    return (
        <div className="textarea-wrapper">
            <span className="textarea-label">{label}</span>

            <div className="textarea-input-counter-wrapper">
                <textarea ref={textareaRef} className="textarea-input" placeholder={placeholder} value={value} onChange={handleTextareaChange} name={name}></textarea>
                <span className="textarea-character-counter">
                    {characterCounter}/{maxLength}
                </span>
            </div>

            {showError && <div className="textarea-error-message">{errorMessage}</div>}
        </div>
    );
};

export default TextArea;
