import AppConfig from "app/config/AppConfig";
import { validateRegistrationData } from "constants/data-validation";
import { inputFields } from "constants/input-fields";
import routesMap from "constants/route-map";

//returns full path of asset
export function getPath(path) {
    let Config = new AppConfig();
    return Config.debug_mode ? Config.media_path + path : path;
}

//returns full path of asset
export function getMediaPath(path) {
    let Config = new AppConfig();
    return Config.media_path + path;
}

//check for empty objects
export function notEmptyObject(obj) {
    let empty = true;
    if (obj) {
        if (Object.keys(obj).length !== 0 && obj.constructor === Object) {
            empty = false;
        }
    }
    return empty;
}

//Convert Object to sorted array by order_num
export function convertToSortedArray(obj) {
    let sortedArr = Object.keys(obj).map((key) => obj[key]);
    sortedArr = sortedArr.sort((a, b) => {
        return a.order_num - b.order_num;
    });
    return sortedArr;
}

//query browser for user location
export function getUserGeoLocation(onSuccess, onFailure) {
    if ("geolocation" in navigator) {
        // check if geolocation is supported/enabled on current browser
        navigator.geolocation.getCurrentPosition(
            function success(position) {
                // for when getting location is a success
                onSuccess(position);
            },
            function error(error_message) {
                // for when getting location results in an error
                // console.error('An error has occured while retrieving location', error_message);
                onFailure(error_message);
            }
        );
    } else {
        // geolocation is not supported
        // get your location some other way
        console.log("geolocation is not enabled on this browser");
    }
}

//calculate distance in Km between 2 gps sets
export function calcDistance(p1, p2) {
    var R = 6371; // Radius of the earth in km
    var dLat = toRad(p1.lat - p2.lat); // Javascript functions in radians
    var dLon = toRad(p1.lng - p2.lng);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(toRad(p2.lat)) * Math.cos(toRad(p1.lat)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return parseInt(d);
}
function toRad(num) {
    return (num * Math.PI) / 180;
}

//generate unique IDs
export function generateUniqueId(length = 16) {
    var result = [];
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
    }
    return result.join("");
}

export function previousPageObject({ pathname }) {
    let routesObj = generateRoutesObj();
    let path = pathname.split("/");
    let currentSubRoute = path[1] + "/" + path[2];

    let response = { step: 1 };

    const getPreviousPage = () => {
        for (let route in routesObj) {
            for (let key in routesObj[route]) {
                if (route + "/" + key === currentSubRoute) {
                    return response;
                }

                response.sub = key;
                response.main = route;
            }
            response.step = response.step + 1;
        }
    };

    let previousPageResult = getPreviousPage();
    return previousPageResult;
}

export function nextPageObject({ pathname }) {
    let routesObj = generateRoutesObj();
    let path = pathname.split("/");
    let currentSubRoute = path[1] + "/" + path[2];

    let response = { step: 1 };

    const getPreviousPage = () => {
        for (let route in routesObj) {
            for (let key in routesObj[route]) {
                if (route + "/" + key === currentSubRoute) {
                    return response;
                }

                response.sub = key;
                response.main = route;
            }
            response.step = response.step + 1;
        }
    };

    let previousPageResult = getPreviousPage();
    return previousPageResult;
}

function generateRoutesObj() {
    let obj = {};

    for (let route in routesMap) {
        let routePath = routesMap[route].path;

        if (routePath === "*" || routePath === "/") {
            continue;
        }

        obj[routePath] = {};

        let subRoutes = routesMap[route].subRoutes;
        if (subRoutes) {
            for (let subRoute in subRoutes) {
                if (subRoutes[subRoute]?.isRequired) {
                    if (!subRoutes[subRoute].isRequired()) {
                        continue;
                    }
                }
                obj[routePath][subRoute] = subRoute;
            }
        }
    }

    return obj;
}

export function isNumber(value) {
    if (value === "") {
        return true;
    }

    return /^(\s*|\d+)$/.test(value);
}

export const isValidationPathAsCurrent = (resultObj, location) => {
    // * THIS FUNCTIONS CHECKS WETHER THE VALIDATION RESULT PATH IS SAME AS CURRENT PATH
    if (resultObj?.mainPath === location.path && resultObj?.subRoute === location.subRoute) {
        return true;
    }

    return false;
};

export const navigateToNextStep = (result, location, navigateHook) => {
    const currentMain = location.path;
    const currentSubRoute = location.subRoute;
    const routesObj = generateRoutesObj();
    let isSameRoute = false;

    for (let route in routesObj) {
        for (let subRoute in routesObj[route]) {
            if ((route === result.mainPath && subRoute === result.subRoute) || isSameRoute) {
                const fullPath = `/${route}/${subRoute}`;
                navigateHook(fullPath);
                return;
            }

            if (route === currentMain && subRoute === currentSubRoute) {
                isSameRoute = true;
            }
        }
    }

    // if (result && !isValidationPathAsCurrent(result, location)) {
    //     let fullPath = `/${mainPath}/${subRoutePath}`;
    //     navigateHook(fullPath);
    // }
};

export const generateDefaultFormData = (fields) => {
    let formData = {};

    for (let key in fields) {
        if (fields[key]?.key && fields[key]?.name) {
            formData[fields[key].name] = {
                ...(fields[key]?.keyboardType ? { keyboardType: fields[key]?.keyboardType } : ""),
                inputKey: fields[key].key,
                isValid: {},
            };
        }
    }

    return formData;
};

export const convertDateToTimestamp = (date = "") => {
    if (!date) {
        return "";
    }

    try {
        return new Date(date).getTime() / 1000;
    } catch (error) {
        return null;
    }
};

export const convertTimestampToDate = (timestamp = "") => {
    if (!timestamp || isNaN(timestamp)) {
        return "";
    }

    if (Math.abs(Date.now() - new Date(timestamp)) < Math.abs(Date.now() - new Date(timestamp) * 1000)) {
        timestamp = new Date(timestamp);
    } else {
        timestamp = new Date(timestamp * 1000);
    }

    try {
        const dateDay = timestamp.getDate();
        const dateMonth = ("0" + (timestamp.getMonth() + 1)).slice(-2);
        const dateYear = timestamp.getFullYear();

        // Add leading zero to dateDay if necessary
        const stylesDateDay = dateDay < 10 ? `0${dateDay}` : dateDay;

        return `${dateYear}-${dateMonth}-${stylesDateDay}`;
    } catch (error) {
        return "";
    }
};
