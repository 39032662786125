import useTranslate from "app/hooks/useTranslate";
import { CONTACT_TYPES } from "constants/input-fields";
import "./index.scss";

const ContactButtons = () => {
    const translate = useTranslate();

    const handleContactButtonClick = (e, data) => {
        e?.currentTarget?.blur();
        const agent = navigator.userAgent;
        const isAppleDevice = agent.match(/iPhone|iPad|iPod|Mac/i) ? true : false;
        let link;

        if (data?.link) {
            link = data.link;
        } else if (isAppleDevice) {
            link = data?.appleLink;
        } else {
            link = data?.androidLink;
        }

        if (link) {
            window.open(link);
        }
    };

    const drawContactButton = (data) => {
        if (typeof data !== "object") {
            return;
        }

        return (
            <button className="contact-customer-support-button" aria-label={translate(data.alt)} onClick={(e) => handleContactButtonClick(e, data)} key={data.text}>
                <div className="contact-customer-support-button-icon">
                    <img src={data.icon} alt={translate(data.alt)} />
                </div>

                <label className="contact-customer-support-button-text">{translate(data.text)}</label>
            </button>
        );
    };

    return <div className="contact-customer-support-buttons-wrapper">{Object.values(CONTACT_TYPES).map((data) => drawContactButton(data))}</div>;
};

export default ContactButtons;
