import { createSlice } from "@reduxjs/toolkit";

let Slices = [];
let stateReducers = {};
let stateActions = {};

export const deviceSlice = createSlice({
	name: "deviceState",
	initialState: false,
	reducers: {
		setDeviceState: (state, action) => action.payload,
	},
});

// Action creators are generated for each case reducer function
Slices.push(deviceSlice);

/*---------------------------------------------------------------*/

export const loaderSlice = createSlice({
	name: "loaderState",
	initialState: false,
	reducers: {
		setLoader: (state, action) => action.payload,
	},
});

// Action creators are generated for each case reducer function
Slices.push(loaderSlice);

/*---------------------------------------------------------------*/

export const popupsSlice = createSlice({
	name: "popupsArray",
	initialState: [],
	reducers: {
		addPopup: (state, action) => {
			state = state.push(action.payload);
		},
		removePopup: (state) => {
			state = state.pop();
		},
	},
});

// Action creators are generated for each case reducer function
Slices.push(popupsSlice);

/*---------------------------------------------------------------*/

export const requestingSlice = createSlice({
	name: "requestingState",
	initialState: false,
	reducers: {
		requestStarted: (state, action) => true,
		requestEnded: (state, action) => false,
	},
});

// Action creators are generated for each case reducer function
Slices.push(requestingSlice);

/*---------------------------------------------------------------*/

export const burgerSlice = createSlice({
	name: "burgerState",
	initialState: false,
	reducers: {
		setBurger: (state, action) => action.payload,
	},
});

// Action creators are generated for each case reducer function
Slices.push(burgerSlice);

/*---------------------------------------------------------------*/

export const pageScrollerSlice = createSlice({
	name: "pageScrollerState",
	initialState: false,
	reducers: {
		setPageScroller: (state, action) => action.payload,
	},
});

// Action creators are generated for each case reducer function
Slices.push(pageScrollerSlice);

/*---------------------------------------------------------------*/

export const wizardStepsSlice = createSlice({
	name: "wizardSteps",
	initialState: false,
	reducers: {
		setWizardSteps: (state, action) => {
			return { ...state, ...action.payload };
		},
	},
});

// Action creators are generated for each case reducer function
Slices.push(wizardStepsSlice);

/*---------------------------------------------------------------*/

export const au10tixSlice = createSlice({
	name: "isAu10tix",
	initialState: false,
	reducers: {
		setIsAu10tix: (state, action) => action.payload,
	},
});

// Action creators are generated for each case reducer function
Slices.push(au10tixSlice);

/*---------------------------------------------------------------*/

export const iframeSlice = createSlice({
	name: "isIframe",
	initialState: false,
	reducers: {
		setIsIframe: (state, action) => action.payload,
	},
});

// Action creators are generated for each case reducer function
Slices.push(iframeSlice);

/*---------------------------------------------------------------*/

export const initialPageSlice = createSlice({
	name: "isInitialPage",
	initialState: false,
	reducers: {
		setIsInitialPage: (state, action) => action.payload,
	},
});

// Action creators are generated for each case reducer function
Slices.push(initialPageSlice);

/*---------------------------------------------------------------*/

export const leadCompleteSlice = createSlice({
	name: "isLeadComplete",
	initialState: false,
	reducers: {
		setIsLeadComplete: (state, action) => action.payload,
	},
});

// Action creators are generated for each case reducer function
Slices.push(leadCompleteSlice);

/*---------------------------------------------------------------*/

export const contentPageSlice = createSlice({
	name: "isContentPage",
	initialState: false,
	reducers: {
		setIsContentPage: (state, action) => action.payload,
	},
});

// Action creators are generated for each case reducer function
Slices.push(contentPageSlice);

/*---------------------------------------------------------------*/

export const leadExpiredSlice = createSlice({
	name: "isLeadExpired",
	initialState: false,
	reducers: {
		setIsLeadExpired: (state, action) => action.payload,
	},
});

// Action creators are generated for each case reducer function
Slices.push(leadExpiredSlice);

/*---------------------------------------------------------------*/

export const leadInitialDataStateSlice = createSlice({
	name: "isLeadDataReady",
	initialState: false,
	reducers: {
		setIsLeadDataReady: (state, action) => action.payload,
	},
});

// Action creators are generated for each case reducer function
Slices.push(leadInitialDataStateSlice);

/*---------------------------------------------------------------*/

export const editLeadOccupationSlice = createSlice({
	name: "isLeadOccupationEdit",
	initialState: false,
	reducers: {
		setIsLeadOccupationEdit: (state, action) => action.payload,
	},
});

// Action creators are generated for each case reducer function
Slices.push(editLeadOccupationSlice);

/*---------------------------------------------------------------*/

export const otpStateSlice = createSlice({
	name: "otpState",
	initialState: { timeout: false, otpToken: false },
	reducers: {
		setOtpStateTimeout: (state, action) => {
			return { ...state, timeout: action.payload };
		},
		setOtpStateToken: (state, action) => {
			return { ...state, otpToken: action.payload };
		},
		resetOtpState: (state, action) => {
			return { timeout: false, otpToken: false };
		},
	},
});

// Action creators are generated for each case reducer function
Slices.push(otpStateSlice);

/*---------------------------------------------------------------*/

for (const Slice of Slices) {
	stateActions = { ...stateActions, ...Slice.actions };
	let reducer = { [Slice.name]: Slice.reducer };
	stateReducers = { ...stateReducers, ...reducer };
}

export { stateActions };
export { stateReducers };
