import Api from "api/requests";
import useTranslate from "app/hooks/useTranslate";
import ContactButtons from "components/forms/contact_buttons";
import { BOOLEAN_VALUES } from "constants/input-fields";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "redux/actions";
import "./index.scss";

const CongratulationsScreen = ({ fields, location }) => {
    const isIframe = useSelector((store) => store.isIframe);
    const isLeadComplete = useSelector((store) => store.isLeadComplete);
    const dispatch = useDispatch();
    const translate = useTranslate();

    useEffect(() => {
        if (isIframe) {
            window.parent.postMessage({ action: "success", source: "growRegister" }, "*");
        }

        dispatch(Actions.setWizardSteps({ isLastStep: BOOLEAN_VALUES.true }));

        if (!isLeadComplete) {
            const props = {
                payload: {},
                onSuccess: () => dispatch(Actions.setIsLeadComplete(BOOLEAN_VALUES.true)),
            };

            Api.buildBusiness(props);
        }
    }, []);

    return (
        <div className="congratulations-screen-wrapper">
            <div className="congratulations-screen-data">
                <div className="congratulations-screen-image-wrapper">
                    <img src={fields.image.main} alt={translate(fields.image.alt)} />
                </div>

                <div className="congratulations-screen-text-main">{translate(fields.texts.main)}</div>
                <div className="congratulations-screen-text-sub">{translate(fields.texts.sub)}</div>

                <ContactButtons />
            </div>
        </div>
    );
};

export default CongratulationsScreen;
