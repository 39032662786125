import useTranslate from "app/hooks/useTranslate";
import imageUploadIcon from "assets/icons/image_upload.svg";
import "./index.scss";

const UploadButton = (props) => {
    const { className = "", id, name, text, onChange, uploadedName, typesToAccept, showIcon = true, handlePreviewButtonClick } = props;
    const translate = useTranslate();

    const defaultText = text ?? translate("id_upload_button_default");
    const wrapperClassName = `upload-button-wrapper ${className}`.trim();

    const renderButtonContent = () => {
        return (
            <>
                <span className="upload-button-text">{uploadedName ? uploadedName : defaultText}</span>

                {showIcon && (
                    <div className="upload-button-icon-wrapper">
                        <img src={imageUploadIcon} alt="" />
                    </div>
                )}
            </>
        );
    };

    return (
        <div className={wrapperClassName}>
            {uploadedName && handlePreviewButtonClick ? (
                <button className="upload-button-input-wrapper view-document-button" type="button" onClick={handlePreviewButtonClick}>
                    {renderButtonContent()}
                </button>
            ) : (
                <>
                    <label htmlFor={id} className="upload-button-input-wrapper">
                        {renderButtonContent()}
                    </label>

                    <input className="upload-button-input" id={id} name={name} type="file" accept={typesToAccept} onChange={onChange} />
                </>
            )}
        </div>
    );
};

export default UploadButton;
