import Api from "api/requests";
import { generateDefaultFormData, isNumber, navigateToNextStep } from "app/functions";
import useApiError from "app/hooks/useApiError";
import useTranslate from "app/hooks/useTranslate";
import useValidate from "app/hooks/useValidate";
import deleteDocumentIcon from "assets/icons/file_delete_icon.svg";
import Button from "components/forms/button";
import UploadButton from "components/forms/upload-button";
import HeaderTitle from "components/header_title";
import { BOOLEAN_VALUES, DELAY_DEBOUNCE } from "constants/input-fields";
import popupTypes from "constants/popup-types";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Actions from "redux/actions";
import "./index.scss";

const UploadDocuments = ({ fields, location }) => {
    const defaultFormData = generateDefaultFormData(fields);
    const handleServerError = useApiError();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const translate = useTranslate();
    const signupForm = useSelector((store) => store.signupForm);
    const occupationDocuments = useSelector((store) => store.occupationAreaData.documents);

    const [firstTry, setFirstTry] = useState(true);
    const [formData, setFormData] = useState(defaultFormData);
    const validationObj = { defaultFormData, formData, setFormData, location };
    const validateForm = useValidate(validationObj);
    const uploadedFileData = signupForm[fields.uploadDocuments.documentData];

    useEffect(() => {
        const delayDebounce = setTimeout(() => {
            validateForm(false);
        }, DELAY_DEBOUNCE);

        return () => clearTimeout(delayDebounce);
    }, [signupForm]);

    const handleInputChange = (e) => {
        let type = e?.target?.type;
        let name = e?.target?.name || e?.name;
        let val = e?.target?.value || e?.value;
        let key = formData[name]?.inputKey;

        if (type === "checkbox") {
            let isChecked = e.target.checked;
            key = e.target.name;

            if (!isChecked) {
                dispatch(Actions.removeFromSignupForm([key]));
                return;
            }

            val = BOOLEAN_VALUES[isChecked];
        } else if (type === "radio") {
            val = BOOLEAN_VALUES[val == BOOLEAN_VALUES.true];
        } else if (type === "file") {
            const uploadedFile = e.target?.files[0];

            if (!uploadedFile) {
                return;
            }

            const handleBase64ConversionSuccess = (data) => {
                dispatch(Actions.updateSignupForm({ [fields.uploadDocuments.documentData]: { file: uploadedFile, base64: data } }));
            };

            convertToBase64(uploadedFile, handleBase64ConversionSuccess);
        }

        if (formData[name]?.keyboardType === "tel" && !isNumber(val) && val?.length > 0) {
            return;
        }

        dispatch(Actions.updateSignupForm({ [key]: val }));
    };

    const handleNextButtonClick = (e) => {
        if (e) {
            e.preventDefault();
        }

        setFirstTry(false);

        let validationResult = validateForm(true);

        if (validationResult) {
            const onSuccess = (response) => {
                if (response.status === 1) {
                    navigateToNextStep(validationResult, location, navigate);
                }
            };

            const onFailure = (response) => {
                if (response.status === 0) {
                    handleServerError(response);
                }
            };

            const documentFileType = uploadedFileData.file?.type?.split(["/"])[1];

            const props = {
                onSuccess,
                onFailure,
                payload: {
                    [fields.uploadDocuments.apiKeys.documentFile]: uploadedFileData.base64,
                    [fields.uploadDocuments.apiKeys.fileType]: documentFileType,
                },
            };

            Api.uploadOccupationDocument(props);
        }
    };

    const getUniqueArray = (arr, key) => {
        return [...new Map(arr.map((item) => [item[key], item])).values()];
    };

    const setDocumentType = (type) => {
        const subLabel = translate(fields.headerTitle.subLabel).replace("{document_name}", type);
        const uploadLabel = translate(fields.uploadDocuments.label).replace("{document_name}", type);
        return { subLabel, uploadLabel };
    };

    const handlePreviewButtonClick = () => {
        const popupPayload = { type: popupTypes.DOCUMENT_PREVIEW, payload: uploadedFileData };
        dispatch(Actions.addPopup(popupPayload));
    };

    const renderDocumentUploadButton = (documentData) => {
        const documentName = documentData.documentName;

        return (
            <div className="upload-other-documents-button-wrapper">
                <div className="upload-other-documents-button-file">
                    <div className="upload-other-documents-button-label">{setDocumentType(documentName).uploadLabel}</div>

                    <div className="buttons-wrapper">
                        <UploadButton
                            className="upload-button"
                            id={fields.uploadDocuments.id}
                            name={fields.uploadDocuments.name}
                            text={translate(fields.uploadDocuments.defaultText)}
                            typesToAccept={fields.uploadDocuments.typesToAccept}
                            onChange={handleInputChange}
                            uploadedName={uploadedFileData?.file?.name}
                            handlePreviewButtonClick={handlePreviewButtonClick}
                        />

                        {!!uploadedFileData && renderRemoveDocumentButton()}
                    </div>
                </div>

                {!firstTry && formData[fields.uploadDocuments.name].isValid?.valid === false && (
                    <div className="upload-other-documents-button-error-message">{formData[fields.uploadDocuments.name].isValid.errMsg}</div>
                )}
            </div>
        );
    };

    const renderRemoveDocumentButton = () => {
        const handleRemoveButtonClick = () => {
            dispatch(Actions.removeFromSignupForm([fields.uploadDocuments.documentData]));
        };

        return (
            <button className="remove-document-button" type="button" onClick={handleRemoveButtonClick}>
                <img src={deleteDocumentIcon} alt="" />
            </button>
        );
    };

    const convertToBase64 = (file, successCallback) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64File = reader.result.split(",")[1];
            successCallback(base64File);
        };
    };

    const uniqueOccupationDocuments = getUniqueArray(occupationDocuments, "documentName");

    return (
        <form className="upload-other-documents-wrapper" onSubmit={handleNextButtonClick}>
            <div className="upload-other-documents-data">
                <HeaderTitle>{translate(fields.headerTitle.label)}</HeaderTitle>
                {fields.headerTitle.subLabel && (
                    <div className="upload-other-documents-sub-label">{setDocumentType(uniqueOccupationDocuments?.[0]?.documentName ?? "").subLabel}</div>
                )}

                {uniqueOccupationDocuments.length > 0 &&
                    uniqueOccupationDocuments.map((documentData) => <Fragment key={`document_${documentData.id}`}>{renderDocumentUploadButton(documentData)}</Fragment>)}
            </div>

            <Button isNext={true} onClick={handleNextButtonClick} />
        </form>
    );
};

export default UploadDocuments;
