import React from "react"; //React imports
import ReactDOM from "react-dom"; //React imports
import { Provider } from "react-redux"; //Redux HOC
import store from "./redux/index"; //Redux Store
import { BrowserRouter, Router } from "react-router-dom"; //Router
import App from "./App"; //Root component
import deviceState from "./app/device_state/index"; //responsive view states
new deviceState(store.dispatch); //pass redux dispatch to device state

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <App />
        </Provider>
    </BrowserRouter>,
    document.getElementById("root")
);
