import { previousPageObject } from "app/functions";
import { mainRootRoutes } from "constants/main-routes";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import Actions from "redux/actions";
import "./index.scss";

const WizardSteps = () => {
	const location = useLocation();
	const [stepsArr, setStepsArr] = useState();
	const dispatch = useDispatch();
	const wizardSteps = useSelector((store) => store.wizardSteps);
	const currentStep = wizardSteps.currentStep;

	useEffect(() => {
		const totalSteps = Object.keys(mainRootRoutes).length;
		dispatch(Actions.setWizardSteps({ totalSteps: totalSteps }));
		setStepsArr(generateStepsArray(totalSteps));
	}, []);

	useEffect(() => {
		let pageObjectResponse = previousPageObject(location);

		if (pageObjectResponse) {
			dispatch(Actions.setWizardSteps({ currentStep: pageObjectResponse.step }));
		}
	}, [location]);

	function generateStepsArray(totalSteps) {
		let arr = [];

		for (let i = 1; i <= totalSteps; i++) {
			arr.push(i);
		}

		return arr;
	}

	return (
		<div className="steps-wrapper">
			{stepsArr &&
				stepsArr.map((step) => {
					return <div key={step} className={`steps-step${step <= currentStep ? " steps-previous" : ""}`}></div>;
				})}
		</div>
	);
};

export default WizardSteps;
