import { generateDefaultFormData, isNumber, navigateToNextStep } from "app/functions";
import useTranslate from "app/hooks/useTranslate";
import useValidate from "app/hooks/useValidate";
import Button from "components/forms/button";
import TextInput from "components/forms/textInput";
import HeaderTitle from "components/header_title";
import { DELAY_DEBOUNCE } from "constants/input-fields";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Actions from "redux/actions";
import Api from "api/requests";
import "./index.scss";
import useApiError from "app/hooks/useApiError";
import AutoComplete from "components/forms/autocomplete/highlighted-query";

const ContactInfo = ({ fields, location }) => {
    const MAX_SEON_RETRIES = 5;

    const defaultFormData = generateDefaultFormData(fields);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const translate = useTranslate();
    const signupForm = useSelector((store) => store.signupForm);
    const roleOptions = useSelector((store) => store.roleOptions) ?? [];
    const leadId = useSelector((store) => store.leadId);
    const seonSession = useSelector((store) => store.seonSession);

    const [firstTry, setFirstTry] = useState(true);
    const [formData, setFormData] = useState(defaultFormData);
    const validationObj = { defaultFormData, formData, setFormData, location };
    const validateForm = useValidate(validationObj);
    const handleServerError = useApiError();

    useEffect(() => {
        const delayDebounce = setTimeout(() => {
            validateForm(false);
        }, DELAY_DEBOUNCE);

        return () => clearTimeout(delayDebounce);
    }, [signupForm]);

    useEffect(() => {
        if (signupForm?.[fields.contactBusinessRole.key]) {
            const roleId = roleOptions.find((role) => role.text === signupForm[fields.contactBusinessRole.key])?.id ?? false;
            dispatch(Actions.updateSignupForm({ roleId: roleId }));
        }

        configureSeon(1, MAX_SEON_RETRIES, leadId);
    }, []);

    const configureSeon = (retry, maxRetries, leadId) => {
        if (!window.seon) {
            return;
        }

        seon.config({
            host: location.hostname,
            session_id: leadId,
            audio_fingerprint: true,
            canvas_fingerprint: true,
            webgl_fingerprint: true,
            onSuccess: function () {
                seon.getBase64Session(function (data) {
                    if (data) {
                        dispatch(Actions.setSeonSession(data));
                        return;
                    }
                });
            },
            onError: function (message) {
                if (retry < maxRetries) {
                    setTimeout(() => {
                        configureSeon(retry + 1, maxRetries, leadId);
                    }, 1000);
                }
            },
        });
    };

    const handleInputChange = (e) => {
        let type = e?.target?.type;
        let name = e.target.name;
        let val = e.target.value;
        let key = formData[name]?.inputKey;

        if (name === fields.contactBusinessRole.key) {
            dispatch(Actions.removeFromSignupForm(["roleId"]));
        }

        if (type === "autocomplete") {
            let roleId = e.data.id;
            key = fields.contactBusinessRole.key;
            val = e.data.text;
            dispatch(Actions.updateSignupForm({ roleId: roleId }));
        }

        if (formData[name]?.keyboardType === "tel" && !isNumber(val) && val?.length > 0) {
            return;
        }

        dispatch(Actions.updateSignupForm({ [key]: val }));
    };

    const handleNextButtonClick = (e) => {
        if (e) {
            e.preventDefault();
        }

        setFirstTry(false);

        let validationResult = validateForm(true);

        if (validationResult) {
            const onSuccess = (response) => {
                if (response.status === 1) {
                    navigateToNextStep(validationResult, location, navigate);
                }
            };

            const onFailure = (response) => {
                if (response.status === 0) {
                    handleServerError(response);
                }
            };

            const props = {
                onSuccess,
                onFailure,
                payload: {
                    [fields.contactFirstName.key]: signupForm[fields.contactFirstName.key],
                    [fields.contactLastName.key]: signupForm[fields.contactLastName.key],
                    [fields.contactPhoneNumber.apiKey]: signupForm[fields.contactPhoneNumber.key],
                    [fields.contactEmailAddress.apiKey]: signupForm[fields.contactEmailAddress.key],
                    [fields.contactBusinessRole.key]: signupForm["roleId"],
                    token: seonSession,
                },
            };

            Api.validateContact(props);
        }
    };

    return (
        <form className="contact-info-general-wrapper" onSubmit={handleNextButtonClick}>
            <div className="contact-info-general-data">
                <HeaderTitle>{translate(fields.headerTitle.label)}</HeaderTitle>

                <TextInput
                    label={translate(fields.contactFirstName.header)}
                    placeholder={translate(fields.contactFirstName.placeholder)}
                    name={fields.contactFirstName.name}
                    value={signupForm[fields.contactFirstName.key] || ""}
                    onChange={handleInputChange}
                    showError={!firstTry && formData[fields.contactFirstName.name].isValid?.valid === false}
                    errorMessage={formData[fields.contactFirstName.name].isValid?.errMsg}
                />

                <TextInput
                    placeholder={translate(fields.contactLastName.placeholder)}
                    name={fields.contactLastName.name}
                    value={signupForm[fields.contactLastName.key] || ""}
                    onChange={handleInputChange}
                    showError={!firstTry && formData[fields.contactLastName.name].isValid?.valid === false}
                    errorMessage={formData[fields.contactLastName.name].isValid?.errMsg}
                    isShort={true}
                />

                <TextInput
                    label={translate(fields.contactPhoneNumber.label)}
                    placeholder={translate(fields.contactPhoneNumber.placeholder)}
                    name={fields.contactPhoneNumber.name}
                    value={signupForm[fields.contactPhoneNumber.key] || ""}
                    onChange={handleInputChange}
                    showError={!firstTry && formData[fields.contactPhoneNumber.name].isValid?.valid === false}
                    errorMessage={formData[fields.contactPhoneNumber.name].isValid?.errMsg}
                    keyboardType={fields.contactPhoneNumber.keyboardType}
                />

                <TextInput
                    label={translate(fields.contactEmailAddress.label)}
                    placeholder={translate(fields.contactEmailAddress.placeholder)}
                    name={fields.contactEmailAddress.name}
                    value={signupForm[fields.contactEmailAddress.key] || ""}
                    onChange={handleInputChange}
                    showError={!firstTry && formData[fields.contactEmailAddress.name].isValid?.valid === false}
                    errorMessage={formData[fields.contactEmailAddress.name].isValid?.errMsg}
                    keyboardType={fields.contactEmailAddress.keyboardType}
                />

                <AutoComplete
                    className="field"
                    fieldKey={"text"}
                    options={roleOptions}
                    query={signupForm[fields.contactBusinessRole.key] || ""}
                    onChange={handleInputChange}
                    onSelect={handleInputChange}
                    value={signupForm[fields.contactBusinessRole.key] || ""}
                    name={fields.contactBusinessRole.name}
                    label={translate(fields.contactBusinessRole.label)}
                    showError={!firstTry && formData[fields.contactBusinessRole.name].isValid?.valid === false}
                    errorMessage={formData[fields.contactBusinessRole.name].isValid?.errMsg}
                />
            </div>

            <Button isNext={true} onClick={handleNextButtonClick} />
        </form>
    );
};

export default ContactInfo;
