import useTranslate from "app/hooks/useTranslate";
import BeginProcess from "containers/begin_process";
import Header from "containers/header";
import { useSelector } from "react-redux";
import "./index.scss";

const MobileContainer = ({ children }) => {
	const isAu10tix = useSelector((store) => store.isAu10tix);
	const isInitialPage = useSelector((store) => store.isInitialPage);
	const isContentPage = useSelector((store) => store.isContentPage);

	if (isAu10tix) {
		return <div className="au10tix-mobile-container-form">{children}</div>;
	}

	const containerWrapperClassName = `mobile-container-wrapper ${isContentPage ? "auto-height" : ""}`.trim();

	return (
		<div className={containerWrapperClassName}>
			{isInitialPage ? (
				<>
					<BeginProcess />
					<AppVersion />
				</>
			) : (
				<>
					<Header />
					<div className="mobile-container-form">{children}</div>
				</>
			)}
		</div>
	);
};

const AppVersion = () => {
	const translate = useTranslate();
	const appVersion = translate("signup_footer_app_version").replace("{version}", process.env.REACT_APP_SITE_VERSION);

	return <div className="app-version">{appVersion}</div>;
};

export default MobileContainer;
