const popupTypes = {
    GENERAL_MESSAGE: "GENERAL_MESSAGE",
    API_MESSAGE: "API_MESSAGE",
    API_ERROR: "API_ERROR",
    TWO_ACTION: "TWO_ACTION",
    BUSINESS_EXISTS: "BUSINESS_EXISTS",
    DOCUMENT_PREVIEW: "DOCUMENT_PREVIEW",
};

export default popupTypes;
