import { useSelector } from "react-redux";

const useTranslate = () => {
    const translations = useSelector((store) => store.translations);

    const translate = (key) => {
        if (!translations) {
            return "";
        }

        return translations[key] ? translations[key] : key;
    };

    return translate;
};

export default useTranslate;
