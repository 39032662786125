import useTranslate from "app/hooks/useTranslate";
import ContactButtons from "components/forms/contact_buttons";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Actions from "redux/actions";
import errorImage from "../../../assets/images/popup-error.svg";

import "./index.scss";

const TwoActionPopup = ({ payload }) => {
    const { header, text, btnText, isCustomerService } = payload;

    const dispatch = useDispatch();
    const [animationClass, setAnimationClass] = useState("");
    const translate = useTranslate();

    const headerText = header && header ? translate(header) : "";
    const innerText = text && text ? translate(text) : translate("popup_default_error_text");
    const buttonText = btnText && btnText ? translate(btnText) : translate("popup_default_button_text");
    let animationFrame;

    useEffect(() => {
        animateIn();

        return () => {
            cancelAnimationFrame(animationFrame);
        };
    }, []);

    const animateIn = () => {
        animationFrame = requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                setAnimationClass("active");
            });
        });
    };

    const completeAnimation = () => {
        if (animationClass !== "exit" && animationClass !== "done") {
            setAnimationClass("done");
        }
    };

    /* first remove the active class for exit animation then call the callback and remove the popup */
    const animateOut = () => {
        let callback = payload?.callback;

        setAnimationClass("exit");

        setTimeout(() => {
            if (callback) {
                callback();
            }

            cancelAnimationFrame(animationFrame);
            dispatch(Actions.removePopup());
        }, 200);
    };

    return (
        <div className={"backdrop general-msg " + animationClass} onClick={animateOut} onTransitionEnd={completeAnimation}>
            <div className={"popup_wrapper " + animationClass} onClick={(e) => e.stopPropagation()}>
                <div className="popup_error_image">
                    <img src={errorImage} alt={translate("popup_error_image_alt")} />
                </div>

                <div className="popup_inner_wrapper">
                    {header && <div className="popup_header_text">{headerText}</div>}
                    <div className="popup_text">{innerText}</div>

                    {isCustomerService && <ContactButtons />}

                    {!isCustomerService && (
                        <div className={"buttons-container"}>
                            <button className="button accept-btn teal-button" onClick={() => animateOut(payload.action1)}>
                                {buttonText}
                            </button>
                            <button className="button accept-btn teal-button" onClick={() => animateOut(payload.action2)}>
                                {buttonText}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TwoActionPopup;
