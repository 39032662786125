import { generateUniqueId } from "app/functions";
import useTranslate from "app/hooks/useTranslate";
import { BOOLEAN_VALUES } from "constants/input-fields";
import { useSelector } from "react-redux";
import "./checkbox.scss";

const Checkbox = (props) => {
    const {
        type = "squared",
        id,
        name,
        label = "",
        subLabel = "",
        value = "",
        onChange,
        showError = false,
        errorMessage = "",
        checked,
        isShort = false,
        options,
        disabled,
        tabIndex = 0,
        fieldKey = "text",
        isOccupationService = false,
    } = props;

    const signupForm = useSelector((store) => store.signupForm);
    const servicesData = useSelector((store) => store.occupationAreaData.services);
    const occupationAreaValidation = useSelector((store) => store.occupationAreaData.validation?.[showError]?.isValid);
    const errorMessageId = `${generateUniqueId(12)}_err`;

    const translate = useTranslate();

    const isStacked = type === "stacked-spaced";
    const isSpaced = type === "spaced";

    const drawCheckboxes = () => {
        return (
            <div className="stacked-checkbox-wrapper">
                {Object.values(options).map((option) => {
                    const isBitwise = option.bitwiseValue > 0 ? BOOLEAN_VALUES.true : BOOLEAN_VALUES.false;

                    const isChecked = () => {
                        if (isBitwise) {
                            return signupForm[option.name] & option.bitwiseValue;
                        }

                        if (isOccupationService) {
                            return servicesData.includes(option.id);
                        }

                        return signupForm[option.key] === BOOLEAN_VALUES.true;
                    };

                    return (
                        <div className={`checkbox-input-wrapper${isChecked() ? " checkbox-checked" : ""}`} key={option.id}>
                            <input
                                className={`checkbox-input ${type}`}
                                type="checkbox"
                                id={option.id}
                                name={option.key || option.name}
                                value={option.value ? option.value : option.name}
                                onChange={(e) => {
                                    onChange(e, option);
                                }}
                                checked={isChecked()}
                                tabIndex={disabled ? -1 : tabIndex}
                                {...(showError ? { "aria-describedby": errorMessageId } : {})}
                            />
                            <label className="checkbox-label" htmlFor={option.id} tabIndex={-1}>
                                {translate(option[fieldKey])}
                            </label>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div className={`checkbox-wrapper${isShort ? " short" : ""}${isStacked || isSpaced ? ` ${type}` : ""}`}>
            {isStacked || isSpaced ? (
                <>
                    <label className="checkbox-label">{label}</label>
                    {subLabel && <span className="checkbox-sub-label">{subLabel}</span>}
                    {drawCheckboxes()}
                </>
            ) : (
                <div className={`checkbox-input-wrapper${checked ? " checkbox-checked" : ""}${type === "squared" ? " squared" : ""}`}>
                    <input
                        className={`checkbox-input ${type}`}
                        type="checkbox"
                        id={id}
                        name={name}
                        value={value}
                        onChange={onChange}
                        checked={checked}
                        disabled={disabled}
                        tabIndex={disabled ? -1 : tabIndex}
                        {...(showError ? { "aria-describedby": errorMessageId } : {})}
                    />
                    <label className="checkbox-label" htmlFor={id} tabIndex={-1}>
                        {label}
                    </label>
                </div>
            )}

            {isOccupationService ? (
                occupationAreaValidation?.valid === false ? (
                    <div className="checkbox-error-message" id={errorMessageId}>
                        {occupationAreaValidation.errMsg}
                    </div>
                ) : (
                    <div className="checkbox-error-message-placeholder"></div>
                )
            ) : (
                showError && (
                    <div className="checkbox-error-message" id={errorMessageId}>
                        {errorMessage}
                    </div>
                )
            )}
        </div>
    );
};
export default Checkbox;
