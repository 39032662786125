import Api from "api/requests";
import { generateDefaultFormData, isNumber, navigateToNextStep } from "app/functions";
import useApiError from "app/hooks/useApiError";
import useTranslate from "app/hooks/useTranslate";
import useValidate from "app/hooks/useValidate";
import AutoComplete from "components/forms/autocomplete/highlighted-query";
import Button from "components/forms/button";
import Checkbox from "components/forms/checkbox";
import RadioButton from "components/forms/radio-button";
import TextInput from "components/forms/textInput";
import HeaderTitle from "components/header_title";
import { BOOLEAN_VALUES, BUSINESS_INFO_TYPE, DELAY_DEBOUNCE } from "constants/input-fields";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Actions from "redux/actions";
import "./index.scss";

const OccupationAreas = ({ fields, location }) => {
    const defaultFormData = generateDefaultFormData(fields);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const translate = useTranslate();
    const signupForm = useSelector((store) => store.signupForm);
    const occupationAreaData = useSelector((store) => store.occupationAreaData);
    const requestingState = useSelector((store) => store.requestingState);
    const mainAreas = useSelector((store) => store.occupationAreasOptions);
    const isLeadOccupationEdit = useSelector((store) => store.isLeadOccupationEdit);

    // * CHECK WETHER OCCUPATION ID EXISTS IN SIGNUP FORM, AND IF SO => HANDLE THE DATA
    const isMainOccupationId = signupForm?.[fields.mainAreas.key];

    const [firstTry, setFirstTry] = useState(true);
    const [formData, setFormData] = useState(defaultFormData);
    const validationObj = { defaultFormData, formData, setFormData, location };
    const validateForm = useValidate(validationObj);
    const handleServerError = useApiError();

    const [stepsArray, setStepsArray] = useState([]);
    const isNonProfitOrganizationType = signupForm[BUSINESS_INFO_TYPE.businessType.key] === BUSINESS_INFO_TYPE.businessType.options.nonProfitOrganization.value;

    useEffect(() => {
        if (firstTry) {
            return;
        }

        const delayDebounce = setTimeout(() => {
            validateForm(false);
        }, DELAY_DEBOUNCE);

        return () => clearTimeout(delayDebounce);
    }, [occupationAreaData.services, occupationAreaData.form, occupationAreaData.autocomplete, occupationAreaData.figure, signupForm]);

    useEffect(() => {
        handleSavedFormData();
    }, []);

    const handleSavedFormData = () => {
        // * THIS FUNCTION GOES OVER THE SAVED FORM DATA AND HANDLES THE UI TO FILL THE DATA
        const findDataById = (obj, id) => {
            for (const item of obj) {
                if (item.id === id) {
                    return item;
                }

                if (item.is_dropdown?.length) {
                    const innerResult = findDataById(item.is_dropdown, id);

                    if (innerResult) {
                        return innerResult;
                    }
                }

                if (item.is_question?.length) {
                    const innerResult = findDataById(item.is_question, id);

                    if (innerResult) {
                        return innerResult;
                    }
                }
            }
        };

        if (!isMainOccupationId) {
            return;
        }

        let previousOccupationData = null;
        const occupationServices = signupForm?.services;
        const occupationQuestions = signupForm?.questions;

        let [mainOccupationData] = mainAreas.filter((occupation) => occupation.id === isMainOccupationId);
        mainOccupationData = { ...mainOccupationData, isSavedForm: BOOLEAN_VALUES.true };
        previousOccupationData = mainOccupationData;

        const mainOccupationSelection = {
            name: fields.mainAreas.key,
            data: mainOccupationData,
            target: {
                type: "autocomplete",
            },
        };

        handleMainOccupationAreaChange(mainOccupationSelection);

        if (mainOccupationData?.is_figure) {
            const val = signupForm?.figure ?? "";
            const name = targetName(mainOccupationData, "inputData");
            dispatch(Actions.setOccupationAreaFigure({ [name]: val }));
        }

        if (occupationServices) {
            occupationServices.forEach((occupation) => {
                if (occupation === signupForm?.[fields.mainAreas.key]) {
                    return;
                }

                const occupationData = findDataById(mainAreas, occupation);

                const eventData = {
                    name: targetName(previousOccupationData),
                    target: {
                        id: occupationData.id,
                        name: occupationData.name,
                        checked: BOOLEAN_VALUES.true,
                        type: previousOccupationData.results === 5 ? "autocomplete" : "checkbox",
                    },
                    data: occupationData,
                };

                handleMainOccupationAreaChange(eventData, occupationData);
                previousOccupationData = occupationData;
            });

            dispatch(Actions.setOccupationAreaServices(occupationServices));
        }

        if (occupationQuestions) {
            occupationQuestions.forEach((occupation) => {
                const occupationData = findDataById(mainAreas, occupation.id ?? occupation.questions_id);

                const eventData = {
                    name: targetName(previousOccupationData),
                    target: {
                        id: occupationData.id,
                        name: occupationData.name,
                        value: occupation.answer,
                        "data-question-id": occupation.id ?? occupation.questions_id,
                        type: "radio",
                    },
                };

                handleMainOccupationAreaChange(eventData, occupationData);
                previousOccupationData = occupationData;
            });
        }
    };

    const handleNextOccupationStep = (e) => {
        const nextStep = e.results;
        const isDropDown = e.is_dropdown;
        const figureName = e.figure_or_file_name;

        switch (nextStep) {
            case 4:
                // * CHOOSE FROM SERVICES
                if (isDropDown) {
                    setInputValidation(e, "checkbox", () => {
                        let services = [];
                        for (let item of isDropDown) {
                            services.push(item.id);
                        }

                        return services;
                    });

                    setStepsArray((prevState) => {
                        return [...prevState, { id: e.id, comp: renderServices(e) }];
                    });
                }
                break;
            case 5:
                // * CHOOSE FROM DROPDOWN

                setInputValidation(e, "dropdown", () => {
                    return e.name;
                });

                setStepsArray((prevState) => {
                    return [...prevState, { id: e.id, comp: renderDropDown(e) }];
                });

                break;
            case 6:
                // * UPLOAD DOCUMENT
                dispatch(Actions.setOccupationAreaDocuments({ id: e.id, documentName: figureName }));
                break;
            case 7:
                // * ADD DATA
                setInputValidation(e, "inputData", () => {
                    return new RegExp(/^\d{4,}$/);
                });

                setStepsArray((prevState) => {
                    return [...prevState, { id: e.id, comp: renderData(e) }];
                });

                break;
            case 8:
                // * ADD DATA AND CHOOSE FROM SERVICES

                setInputValidation(e, "checkbox", () => {
                    let services = [];
                    for (let item of isDropDown) {
                        services.push(item.id);
                    }

                    return services;
                });

                setInputValidation(e, "inputData", () => {
                    return new RegExp(/^\d{4,}$/);
                });

                setStepsArray((prevState) => {
                    return [...prevState, { id: e.id, comp: renderServicesAndData(e) }];
                });

                break;
            case 9:
                // * UPLOAD DOCUMENT AND CHOOSE FROM SERVICES
                setInputValidation(e, "checkbox", () => {
                    let services = [];
                    for (let item of isDropDown) {
                        services.push(item.id);
                    }

                    return services;
                });

                setStepsArray((prevState) => {
                    return [...prevState, { id: e.id, comp: renderServices(e) }];
                });

                dispatch(Actions.setOccupationAreaDocuments({ id: e.id, documentName: figureName }));
                break;
            case 10:
                // * ASK FOR BUSINESS DESCRIPTION
                dispatch(Actions.setOccupationAreaData({ businessDescription: BOOLEAN_VALUES.true }));
                break;
            case 11:
                // * CHOOSE FROM SERVICES

                setInputValidation(e, "checkbox", () => {
                    let services = [];
                    for (let item of isDropDown) {
                        services.push(item.id);
                    }

                    return services;
                });

                setStepsArray((prevState) => {
                    return [...prevState, { id: e.id, comp: renderServices(e) }];
                });

                dispatch(Actions.setOccupationAreaData({ businessDescription: BOOLEAN_VALUES.true }));

                break;
            case 13:
                // * ADD QUESTIONS

                setInputValidation(e, "radio", () => {
                    let questions = [];
                    for (let item of e.is_question) {
                        questions.push(item.id);
                    }

                    return questions;
                });

                setStepsArray((prevState) => {
                    return [...prevState, { id: e.id, comp: renderQuestions(e) }];
                });

                break;
        }
    };

    const handleInputChange = (e) => {
        let type = e?.target?.type;
        let name = e?.target?.name || e?.name;
        let val = e?.target?.value || e?.value;
        let key = formData[name]?.inputKey ?? name;

        if (type === "checkbox") {
            let isChecked = e.target.checked;
            key = e.target.name;

            if (!isChecked) {
                dispatch(Actions.removeFromSignupForm([key]));
                return;
            }

            val = BOOLEAN_VALUES[isChecked];
        } else if (type === "radio") {
            val = BOOLEAN_VALUES[val == BOOLEAN_VALUES.true];
        }

        if (formData[name]?.keyboardType === "tel" && !isNumber(val) && val?.length > 0) {
            return;
        }

        dispatch(Actions.updateSignupForm({ [key]: val }));
    };

    const handleMainOccupationAreaChange = (e, additionalData = null) => {
        let type = e?.target?.type;
        let name = e?.target?.name || e?.name;
        let val = e?.data?.name;
        let datasetType = e?.target?.dataset?.type;

        // * RESET THE ARRAY IF SELECTED FROM MAIN AREA
        if (name === fields.mainAreas.name) {
            setStepsArray([]);
            dispatch(Actions.resetOccupationAreaData({}));
        }

        if (type === "checkbox") {
            if (!e.target.checked) {
                const occupationId = parseInt(e.target.id);

                dispatch(Actions.removeOccupationAreaDocumentsById(occupationId));
                removeNextStep(occupationId, mainAreas);
                removeNextValidation(e.target);
            } else {
                handleNextOccupationStep(additionalData);
            }

            val = e.target.value;
            dispatch(Actions.setOccupationAreaServices(parseInt(e.target.id)));
        } else if (type === "tel" || (type === "text" && datasetType !== "autocomplete")) {
            val = e.target.value;

            if (type === "tel" && !isNumber(val) && val?.length > 0) {
                return;
            }

            dispatch(Actions.setOccupationAreaFigure({ [name]: val }));
        } else if (type === "text" && datasetType === "autocomplete") {
            val = e.target.value;
            const id = parseInt(e.target.dataset?.selected);

            if (id) {
                removeNextStep(parseInt(id), mainAreas);
            }

            dispatch(Actions.removeAutoCompleteSelection(name));
            dispatch(Actions.setOccupationAreaForm({ [name]: val }));
        } else if (type === "autocomplete") {
            const name = e.name;
            const id = e.data.id;

            dispatch(Actions.setAutoCompleteSelection({ key: name, id: id }));
            dispatch(Actions.setOccupationAreaForm({ [name]: val }));
            handleNextOccupationStep(e.data);
        } else if (type === "radio") {
            val = e.target.value;
            val = BOOLEAN_VALUES[val == BOOLEAN_VALUES.true];

            const questionId = e.target["data-question-id"] ?? e.target.getAttribute("data-question-id");
            dispatch(Actions.setOccupationAreaQuestion({ key: questionId, val: val }));
        } else {
            dispatch(Actions.setOccupationAreaForm({ [name]: val }));
            handleNextOccupationStep(e?.data ?? e);
        }
    };

    const removeNextStep = (removedId, object, isFound = false) => {
        const removeStep = () => {
            dispatch(Actions.removeOccupationAreaDocumentsById(removedId));

            setStepsArray((prevState) => {
                return prevState.filter((item) => parseInt(item.id) !== removedId);
            });
        };

        if (isFound && Array.isArray(object) && object.length === 0) {
            removeStep();
            return;
        }

        for (let item of object) {
            if (isFound) {
                removeStep();
                return;
            }

            if (item.is_dropdown && typeof item.is_dropdown === "object" && !isFound) {
                removeNextStep(removedId, item.is_dropdown, item.id === removedId);
            }

            if (item.is_question && typeof item.is_question === "object" && !isFound) {
                removeNextStep(removedId, item.is_question, item.id === removedId);
            }
        }
    };

    const removeNextValidation = (e) => {
        const fullName = targetName(e);
        dispatch(Actions.removeSubAreasValidation(fullName));
    };

    const handleNextButtonClick = (e) => {
        if (e) {
            e.preventDefault();
        }

        setFirstTry(false);

        let validationResult = validateForm(true);

        if (validationResult) {
            const mainOccupationArea = occupationAreaData.autocomplete[fields.mainAreas.key];
            const mainServices = [...occupationAreaData.services].filter((service) => service !== mainOccupationArea);
            const occupationAreaQuestions = occupationAreaData.questions;
            const isQuestionsEmpty = Object.keys(occupationAreaQuestions).length === 0;
            const [figureData] = Object.values(occupationAreaData.figure);
            const questionsArray = [];

            if (!isQuestionsEmpty) {
                Object.entries(occupationAreaQuestions).forEach(([id, answer]) => {
                    const questionData = { id: parseInt(id), answer: answer };
                    questionsArray.push(questionData);
                });
            }

            const payload = {
                [fields.mainAreas.key]: mainOccupationArea,
                ...(mainServices.length > 0 && { services: mainServices }),
                ...(!isQuestionsEmpty && { questions: questionsArray }),
                ...(figureData && { figure: figureData }),
                ...(isNonProfitOrganizationType && {
                    [fields.managementApproval.key]: signupForm[fields.managementApproval.key],
                    [fields.receiveDonations.key]: signupForm[fields.receiveDonations.key],
                }),
            };

            const onSuccess = (response) => {
                if (response.status === 1) {
                    // * THE PURPOSE HERE IS TO UPDATE THE SIGNUP FORM WITH THE SELECTED OCCUPATION DATA
                    // * IN ORDER TO DRAW THE SELECTION IF THIS COMPONENT GETS LOADED AGAIN
                    const { lead_id, ...restPayload } = payload;
                    dispatch(Actions.updateSignupForm({ questions: [], services: [], ...restPayload }));

                    if (isLeadOccupationEdit) {
                        window.parent.postMessage({ action: "editOccupationDone", source: "growRegister" }, "*");
                        return;
                    }

                    navigateToNextStep(validationResult, location, navigate);
                }
            };

            const onFailure = (response) => {
                if (response.status === 0) {
                    handleServerError(response);
                }
            };

            const props = {
                onSuccess,
                onFailure,
                payload: payload,
            };

            Api.updateOccupations(props);
        }
    };

    const renderDropDown = (e) => {
        const data = e.is_dropdown;
        const formDataName = targetName(e);
        const parentOccupationName = e?.name || "";
        const autoCompleteLabel = `${translate("occupation_areas__sub_dropdown_label")} ${parentOccupationName}`.trim();

        return (
            <AutoComplete
                className="field"
                fieldKey={"name"}
                name={formDataName}
                placeholder={translate("occupation_areas__sub_dropdown_placeholder")}
                onChange={handleMainOccupationAreaChange}
                onSelect={handleMainOccupationAreaChange}
                options={data}
                label={autoCompleteLabel}
                showError={targetName(e)}
                isOpen={false}
                disabled={requestingState}
                isOccupation={true}
            />
        );
    };

    const renderServices = (e) => {
        const data = e.is_dropdown;
        const formDataName = targetName(e);
        const parentOccupationName = e?.name || "";
        const checkboxLabel = `${translate("occupation_areas_services_label")} ${parentOccupationName}`.trim();

        return (
            <Checkbox
                type={"spaced"}
                label={checkboxLabel}
                options={data}
                showError={formDataName}
                onChange={handleMainOccupationAreaChange}
                fieldKey={"name"}
                isOccupationService={true}
            />
        );
    };

    const renderServicesAndData = (e) => {
        const data = e.is_dropdown;
        const figureName = e.figure_or_file_name;
        const id = e.id;
        const formDataName = targetName(e);
        const parentOccupationName = e?.name || "";
        const checkboxLabel = `${translate("occupation_areas_services_label")} ${parentOccupationName}`.trim();

        return (
            <>
                <Checkbox
                    type={"spaced"}
                    label={checkboxLabel}
                    options={data}
                    showError={formDataName}
                    onChange={handleMainOccupationAreaChange}
                    fieldKey={"name"}
                    isOccupationService={true}
                />

                <TextInput
                    label={figureName}
                    name={`${replaceSpaces(figureName)}_${id}`}
                    value={""}
                    onChange={handleMainOccupationAreaChange}
                    showError={targetName(e, "inputData")}
                    keyboardType={"tel"}
                    isOccupation={true}
                />
            </>
        );
    };

    const renderData = (e) => {
        const figureName = e.figure_or_file_name;
        const id = e.id;

        return (
            <TextInput
                label={figureName}
                name={`${replaceSpaces(figureName)}_${id}`}
                value={""}
                onChange={handleMainOccupationAreaChange}
                showError={targetName(e, "inputData")}
                keyboardType={"tel"}
                isOccupation={true}
                isShort={true}
            />
        );
    };

    const renderQuestions = (e) => {
        const questionsArray = e.is_question;
        const radioType = "options";
        const calCode = e.cal_code;

        return questionsArray.map((question) => {
            const questionOptions = [
                {
                    get id() {
                        return `${calCode}_${question.id}_${this.value}`;
                    },
                    value: BOOLEAN_VALUES.true,
                    text: "occupation_area_question_true",
                },
                {
                    get id() {
                        return `${calCode}_${question.id}_${this.value}`;
                    },
                    value: BOOLEAN_VALUES.false,
                    text: "occupation_area_question_false",
                },
            ];

            return (
                <RadioButton
                    key={question.id}
                    questionId={question.id}
                    type={radioType}
                    label={question.name}
                    options={questionOptions}
                    name={`${calCode}_${question.id}`}
                    isOccupation={true}
                    occupationValidationName={targetName(e)}
                    onChange={handleMainOccupationAreaChange}
                />
            );
        });
    };

    const replaceSpaces = (string) => {
        return string.replace(" ", "_");
    };

    const targetName = (e, type = "") => {
        if (type === "inputData") {
            return `${replaceSpaces(e.figure_or_file_name.trim())}_${e.id}`;
        }

        if (typeof e === "string") {
            return `${replaceSpaces(e.trim())}`;
        }

        return `${replaceSpaces(e.name?.trim())}_${e.id}`;
    };

    const setInputValidation = (e, type, rule) => {
        dispatch(
            Actions.setSubAreasValidations({
                [targetName(e, type)]: {
                    isValid: {
                        firstTry: true,
                        valid: null,
                        errMsg: "",
                    },
                    type: type,
                    name: targetName(e, type),
                    get rule() {
                        return rule();
                    },
                },
            })
        );
    };

    return (
        <form className="occupation-areas-wrapper" onSubmit={handleNextButtonClick}>
            <div className="occupation-areas-data">
                <HeaderTitle>{translate(fields.headerTitle.label)}</HeaderTitle>

                {mainAreas && (
                    <AutoComplete
                        className="field"
                        fieldKey={"name"}
                        name={fields.mainAreas.name}
                        placeholder={translate(fields.mainAreas.placeholder)}
                        onChange={handleMainOccupationAreaChange}
                        onSelect={handleMainOccupationAreaChange}
                        options={mainAreas}
                        label={translate(fields.mainAreas.label)}
                        showError={!firstTry && formData[fields.mainAreas.name].isValid?.valid === false}
                        errorMessage={formData[fields.mainAreas.name].isValid?.errMsg}
                        isOpen={false}
                        disabled={requestingState}
                    />
                )}

                {stepsArray.length > 0 && stepsArray.map((item) => <Fragment key={item.id}>{item.comp}</Fragment>)}

                {isNonProfitOrganizationType && (
                    <>
                        <RadioButton
                            type={fields.managementApproval.type}
                            label={translate(fields.managementApproval.header)}
                            options={fields.managementApproval.options}
                            name={fields.managementApproval.name}
                            value={signupForm[fields.managementApproval.key]}
                            showError={!firstTry && formData[fields.managementApproval.name].isValid?.valid === false}
                            errorMessage={formData[fields.managementApproval.name].isValid?.errMsg}
                            onChange={handleInputChange}
                        />

                        <RadioButton
                            type={fields.receiveDonations.type}
                            label={translate(fields.receiveDonations.header)}
                            options={fields.receiveDonations.options}
                            name={fields.receiveDonations.name}
                            value={signupForm[fields.receiveDonations.key]}
                            showError={!firstTry && formData[fields.receiveDonations.name].isValid?.valid === false}
                            errorMessage={formData[fields.receiveDonations.name].isValid?.errMsg}
                            onChange={handleInputChange}
                        />
                    </>
                )}
            </div>

            <Button isNext={true} onClick={handleNextButtonClick} />
        </form>
    );
};

export const validateOccupationsForm = (data, dispatch, translations) => {
    let isWholeFormValid = true;

    const allValidations = data.validation ?? {};
    const selectedServices = data.services ?? [];
    const questions = data.questions ?? {};
    const occupationAreaFigureData = data.figure ?? {};
    const occupationAreaAutocomplete = data.autocomplete;

    for (let validation in allValidations) {
        let isValid = false;

        switch (allValidations[validation].type) {
            case "checkbox":
                for (let item of allValidations[validation].rule) {
                    if (selectedServices.includes(item)) {
                        isValid = true;

                        dispatch(
                            Actions.updateSubAreasValidations({
                                [validation]: {
                                    isValid: {
                                        valid: isValid,
                                        errMsg: "",
                                    },
                                },
                            })
                        );

                        break;
                    }
                }

                if (!isValid) {
                    dispatch(
                        Actions.updateSubAreasValidations({
                            [validation]: {
                                isValid: {
                                    valid: isValid,
                                    errMsg: translations["occupation_area_service_is_must"],
                                },
                            },
                        })
                    );

                    isWholeFormValid = false;
                }

                break;
            case "inputData":
                const userInput = occupationAreaFigureData[allValidations[validation].name] ?? "";
                isValid = allValidations[validation].rule.test(userInput);
                isWholeFormValid = isValid;

                dispatch(
                    Actions.updateSubAreasValidations({
                        [validation]: {
                            isValid: {
                                valid: isValid,
                                errMsg: isValid ? "" : translations["occupation_area_license_number_is_must"],
                            },
                        },
                    })
                );

                break;
            case "dropdown":
                isValid = occupationAreaAutocomplete[allValidations[validation].name] ? true : false;
                isWholeFormValid = isValid;

                dispatch(
                    Actions.updateSubAreasValidations({
                        [validation]: {
                            isValid: {
                                valid: isValid,
                                errMsg: isValid ? "" : translations["occupation_area_dropdown_is_must"],
                            },
                        },
                    })
                );

                break;
            case "radio":
                let isRadioSelected = true;

                for (let item of allValidations[validation].rule) {
                    if (!questions.hasOwnProperty(item)) {
                        dispatch(
                            Actions.updateSubAreasValidations({
                                [validation]: {
                                    isValid: {
                                        valid: isValid,
                                        errMsg: translations["occupation_area_question_is_must"],
                                    },
                                },
                            })
                        );

                        isWholeFormValid = false;
                        isRadioSelected = false;
                    }
                }

                if (isRadioSelected) {
                    isValid = true;

                    dispatch(
                        Actions.updateSubAreasValidations({
                            [validation]: {
                                isValid: {
                                    valid: isValid,
                                    errMsg: "",
                                },
                            },
                        })
                    );

                    isWholeFormValid = true;
                }

                break;
        }
    }

    return isWholeFormValid;
};

export default OccupationAreas;
