import Validate from "app/validation/validation";
import { inputFields } from "./input-fields";
import routesMap from "./route-map";

// * USING THIS FUNCTION WE CAN GET AN OBJECT WITH THE PATH OF THE FAILED INPUT
const getPathObject = (routesMap, failedPath, failedInputs) => {
    let resultObject = {
        mainPath: "",
        subRoute: "",
        failedInputs: failedInputs,
    };

    for (let route in routesMap) {
        resultObject.mainPath = routesMap[route].path;

        if (routesMap[route].key && routesMap[route].key === failedPath) {
            return resultObject;
        } else {
            for (let subRoute in routesMap[route].subRoutes) {
                if (routesMap[route].subRoutes[subRoute].key === failedPath) {
                    resultObject.subRoute = subRoute;
                    return resultObject;
                }
            }
        }
    }
};

// * CHECKING WETHER THE FIELD IS REQUIRED
const isFieldRequired = (field) => {
    let result;

    if (typeof field === "function") {
        result = field();
    } else if (typeof field === "undefined") {
        // * THE DEFAULT IS 'TRUE'
        result = true;
    } else {
        result = field;
    }

    return result;
};

export const validateRegistrationData = (data, location, isEntireForm) => {
    let validationError = {};
    let tempPath;
    let currentPathKey = location?.key;

    for (let fieldsMainPath in inputFields) {
        // * SHOULD WE VALIDATE THE ENTIRE FORM OR JUST THE CURRENT PATH?
        if (currentPathKey && fieldsMainPath !== currentPathKey && !isEntireForm) {
            continue;
        }

        let isCurrentFormValid = true;
        tempPath = fieldsMainPath;

        for (let componentField in inputFields[fieldsMainPath]) {
            let fieldKey = inputFields[fieldsMainPath][componentField].key;

            // * TESTING WETHER THE FIELD IS REQUIRED BASED ON VALUE/FUNCTION
            let isRequired = isFieldRequired(inputFields[tempPath][componentField].isRequired, fieldKey);

            if (fieldKey) {
                let fieldRules = inputFields[tempPath][componentField].validationRules;

                // * TEST VALUE IF IT HAS RULES AND IS ALSO REQUIRED
                if (fieldRules && isRequired) {
                    let validationObj = Validate(data[fieldKey] ?? "", fieldRules, inputFields[tempPath][componentField].label);

                    if (Array.isArray(validationObj)) {
                        validationObj = Validate(data[fieldKey] ?? "", validationObj, inputFields[tempPath][componentField].label);
                    }

                    let isFieldValid = validationObj.valid;

                    inputFields[fieldsMainPath][componentField].isValid = {
                        valid: isFieldValid,
                        errMsg: validationObj.msg,
                    };

                    if (!isFieldValid) {
                        isCurrentFormValid = false;
                        validationError[fieldKey] = {
                            valid: isFieldValid,
                            errMsg: validationObj.msg,
                        };
                    }
                }

                if (!data.hasOwnProperty(fieldKey)) {
                    let isFieldRequired = isRequired;

                    if (!isFieldRequired) {
                        continue;
                    } else if (typeof isFieldRequired === "function") {
                        if (!isFieldRequired()) {
                            continue;
                        }
                    }
                }
            }
        }

        if (!isCurrentFormValid) {
            return getPathObject(routesMap, tempPath, validationError);
        }
    }

    return getPathObject(routesMap, tempPath, validationError);
};
