import useTranslate from "app/hooks/useTranslate";
import Button from "components/forms/button";
import RadioButton from "components/forms/radio-button";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Actions from "redux/actions";
import errorImage from "../../../assets/images/popup-error.svg";
import "./index.scss";

const BusinessExistsPopup = ({ payload }) => {
    const dispatch = useDispatch();
    const [animationClass, setAnimationClass] = useState("");
    const [checkedOption, setCheckedOption] = useState("");
    const translate = useTranslate();

    const innerText = payload && payload.text ? translate(payload.text) : translate("popup_default_error_text");
    let animationFrame;

    useEffect(() => {
        animateIn();

        return () => {
            cancelAnimationFrame(animationFrame);
        };
    }, []);

    const animateIn = () => {
        animationFrame = requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                setAnimationClass("active");
            });
        });
    };

    const completeAnimation = () => {
        if (animationClass !== "exit" && animationClass !== "done") {
            setAnimationClass("done");
        }
    };

    /* first remove the active class for exit animation then call the callback and remove the popup */
    const animateOut = () => {
        setAnimationClass("exit");

        setTimeout(() => {
            cancelAnimationFrame(animationFrame);
            dispatch(Actions.removePopup());
        }, 200);
    };

    const handleInputChange = (e) => {
        setCheckedOption(e.target.id);
    };

    const handleNextButtonClick = () => {
        if (!checkedOption) {
            return;
        }

        const callback = payload.callback ? payload.callback : () => {};
        callback(checkedOption);

        animateOut();
    };

    return (
        <div className={"backdrop general-msg " + animationClass} onClick={animateOut} onTransitionEnd={completeAnimation}>
            <div className={"popup_wrapper " + animationClass} onClick={(e) => e.stopPropagation()}>
                <div className="popup_content">
                    <div className="popup_error_image">
                        <img src={errorImage} alt={translate("popup_error_image_alt")} />
                    </div>
                    <div className="popup_text">{innerText}</div>
                </div>

                <div className="business-exists-popup-radio-buttons">
                    <RadioButton type={payload.optionsType} name={payload.name} options={payload.options} onChange={handleInputChange} isShort={true} />
                </div>

                <Button onClick={handleNextButtonClick} isNext={true} isPopup={true}></Button>
            </div>
        </div>
    );
};

export default BusinessExistsPopup;
