import useTranslate from "app/hooks/useTranslate";
import growLogo from "assets/images/grow-logo.svg";
import WizardSteps from "components/wizard_steps";
import { useSelector } from "react-redux";
import "./index.scss";

const DesktopContainer = ({ children }) => {
	const translate = useTranslate();
	const logoAlt = translate("grow_logo_alt");
	const isAu10tix = useSelector((store) => store.isAu10tix);
	const showWizardSteps = !Boolean(useSelector((store) => store.wizardSteps.hideSteps));

	if (isAu10tix) {
		return <div className={"au10tix-desktop-container-wrapper"}>{children}</div>;
	}

	const renderHeaderText = () => {
		const headerTextTemplate = translate("signup_desktop_base_screen_header");
		const [baseText, coloredText] = headerTextTemplate.split("{{color}}");

		return (
			<>
				<span>{baseText}</span>
				{!!coloredText && <span className={"colored-text"}> {coloredText}</span>}
			</>
		);
	};

	return (
		<div className="desktop-container-wrapper">
			<div className="desktop-container-main-content">{children}</div>

			<div className="desktop-container-general-details">
				<div className="desktop-container-top-section">
					<div className="desktop-container-logo-wrapper">
						<img src={growLogo} alt={logoAlt} />
					</div>

					<div className="desktop-container-text-header">{renderHeaderText()}</div>
				</div>

				{showWizardSteps && <WizardSteps />}
				<BottomCircle />
				<AppVersion />
			</div>
		</div>
	);
};

const BottomCircle = () => {
	return (
		<svg className="desktop-bottom-circle" xmlns="http://www.w3.org/2000/svg" width="331" height="371" viewBox="0 0 331 371" fill="none">
			<path
				d="M-65.9775 14.9336C-46.8819 8.91407 -26.956 4.72905 -6.42896 2.5792C4.3642 1.40396 15.3577 0.802002 26.4658 0.802002C37.5739 0.802002 48.052 1.37529 58.6161 2.49321C79.1718 4.58572 99.0689 8.71342 118.193 14.6756C241.355 53.0288 330.649 166.77 330.649 302.955C330.649 315.596 329.876 328.036 328.387 340.248C314.13 456.912 233.969 552.939 126.295 590.26C122.745 591.521 119.138 592.696 115.502 593.757C87.331 602.385 57.4424 607 26.4658 607C-4.5108 607 -33.913 602.442 -61.8835 593.958C-65.7771 592.811 -69.6132 591.578 -73.3923 590.231C-178.031 553.97 -256.704 462.244 -274.11 350.022C-276.515 334.687 -277.718 318.95 -277.718 302.926C-277.718 166.999 -188.739 53.4301 -65.9488 14.8763L-65.9775 14.9336ZM-152.809 336.665C-137.035 421.397 -62.7995 485.577 26.4658 485.577C115.731 485.577 189.937 421.397 205.712 336.665C207.773 325.772 208.833 314.478 208.833 302.984C208.833 202.113 127.211 120.391 26.4944 120.391C-74.2225 120.391 -155.872 202.113 -155.872 302.984C-155.872 314.478 -154.813 325.772 -152.752 336.665H-152.809Z"
				fill="#6E39EE"
			/>
		</svg>
	);
};

const AppVersion = () => {
	const translate = useTranslate();
	const appVersion = translate("signup_footer_app_version").replace("{version}", process.env.REACT_APP_SITE_VERSION);

	return <div className="app-version">{appVersion}</div>;
};

export default DesktopContainer;
