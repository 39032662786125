import useTranslate from "app/hooks/useTranslate";
import { useEffect } from "react";
import "./index.scss";

const Au10tixError = () => {
	const translate = useTranslate();

	useEffect(() => {
		console.log("Au10tix error");

		if (typeof parent.setSecureMeProcess === "function") {
			window.parent.setSecureMeProcess();
		}
	}, []);

	return <div className="au10tix-error-wrapper">{translate("waiting_for_au10tix_response")}</div>;
};

export default Au10tixError;
