import Api from "api/requests";
import { generateDefaultFormData, isNumber, navigateToNextStep } from "app/functions";
import useApiError from "app/hooks/useApiError";
import useTranslate from "app/hooks/useTranslate";
import useValidate from "app/hooks/useValidate";
import Button from "components/forms/button";
import Checkbox from "components/forms/checkbox";
import Hint from "components/forms/hint";
import TextInput from "components/forms/textInput";
import TextArea from "components/forms/textarea";
import HeaderTitle from "components/header_title";
import { BOOLEAN_VALUES, DELAY_DEBOUNCE } from "constants/input-fields";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Actions from "redux/actions";
import "./index.scss";

const ProductPlatforms = ({ fields, location }) => {
    const defaultFormData = generateDefaultFormData({ ...fields, ...fields.socialPlatforms });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const translate = useTranslate();
    const gdParams = useSelector((store) => store.gd.ErrorsLangParam.parameters);
    const signupForm = useSelector((store) => store.signupForm);
    const occupationAreaData = useSelector((store) => store.occupationAreaData);

    const BUSINESS_DESCRIPTION_LENGTH = gdParams?.["max_len_description"] ?? 255;

    const [firstTry, setFirstTry] = useState(true);
    const [formData, setFormData] = useState(defaultFormData);
    const [apiValidationError, setApiValidationError] = useState();
    const validationObj = { defaultFormData, formData, setFormData, location };
    const validateForm = useValidate(validationObj);
    const handleServerError = useApiError();

    const showPlatformsError =
        formData[fields.businessWebsite.name].isValid?.valid === false ||
        formData[fields.businessFacebook.name].isValid?.valid === false ||
        formData[fields.businessInstagram.name].isValid?.valid === false;

    const errorMessage =
        (signupForm[fields.businessWebsite.name] && formData[fields.businessWebsite.name].isValid.errMsg) ||
        (signupForm[fields.businessFacebook.name] && formData[fields.businessFacebook.name].isValid.errMsg) ||
        (signupForm[fields.businessInstagram.name] && formData[fields.businessInstagram.name].isValid.errMsg);

    const isProblematicOccupation = occupationAreaData.businessDescription ? true : false;

    useEffect(() => {
        const delayDebounce = setTimeout(() => {
            validateForm(false);
        }, DELAY_DEBOUNCE);

        return () => clearTimeout(delayDebounce);
    }, [signupForm]);

    const handleInputChange = (e) => {
        setApiValidationError(false);
        let type = e?.target?.type;
        let name = e?.target?.name || e?.name;
        let val = e?.target?.value || e?.value;
        let key = formData[name]?.inputKey;

        if (type === "checkbox") {
            let isChecked = e.target.checked;
            key = e.target.name;

            if (!isChecked) {
                dispatch(Actions.removeFromSignupForm([key]));
                return;
            }

            let keysToRemove = fields.unusedKeys();
            dispatch(Actions.removeFromSignupForm(keysToRemove));
            val = BOOLEAN_VALUES[isChecked];
        }

        if (formData[name]?.keyboardType === "tel" && !isNumber(val) && val?.length > 0) {
            return;
        }

        dispatch(Actions.updateSignupForm({ [key]: val }));
    };

    const handleNextButtonClick = (e) => {
        if (e) {
            e.preventDefault();
        }

        setFirstTry(false);

        let validationResult = validateForm(true);

        if (validationResult) {
            const isNoPlatformsChecked = signupForm[fields.noPlatformsCheckbox.key];
            let payload;

            // * DOES THE CUSTOMER HAVE ANY PLATFORMS?
            if (isNoPlatformsChecked) {
                payload = {
                    [fields.noPlatformsCheckbox.key]: isNoPlatformsChecked,
                    ...(isProblematicOccupation && { [fields.businessDescription.apiKey]: signupForm[fields.businessDescription.key] }),
                };
            } else {
                payload = {
                    ...(signupForm[fields.businessWebsite.key] && { [fields.businessWebsite.key]: signupForm[fields.businessWebsite.key] }),
                    ...(signupForm[fields.businessFacebook.key] && { [fields.businessFacebook.key]: signupForm[fields.businessFacebook.key] }),
                    ...(signupForm[fields.businessInstagram.key] && { [fields.businessInstagram.key]: signupForm[fields.businessInstagram.key] }),
                    ...(isProblematicOccupation && { [fields.businessDescription.apiKey]: signupForm[fields.businessDescription.key] }),
                };
            }

            const onSuccess = (response) => {
                if (response.status === 1) {
                    navigateToNextStep(validationResult, location, navigate);
                }
            };

            const onFailure = (response) => {
                if (response.status === 0) {
                    const errorId = parseInt(response.err.id);
                    const errorMessage = response.err.message;

                    if (errorId === 29 || errorId === 30 || errorId === 31) {
                        setApiValidationError(errorMessage);
                        return;
                    }

                    handleServerError(response);
                }
            };

            const props = {
                onSuccess,
                onFailure,
                payload,
            };

            Api.socialMedia(props);
        }
    };

    return (
        <form className="product-info-platforms-wrapper" onSubmit={handleNextButtonClick}>
            <div className="product-info-platforms-data">
                <HeaderTitle>{translate(fields.headerTitle.label)}</HeaderTitle>
                {translate(fields.headerTitle.sub)?.trim() && <div className="product-info-platforms-sub-label">{translate(fields.headerTitle.sub)}</div>}
                {translate(fields.headerTitle.inputsHeader)?.trim() && (
                    <div className="product-info-platforms-inputs-header">{translate(fields.headerTitle.inputsHeader)}</div>
                )}

                <div className="product-info-platforms-inputs">
                    <TextInput
                        name={fields.businessWebsite.name}
                        value={signupForm[fields.businessWebsite.key] || ""}
                        onChange={handleInputChange}
                        showError={false}
                        type={fields.businessWebsite.type}
                        img={fields.businessWebsite.icon}
                        imgAlt={fields.businessWebsite.iconAlt}
                        isShort={true}
                        disabled={signupForm[fields.noPlatformsCheckbox.name]}
                    />

                    <TextInput
                        name={fields.businessFacebook.name}
                        value={signupForm[fields.businessFacebook.key] || ""}
                        onChange={handleInputChange}
                        showError={false}
                        type={fields.businessFacebook.type}
                        img={fields.businessFacebook.icon}
                        imgAlt={fields.businessFacebook.iconAlt}
                        isShort={true}
                        disabled={signupForm[fields.noPlatformsCheckbox.name]}
                    />

                    <TextInput
                        name={fields.businessInstagram.name}
                        value={signupForm[fields.businessInstagram.key] || ""}
                        onChange={handleInputChange}
                        showError={false}
                        type={fields.businessInstagram.type}
                        img={fields.businessInstagram.icon}
                        imgAlt={fields.businessInstagram.iconAlt}
                        isShort={true}
                        disabled={signupForm[fields.noPlatformsCheckbox.name]}
                    />
                </div>

                <Checkbox
                    key={fields.noPlatformsCheckbox.name}
                    type={fields.noPlatformsCheckbox.type}
                    id={fields.noPlatformsCheckbox.id}
                    name={fields.noPlatformsCheckbox.name}
                    label={translate(fields.noPlatformsCheckbox.label)}
                    onChange={handleInputChange}
                    showError={false}
                    isShort={true}
                    checked={signupForm[fields.noPlatformsCheckbox.name] === BOOLEAN_VALUES.true}
                />

                <div className="error-message-wrapper">
                    {!firstTry && apiValidationError && <div className="product-info-platforms-error-message">{apiValidationError}</div>}
                    {!firstTry && showPlatformsError && (
                        <div className="product-info-platforms-error-message">{errorMessage ? errorMessage : translate(fields.headerTitle.noPlatformsError)}</div>
                    )}
                </div>

                <Hint>
                    <div className="product-info-platforms-hint-header">{translate(fields.hintHeader)}</div>
                    <div className="product-info-platforms-hint-text">{translate(fields.hintText)}</div>
                </Hint>

                {isProblematicOccupation && (
                    <TextArea
                        label={translate(fields.businessDescription.label)}
                        name={fields.businessDescription.name}
                        placeholder={translate(fields.businessDescription.placeholder).replace("{{description_length}}", BUSINESS_DESCRIPTION_LENGTH)}
                        showError={!firstTry && formData[fields.businessDescription.name].isValid?.valid === false}
                        errorMessage={formData[fields.businessDescription.name].isValid?.errMsg}
                        maxLength={BUSINESS_DESCRIPTION_LENGTH}
                        onChange={handleInputChange}
                        value={signupForm[fields.businessDescription.key] || ""}
                    />
                )}
            </div>

            <Button isNext={true} onClick={handleNextButtonClick} />
        </form>
    );
};

export default ProductPlatforms;
