import Api from "api/requests";
import { generateDefaultFormData, isNumber, navigateToNextStep } from "app/functions";
import useApiError from "app/hooks/useApiError";
import useTranslate from "app/hooks/useTranslate";
import useValidate from "app/hooks/useValidate";
import Button from "components/forms/button";
import Hint from "components/forms/hint";
import RadioButton from "components/forms/radio-button";
import TextInput from "components/forms/textInput";
import HeaderTitle from "components/header_title";
import { BOOLEAN_VALUES, BUSINESS_INFO_OWNER, DELAY_DEBOUNCE } from "constants/input-fields";
import { useEffect, useState } from "react";
import HtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Actions from "redux/actions";
import "./index.scss";

const DefaultBase = ({ fields, location }) => {
    const defaultFormData = generateDefaultFormData(fields);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const translate = useTranslate();
    const signupForm = useSelector((store) => store.signupForm);
    const isBusinessOwner = BUSINESS_INFO_OWNER.isFormRequired();

    const [firstTry, setFirstTry] = useState(true);
    const [hintText, setHintText] = useState("");
    const [formData, setFormData] = useState(defaultFormData);
    const validationObj = { defaultFormData, formData, setFormData, location };
    const validateForm = useValidate(validationObj);
    const handleServerError = useApiError();

    useEffect(() => {
        const delayDebounce = setTimeout(() => {
            validateForm(false);
        }, DELAY_DEBOUNCE);

        return () => clearTimeout(delayDebounce);
    }, [signupForm]);

    useEffect(() => {
        setHintTextData();
        isDifferentDataAvailable();
    }, []);

    const isDifferentDataAvailable = () => {
        // * CHECK IF FORM CONTAINS ANY DIFFERENT CONTACT DATA, AND IF SO => IT SELECTS THE CHECKBOX
        const differentEmailAddress = signupForm[fields.differentEmailAddress.key];
        const differentPhoneNumber = signupForm[fields.differentPhoneNumber.key];

        if (differentEmailAddress || differentPhoneNumber) {
            dispatch(Actions.updateSignupForm({ [fields.sendThisInfoToCustomers.key]: BOOLEAN_VALUES.false }));
        }
    };

    const handleInputChange = (e) => {
        let type = e?.target?.type;
        let name = e.target.name;
        let val = e.target.value;
        let key = formData[name]?.inputKey;

        if (type === "checkbox") {
            let isChecked = e.target.checked;
            key = e.target.name;

            if (!isChecked) {
                dispatch(Actions.removeFromSignupForm([key]));
                return;
            }

            val = BOOLEAN_VALUES[isChecked];
        } else if (type === "radio") {
            val = BOOLEAN_VALUES[val == BOOLEAN_VALUES.true];
        }

        if (key === fields.sendThisInfoToCustomers.key) {
            let inputs = [fields.differentEmailAddress.key, fields.differentPhoneNumber.key];

            dispatch(Actions.removeFromSignupForm(inputs));
            setFirstTry(true);
        }

        if (formData[name]?.keyboardType === "tel" && !isNumber(val) && val?.length > 0) {
            return;
        }

        dispatch(Actions.updateSignupForm({ [key]: val }));
    };

    const getPhoneAndEmailData = () => {
        // * IF WE RECEIVED BUSINESS OWNER INFO => USE THIS DATA
        if (isBusinessOwner) {
            return {
                phoneNumber: signupForm[fields.contactBusinessHint.ownerData.phoneNumber],
                emailAddress: signupForm[fields.contactBusinessHint.ownerData.emailAddress],
            };
        }

        // * OTHERWISE USE CONTACT INFO DATA
        return {
            phoneNumber: signupForm[fields.contactBusinessHint.contactData.phoneNumber],
            emailAddress: signupForm[fields.contactBusinessHint.contactData.emailAddress],
        };
    };

    const setHintTextData = () => {
        const customerData = getPhoneAndEmailData();
        const baseText = translate(fields.contactBusinessHint.text);
        const newText = baseText
            .replace("{{phone_number}}", `<span>${customerData.phoneNumber}</span>`)
            .replace("{{email_address}}", `<span>${customerData.emailAddress}</span>`);
        setHintText(newText);
    };

    const handleNextButtonClick = (e) => {
        if (e) {
            e.preventDefault();
        }

        setFirstTry(false);

        let validationResult = validateForm(true);

        if (validationResult) {
            const onSuccess = (response) => {
                if (response.status === 1) {
                    navigateToNextStep(validationResult, location, navigate);
                }
            };

            const onFailure = (response) => {
                if (response.status === 0) {
                    handleServerError(response);
                }
            };

            const props = {
                onSuccess,
                onFailure,
                // * SEND DATA BASED ON CUSTOMER CHOICE
                payload: {
                    ...(signupForm[fields.sendThisInfoToCustomers.key] === fields.sendThisInfoToCustomers.options.true.value && {
                        [fields.differentPhoneNumber.apiKey]: getPhoneAndEmailData().phoneNumber,
                        [fields.differentEmailAddress.apiKey]: getPhoneAndEmailData().emailAddress,
                    }),

                    ...(signupForm[fields.sendThisInfoToCustomers.key] === fields.sendThisInfoToCustomers.options.false.value && {
                        [fields.differentPhoneNumber.apiKey]: signupForm[fields.differentPhoneNumber.key],
                        [fields.differentEmailAddress.apiKey]: signupForm[fields.differentEmailAddress.key],
                    }),

                    approve_show_details: signupForm[fields.sendThisInfoToCustomers.key],
                },
            };

            Api.confirmCustomerContact(props);
        }
    };

    return (
        <form className="contact-info-confirmation-wrapper" onSubmit={handleNextButtonClick}>
            <div className="contact-info-confirmation-data">
                <HeaderTitle>{translate(fields.headerTitle.label)}</HeaderTitle>

                {hintText && (
                    <Hint fill={false}>
                        <div className="contact-info-hint-header">{translate(fields.contactBusinessHint.header)}</div>
                        <div className="contact-info-hint-content">{HtmlParser(hintText)}</div>
                    </Hint>
                )}

                <RadioButton
                    type={fields.sendThisInfoToCustomers.type}
                    label={translate(fields.sendThisInfoToCustomers.header)}
                    options={fields.sendThisInfoToCustomers.options}
                    name={fields.sendThisInfoToCustomers.name}
                    onChange={handleInputChange}
                    value={signupForm[fields.sendThisInfoToCustomers.name]}
                    showError={!firstTry && formData[fields.sendThisInfoToCustomers.name].isValid?.valid === false}
                    errorMessage={formData[fields.sendThisInfoToCustomers.name].isValid?.errMsg}
                    squareCorners
                />

                {signupForm[fields.sendThisInfoToCustomers.key] === fields.sendThisInfoToCustomers.options.false.value && (
                    <>
                        <TextInput
                            label={translate(fields.differentEmailAddress.label)}
                            name={fields.differentEmailAddress.name}
                            value={signupForm[fields.differentEmailAddress.key] || ""}
                            onChange={handleInputChange}
                            showError={!firstTry && formData[fields.differentEmailAddress.name].isValid?.valid === false}
                            errorMessage={formData[fields.differentEmailAddress.name].isValid?.errMsg}
                        />

                        <TextInput
                            label={translate(fields.differentPhoneNumber.label)}
                            name={fields.differentPhoneNumber.name}
                            value={signupForm[fields.differentPhoneNumber.key] || ""}
                            onChange={handleInputChange}
                            showError={!firstTry && formData[fields.differentPhoneNumber.name].isValid?.valid === false}
                            errorMessage={formData[fields.differentPhoneNumber.name].isValid?.errMsg}
                        />
                    </>
                )}
            </div>

            <Button isNext={true} onClick={handleNextButtonClick} />
        </form>
    );
};

export default DefaultBase;
