import Store from "../../redux/index";

// these fallbacks are just in case the translations are not available for some reason (deleted for example)
const VALIDATIONS_FALLBACK = {
    invalidField: "שדה לא תקין",
    generalRequiredField: "{field} שדה חובה",
    emailAddress: "נא לוודא שכתובת המייל תקינה",
    phoneNumber: "יש להזין 10 ספרות ללא רווחים וללא מקף",
    idNumber: "תעודת זהות לא תקינה",
    firstName: "יש להזין שם פרטי כדי להמשיך",
    lastName: "יש להזין שם משפחה כדי להמשיך",
    businessNumber: "מספר עסק לא תקין",
    forbiddenCharacters: "שדה מכיל תווים לא מורשים",
    hebrewBusinessName: "השם צריך לכלול שתי אותיות לפחות",
    englishBusinessName: "שם צריך להכיל עד {chars} אותיות אנגלית, ללא רווחים",
    postalCode: "מיקוד לא תקין",
    invalidUrl: "נא לוודא שהכתובת תקינה",
    bankAccountNumber: "מספר החשבון לא תקין",
    bankAccountOwners: "יש להזין שם עד {chars} תווים",
    dateInvalid: "תאריך לא תקין",
};

const getParameterFromRedux = (slice, key = false) => {
    const state = Store.getState();
    const splittedKey = key ? key.split(".") : false;
    let reduxValue = state[slice];

    if (!key) {
        return reduxValue;
    }

    if (reduxValue) {
        for (let item of splittedKey) {
            reduxValue = reduxValue?.[item];
        }

        return reduxValue;
    }

    setTimeout(() => {
        getParameterFromRedux(key, slice);
    }, 750);
};

const getTranslationFromRedux = (key = "", fallback = false) => {
    const state = Store.getState();
    const value = state.translations[key];
    return value ? value : fallback || "";
};

const getMaxNameCharacters = () => {
    return getParameterFromRedux("gd", "ErrorsLangParam.parameters.number_of_max_name_characters") ?? 25;
};

const getMinNameWords = () => {
    return (parseInt(getParameterFromRedux("gd", "ErrorsLangParam.parameters.number_of_min_name_words")) ?? 2) - 1;
};

const getProductDescriptionLength = () => {
    return {
        min: parseInt(getParameterFromRedux("gd", "ErrorsLangParam.parameters.min_len_description") ?? 3),
        max: parseInt(getParameterFromRedux("gd", "ErrorsLangParam.parameters.max_len_description") ?? 255),
    };
};

export default function Validate(value, rules, inputHeader = "") {
    // * CHECK IF VALUE IS STRING TYPE AND IF SO => TRIM IT
    value = typeof value === "string" ? value?.trim() : value;

    const Validations = {
        no_validation: {
            valid: (val) => true,
            msg: "",
        },
        not_empty: {
            valid: (val) => val !== "" && val !== undefined,
            msg: getTranslationFromRedux("validation_error_general_required_field", VALIDATIONS_FALLBACK.generalRequiredField)?.replace(
                "{field}",
                getTranslationFromRedux(inputHeader) || ""
            ),
        },
        address: {
            valid: (val) => val !== "" && val !== undefined,
            msg: "יש לבחור כתובת למשלוח",
        },
        package: {
            valid: (val) => val !== "" && val !== undefined,
            msg: "יש לבחור מסלול",
        },
        email: {
            valid: (val) =>
                /^([\w!#$%&'*+-/=?^`{|}~]+\.)*[\w!#$%&'*+-/=?^`{|}~]+@((((([a-zA-Z0-9]{1}[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]{1})|[a-zA-Z])\.)+[a-zA-Z]{2,8})|(\d{1,3}\.){3}\d{1,3}(:\d{1,5})?)$/.test(
                    val
                ),
            msg: getTranslationFromRedux("validation_error_email_address", VALIDATIONS_FALLBACK.emailAddress),
        },
        cell: {
            valid: (val) => /^(?:(0(?:50|51|52|53|54|55|57|58|72|73|74|76|77|78)[-]?[0-9]{7}))$/.test(val),
            msg: getTranslationFromRedux("validation_error_phone_number", VALIDATIONS_FALLBACK.phoneNumber),
        },
        phone: {
            valid: (val) => /^(?:(0(?:2|3|4|8|9|7|50|51|52|53|54|55|56|57|58|59|72|73|74|76|77|78)[-]?[0-9]{7}))$/.test(val),
            msg: "מספר טלפון שגוי",
        },
        id: {
            valid: (id) => {
                id = String(id).trim();
                if (id.length > 9 || isNaN(id)) return false;
                id = id.length < 9 ? ("00000000" + id).slice(-9) : id;

                return (
                    Array.from(id, Number).reduce((counter, digit, i) => {
                        const step = digit * ((i % 2) + 1);
                        return counter + (step > 9 ? step - 9 : step);
                    }) %
                        10 ===
                    0
                );
            },
            msg: getTranslationFromRedux("validation_error_id_number", VALIDATIONS_FALLBACK.idNumber),
        },
        full_name: {
            valid: (val) => /^([\w\u0590-\u05FF]{2,})+\s+([\w\u0590-\u05FF\s]{2,})+$/.test(val),
            msg: "יש למלא שם פרטי ושם משפחה",
        },
        first_name: {
            expression: new RegExp(`^(?=.*[\u0590-\u05FF “.'"׳-]{2})[\u0590-\u05FF\s “.'"׳-]{2,${getMaxNameCharacters()}}$`),
            valid: (val) => Validations.first_name.expression.test(val),

            // valid: (val) => /^(?=.*[\u0590-\u05FF“'"׳-]{2})[\u0590-\u05FF\s“'"׳-]{2,25}$/.test(val),
            msg: getTranslationFromRedux("validation_error_first_name", VALIDATIONS_FALLBACK.firstName),
        },
        last_name: {
            expression: new RegExp(`^(?=.*[\u0590-\u05FF “.'"׳-]{2})[\u0590-\u05FF\s “.'"׳-]{2,${getMaxNameCharacters()}}$`),
            valid: (val) => Validations.last_name.expression.test(val),

            // valid: (val) => /^(?=.*[\u0590-\u05FF“'"׳-]{2})[\u0590-\u05FF\s“'"׳-]{2,25}$/.test(val),
            msg: getTranslationFromRedux("validation_error_last_name", VALIDATIONS_FALLBACK.lastName),
        },
        last_digits: {
            valid: (val) => /^[0-9]*$/.test(val) && val.length === 4,
            msg: "אנא הזינו 4 ספרות אחרונות בכרטיס האשראי",
        },
        checkbox: {
            valid: (val) => val,
            msg: getTranslationFromRedux("validation_error_general_required_field", VALIDATIONS_FALLBACK.generalRequiredField)?.replace(
                "{field}",
                getTranslationFromRedux(inputHeader) || ""
            ),
        },
        required_select: {
            valid: (val) => {
                if (val === undefined) return false;
                return val != -1;
            },
            msg: getTranslationFromRedux("validation_error_general_required_field", VALIDATIONS_FALLBACK.generalRequiredField)?.replace(
                "{field}",
                getTranslationFromRedux(inputHeader) || ""
            ),
        },
        required_radio: {
            valid: (val) => {
                if (val === undefined || val === "") return false;
                // return val != -1;
                return true;
            },
            msg: getTranslationFromRedux("validation_error_general_required_field", VALIDATIONS_FALLBACK.generalRequiredField)?.replace(
                "{field}",
                getTranslationFromRedux(inputHeader) || ""
            ),
        },
        required_date: {
            valid: (val) => {
                return !!val;
                // return (val === null)? true : false
            },
            msg: getTranslationFromRedux("validation_error_general_required_field", VALIDATIONS_FALLBACK.generalRequiredField)?.replace(
                "{field}",
                getTranslationFromRedux(inputHeader) || ""
            ),
        },
        plc_business_number: {
            valid: (val) => /^51\d{7}$/.test(val),
            msg: getTranslationFromRedux("validation_error_business_number", VALIDATIONS_FALLBACK.businessNumber),
        },
        other_business_number: {
            valid: (val) => /^5[^1][0-9]{7}$/.test(val),
            msg: getTranslationFromRedux("validation_error_business_number", VALIDATIONS_FALLBACK.businessNumber),
        },
        share_holders: {
            valid: (val) => /^\d+$/.test(val),
            msg: "מס׳ בעלי מניות לא תקין",
        },
        is_number: {
            valid: (val) => /^\d+$/.test(val),
            msg: getTranslationFromRedux("validation_error_is_number", VALIDATIONS_FALLBACK.forbiddenCharacters),
        },
        hebrew_business_name: {
            valid: (val) => /^(?=.*[a-zA-Z\u05D0-\u05EA]{2,})([a-zA-Z\u05D0-\u05EA\d ,.״׳'-/"/(/)])+([\s]+([a-zA-Z\u05D0-\u05EA\d ,.'-/"/(/)]{2,})+)*$/.test(val),
            msg: getTranslationFromRedux("validation_error_hebrew_business_name", VALIDATIONS_FALLBACK.hebrewBusinessName),
        },
        english_business_name: {
            expression: new RegExp(`^[a-zA-Z]{1,${getMaxNameCharacters()}}$`),
            valid: (val) => Validations.english_business_name.expression.test(val),
            msg: getTranslationFromRedux("validation_error_english_business_name", VALIDATIONS_FALLBACK.englishBusinessName)?.replace("{chars}", getMaxNameCharacters()),
        },
        hebrew_characters: {
            valid: (val) => /^([\u05D0-\u05EA ״׳'-/"]{2,})+$/.test(val),
            msg: getTranslationFromRedux("validation_error_hebrew_characters", VALIDATIONS_FALLBACK.invalidField),
        },
        address_street: {
            valid: (val) => /^([\d\u05D0-\u05EA ״׳'-/"]{2,})+$/.test(val),
            msg: getTranslationFromRedux("validation_error_street_address", VALIDATIONS_FALLBACK.invalidField),
        },
        hebrew_characters_two_words_max: {
            valid: (val) => /^[\u05D0-\u05EA“'"׳-]{2,}(?: {1,2}[\u05D0-\u05EA“'"׳-]{2,})?$/.test(val),
            msg: VALIDATIONS_FALLBACK.invalidField,
        },
        postal_code: {
            valid: (val) => /^\d{7}$/.test(val),
            msg: getTranslationFromRedux("validation_error_postal_code", VALIDATIONS_FALLBACK.postalCode),
        },
        textarea_description: {
            expression: new RegExp(`^([\da-zA-Z\u05D0-\u05EA ״׳'":;.\n,]{${getProductDescriptionLength().min},${getProductDescriptionLength().max}})$`),
            valid: (val) => Validations.textarea_description.expression.test(val),
            msg: getTranslationFromRedux("validation_error_textarea_description", VALIDATIONS_FALLBACK.invalidField),
        },
        general_website_url: {
            valid: (val) =>
                /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/.test(
                    val
                ),
            msg: getTranslationFromRedux("validation_error_website_url", VALIDATIONS_FALLBACK.invalidUrl),
        },
        facebook_url: {
            valid: (val) => /(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/?/.test(val),
            msg: getTranslationFromRedux("validation_error_facebook_url", VALIDATIONS_FALLBACK.invalidUrl),
        },
        instagram_url: {
            valid: (val) => /(?:(?:http|https):\/\/)?(?:www.)?instagram.com\/?/.test(val),
            msg: getTranslationFromRedux("validation_error_instagram_url", VALIDATIONS_FALLBACK.invalidUrl),
        },
        bank_account_number: {
            valid: (val) => /^\d{2,}$/.test(val),
            msg: getTranslationFromRedux("validation_error_bank_account_number", VALIDATIONS_FALLBACK.bankAccountNumber),
        },
        bank_account_owners: {
            expression: new RegExp(`^(?=.{1,${getMaxNameCharacters()}}$)(?:[a-zA-Z\u0590-\u05fe.“'"׳.,-]{2,}(?: [a-zA-Z\u0590-\u05fe.“'"׳.,-]{2,})*)$`,),
            valid: (val) => Validations.bank_account_owners.expression.test(val),

            msg: getTranslationFromRedux("validation_error_bank_account_owners", VALIDATIONS_FALLBACK.bankAccountOwners)?.replace("{chars}", getMaxNameCharacters()),
        },
        jwt: {
            valid: (val) => /^[a-zA-Z0-9-_]+\.[a-zA-Z0-9-_]+\.[a-zA-Z0-9-_]+$/.test(val),
            msg: "שדה לא תקין",
        },
        timestamp: {
            valid: (val) => {
                const dateTime = new Date(val).getTime();
                return typeof dateTime === "number";
            },
            msg: getTranslationFromRedux("validation_error_date_invalid", VALIDATIONS_FALLBACK.dateInvalid),
        },
    };

    let valid = true;
    let msg = "";

    if (typeof rules === "function") {
        let rule = rules();
        let validationObj = Validate(value, rule, inputHeader);
        return validationObj;
    } else {
        for (let rule of rules) {
            if (typeof rule === "function") {
                let ruleResult = rule();

                if (Array.isArray(ruleResult)) {
                    return ruleResult;
                } else if (typeof ruleResult === "object") {
                    valid = ruleResult?.isValid;
                    msg = getTranslationFromRedux(ruleResult?.errMsg) + (ruleResult?.addRequired === false ? "" : " שדה חובה");
                } else {
                    valid = ruleResult;
                    msg = "שדה חובה";
                }
            } else {
                if (!Validations[rule].valid(value)) {
                    valid = false;
                    msg = Validations[rule].msg;
                    break;
                }
            }
        }
    }

    return { valid: valid, msg: msg };
}
