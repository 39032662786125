import Api from "api/requests";
import { generateDefaultFormData, isNumber, navigateToNextStep } from "app/functions";
import useApiError from "app/hooks/useApiError";
import useTranslate from "app/hooks/useTranslate";
import useValidate from "app/hooks/useValidate";
import Button from "components/forms/button";
import Checkbox from "components/forms/checkbox";
import HeaderTitle from "components/header_title";
import { BOOLEAN_VALUES, DELAY_DEBOUNCE, PRODUCT_INFO_GENERAL } from "constants/input-fields";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Actions from "redux/actions";
import "./index.scss";

const GeneralProductInfo = ({ fields, location }) => {
    const defaultFormData = generateDefaultFormData(fields);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const translate = useTranslate();
    const signupForm = useSelector((store) => store.signupForm);

    const [firstTry, setFirstTry] = useState(true);
    const [formData, setFormData] = useState(defaultFormData);
    const validationObj = { defaultFormData, formData, setFormData, location };
    const validateForm = useValidate(validationObj);
    const handleServerError = useApiError();

    useEffect(() => {
        const delayDebounce = setTimeout(() => {
            validateForm(false);
        }, DELAY_DEBOUNCE);

        return () => clearTimeout(delayDebounce);
    }, [signupForm]);

    const handleInputChange = (e) => {
        let type = e.target.type;
        let name = e.target.name;
        let val = e.target.value;
        let key = formData[name]?.inputKey;
        let id = e.target?.id;

        if (type === "checkbox") {
            let isChecked = e.target.checked;
            key = e.target.name;

            const bitwiseValue = PRODUCT_INFO_GENERAL[name].options[id].bitwiseValue;

            if (!isChecked) {
                // * REMOVE BITWISE VALUE IF UNCHECKED
                val = signupForm[key] & ~bitwiseValue;
            } else {
                // * ADD BITWISE VALUE IF CHECKED
                val = signupForm[key] | bitwiseValue;
            }

            if (val === BOOLEAN_VALUES.false) {
                dispatch(Actions.removeFromSignupForm([key]));
                return;
            }
        } else if (type === "radio") {
            val = BOOLEAN_VALUES[val == BOOLEAN_VALUES.true];
        }

        if (formData[name]?.keyboardType === "tel" && !isNumber(val) && val?.length > 0) {
            return;
        }

        dispatch(Actions.updateSignupForm({ [key]: val }));
    };

    const handleNextButtonClick = (e) => {
        if (e) {
            e.preventDefault();
        }

        setFirstTry(false);

        let validationResult = validateForm(true);

        if (validationResult) {
            const onSuccess = (response) => {
                if (response.status === 1) {
                    navigateToNextStep(validationResult, location, navigate);
                }
            };

            const onFailure = (response) => {
                if (response.status === 0) {
                    handleServerError(response);
                }
            };

            const props = {
                onSuccess,
                onFailure,
                payload: {
                    [fields.question_customer.apiKey]: signupForm[fields.question_customer.key],
                    [fields.question_selling.apiKey]: signupForm[fields.question_selling.key],
                    [fields.question_customers_living.apiKey]: signupForm[fields.question_customers_living.key],
                },
            };

            Api.generalProductQuestions(props);
        }
    };

    return (
        <form className="product-info-general-wrapper" onSubmit={handleNextButtonClick}>
            <div className="product-info-general-data">
                <HeaderTitle>{translate(fields.headerTitle.label)}</HeaderTitle>
                {translate(fields.headerTitle.sub)?.trim() && <div className="product-info-general-sub-label">{translate(fields.headerTitle.sub)}</div>}

                <div className="product-info-general-checkboxes">
                    <div className="product-info-general-checkbox-header">{translate(fields.question_customer.header)}</div>
                    <div className="product-info-general-checkbox-container">
                        {Object.values(fields.question_customer.options).map((checkbox) => {
                            return (
                                <Checkbox
                                    key={checkbox.id}
                                    type={fields.question_customer.type}
                                    id={checkbox.id}
                                    name={checkbox.name}
                                    label={translate(checkbox.text)}
                                    onChange={handleInputChange}
                                    showError={false}
                                    checked={signupForm[checkbox.name] & checkbox.bitwiseValue}
                                    isShort={true}
                                />
                            );
                        })}
                    </div>

                    {!firstTry && formData[fields.question_customer.name].isValid?.valid === false && (
                        <div className="product-info-general-error-message">{formData[fields.question_customer.name].isValid?.errMsg}</div>
                    )}
                </div>

                <div className="product-info-general-checkboxes">
                    <div className="product-info-general-checkbox-header">{translate(fields.question_selling.header)}</div>
                    <div className="product-info-general-checkbox-container">
                        {Object.values(fields.question_selling.options).map((checkbox) => {
                            return (
                                <Checkbox
                                    key={checkbox.id}
                                    type={fields.question_selling.type}
                                    id={checkbox.id}
                                    name={checkbox.name}
                                    label={translate(checkbox.text)}
                                    onChange={handleInputChange}
                                    showError={false}
                                    checked={signupForm[checkbox.name] & checkbox.bitwiseValue}
                                    isShort={true}
                                />
                            );
                        })}
                    </div>

                    {!firstTry && formData[fields.question_selling.name].isValid?.valid === false && (
                        <div className="product-info-general-error-message">{formData[fields.question_selling.name].isValid?.errMsg}</div>
                    )}
                </div>

                <div className="product-info-general-checkboxes">
                    <div className="product-info-general-checkbox-header">{translate(fields.question_customers_living.header)}</div>
                    <div className="product-info-general-checkbox-container">
                        {Object.values(fields.question_customers_living.options).map((checkbox) => {
                            return (
                                <Checkbox
                                    key={checkbox.id}
                                    type={fields.question_customers_living.type}
                                    id={checkbox.id}
                                    name={checkbox.name}
                                    label={translate(checkbox.text)}
                                    onChange={handleInputChange}
                                    showError={false}
                                    checked={signupForm[checkbox.name] & checkbox.bitwiseValue}
                                    isShort={true}
                                />
                            );
                        })}
                    </div>

                    {!firstTry && formData[fields.question_customers_living.name].isValid?.valid === false && (
                        <div className="product-info-general-error-message">{formData[fields.question_customers_living.name].isValid?.errMsg}</div>
                    )}
                </div>

                <span className="product-info-general-selection-text">{translate("product_info_general_selection_options")}</span>
            </div>

            <Button isNext={true} onClick={handleNextButtonClick} />
        </form>
    );
};

export default GeneralProductInfo;
