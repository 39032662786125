import { generateUniqueId } from "app/functions";
import { useSelector } from "react-redux";
import "./index.scss";

const DatePicker = (props) => {
    const {
        label,
        subLabel,
        name,
        value,
        onChange,
        showError,
        errorMessage,
        disabled = false,
        tabIndex = 0,
        id,
        isShort,
        autoHeight,
        maxLength = false,
        className = "",
        min = false,
        max = false,
    } = props;

    const requestingState = useSelector((store) => store.requestingState);
    const loaderState = useSelector((store) => store.loaderState);
    const popupsArray = useSelector((store) => store.popupsArray);
    const inputErrorId = `${id ?? name ?? generateUniqueId(12)}_err`;

    const handleOnChange = (e) => {
        if (popupsArray.length > 0 || loaderState) {
            return;
        }

        const value = e.target.value;

        if (value > max || value < min) {
            return;
        }

        onChange(e);
    };

    const getValue = () => {
        switch (value) {
            case "":
            case null:
                return "";
            default:
                return value;
        }
    };

    return (
        <div className={`date-input-wrapper${isShort ? " short" : ""} ${className ? className : ""}${autoHeight ? " auto-height" : ""}`}>
            {label?.trim() && <span className="date-input-label">{label}</span>}
            {subLabel?.trim() && <span className="date-input-sub-label">{subLabel}</span>}

            <input
                type={"date"}
                name={name}
                value={getValue()}
                onChange={handleOnChange}
                tabIndex={tabIndex}
                id={id}
                disabled={disabled || requestingState ? true : false}
                {...(maxLength ? { maxLength: maxLength } : {})}
                {...(showError ? { "aria-describedby": inputErrorId } : {})}
                {...(min ? { min: min } : {})}
                {...(max ? { max: max } : {})}
            />

            {showError && (
                <label className="date-input-error" id={inputErrorId}>
                    {errorMessage}
                </label>
            )}
        </div>
    );
};

export default DatePicker;
