import "./index.scss";

const Subheader = ({ children, noMargin = false }) => {
    if (typeof children === "string" && children?.trim()?.length === 0) {
        return <></>;
    }

    return <h2 className={`subheader-text${noMargin ? " no-margin" : ""}`}>{children}</h2>;
};

export default Subheader;
