import Api from "api/requests";
import { generateDefaultFormData, isNumber, navigateToNextStep } from "app/functions";
import useApiError from "app/hooks/useApiError";
import useTranslate from "app/hooks/useTranslate";
import useValidate from "app/hooks/useValidate";
import Validate from "app/validation/validation";
import Button from "components/forms/button";
import RadioButton from "components/forms/radio-button";
import TextInput from "components/forms/textInput";
import HeaderTitle from "components/header_title";
import Subheader from "components/subheader";
import { BUSINESS_INFO_TYPE, DELAY_DEBOUNCE } from "constants/input-fields";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Actions from "redux/actions";
import "./index.scss";

const BusinessType = ({ fields, location }) => {
    const defaultFormData = generateDefaultFormData(fields);
    const businessTypesOptions = fields.businessType.options;

    const businessTypesBitwise = {
        [fields.businessType.options.exemptDealer.name]: 1,
        [fields.businessType.options.licensedDealer.name]: 1,
        [fields.businessType.options.proprietaryLimitedCompany.name]: 2,
        [fields.businessType.options.partnership.name]: 4,
        [fields.businessType.options.nonProfitOrganization.name]: 4,
        [fields.businessType.options.cooperativeSociety.name]: 4,
    };

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const translate = useTranslate();
    const handleServerError = useApiError();
    const signupForm = useSelector((store) => store.signupForm);

    const [firstTry, setFirstTry] = useState(true);
    const [formData, setFormData] = useState(defaultFormData);
    const [businessOptions, setBusinessOptions] = useState(businessTypesOptions);
    const validationObj = { defaultFormData, formData, setFormData, location };
    const validateForm = useValidate(validationObj);

    useEffect(() => {
        const delayDebounce = setTimeout(() => {
            validateForm(false);
        }, DELAY_DEBOUNCE);

        return () => clearTimeout(delayDebounce);
    }, [signupForm]);

    useEffect(() => {
        disableNotMatchingBusinessTypes();
    }, []);

    const disableNotMatchingBusinessTypes = () => {
        // * DISABLE ALL BUSINESS TYPES THAT ARE NOT MATCHING THE VALIDATION RULE BASED ON BUSINESS NUMBER

        let businessTypeBitwise = 0;
        let validationRules = BUSINESS_INFO_TYPE.businessNumber.setOfValidationRules();
        let businessNumber = signupForm[BUSINESS_INFO_TYPE.businessNumber.key];

        for (let [businessType, rule] of validationRules) {
            let isValid = Validate(businessNumber, rule, "").valid;

            if (isValid) {
                businessTypeBitwise = businessTypeBitwise | businessTypesBitwise[businessType];
                break;
            }
        }

        let newState = { ...businessOptions };

        for (let option in businessOptions) {
            newState[option] = {
                ...businessOptions[option],
                disabled: businessTypeBitwise & businessTypesBitwise[option] ? 0 : 1,
            };

            // * IF WE RECEIVED A BUSINESS TYPE FROM SERVER AND FOR SOME REASON IT DOES NOT MATCH ANY OF THE SELECTABLE OPTIONS => REMOVE IT FROM THE FORM
            if (signupForm[fields.businessType.key] && newState[option].value === signupForm[fields.businessType.key] && newState[option].disabled === 1) {
                dispatch(Actions.removeFromSignupForm([fields.businessType.key]));
            }
        }

        setBusinessOptions(newState);
    };

    const handleInputChange = (e) => {
        let name = e.target.name;
        let val = e.target.value;
        let key = formData[name].inputKey;

        if (name === fields.businessType.name) {
            // * IF THE CHANGE IS ON THE 'BUSINESS TYPE' => RESET OTHER VALUES
            setFirstTry(true);
        }

        // * IF THE INPUT IS NOT A VALUE => DON'T UPDATE THE FORM
        if (name === fields.businessNumber.name || name === fields.shareHolders.name) {
            let isValueNumber = isNumber(val);

            if (!isValueNumber) {
                return;
            }
        }

        if (name === fields.businessType.name) {
            val = Number(val);
        }

        dispatch(Actions.updateSignupForm({ [key]: val }));
    };

    const handleNextButtonClick = (e) => {
        if (e) {
            e.preventDefault();
        }

        setFirstTry(false);

        let validationResult = validateForm(true);

        if (validationResult) {
            const onSuccess = (response) => {
                if (response.status === 1) {
                    navigateToNextStep(validationResult, location, navigate);
                }
            };

            const onFailure = (response) => {
                if (response.status === 0) {
                    handleServerError(response);
                }
            };

            const props = {
                onSuccess,
                onFailure,
                payload: {
                    [fields.businessNumber.key]: signupForm[fields.businessNumber.key],
                    [fields.businessType.key]: signupForm[fields.businessType.key],
                },
            };

            Api.updateBusinessNumberType(props);
        }
    };

    return (
        <form className="business-info-type-wrapper" onSubmit={handleNextButtonClick}>
            <div className="business-info-type-data">
                <HeaderTitle>{translate("business_info_type_header")}</HeaderTitle>
                <Subheader>{translate("business_info_fill_form")}</Subheader>

                <RadioButton
                    label={translate("business_info_choose_business_type")}
                    type={fields.businessType.type}
                    name={fields.businessType.name}
                    options={businessOptions}
                    showError={!firstTry && formData[fields.businessType.name].isValid?.valid === false}
                    errorMessage={formData[fields.businessType.name].isValid?.errMsg}
                    onChange={handleInputChange}
                    value={signupForm[fields.businessType.key] || ""}
                />

                {signupForm[fields.businessNumber.key] ? (
                    <TextInput
                        label={translate(fields.businessNumber.label)}
                        keyboardType={fields.businessNumber.keyboardType}
                        placeholder={translate(fields.businessNumber.placeholder)}
                        name={fields.businessNumber.name}
                        value={signupForm[fields.businessNumber.key] || ""}
                        onChange={handleInputChange}
                        disabled={true}
                    />
                ) : (
                    <></>
                )}
            </div>

            <Button isNext={true} onClick={handleNextButtonClick} />
        </form>
    );
};

export default BusinessType;
