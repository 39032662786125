import "./index.scss";

const Hint = ({ children, fill = true }) => {
    return (
        <div className={`hint-wrapper${fill ? "" : " no-fill"}`}>
            <div className="hint-text">{children}</div>
        </div>
    );
};

export default Hint;
