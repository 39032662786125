import Api from "api/requests";
import { generateDefaultFormData, isNumber, navigateToNextStep } from "app/functions";
import useApiError from "app/hooks/useApiError";
import useTranslate from "app/hooks/useTranslate";
import useValidate from "app/hooks/useValidate";
import AutoComplete from "components/forms/autocomplete/highlighted-query";
import Button from "components/forms/button";
import Hint from "components/forms/hint";
import TextInput from "components/forms/textInput";
import HeaderTitle from "components/header_title";
import { BOOLEAN_VALUES, DELAY_DEBOUNCE, FINANCE_INFO_BANK_TYPE } from "constants/input-fields";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Actions from "redux/actions";
import "./index.scss";

const BankData = ({ fields, location }) => {
    const defaultFormData = generateDefaultFormData(fields);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const translate = useTranslate();
    const handleServerError = useApiError();
    const signupForm = useSelector((store) => store.signupForm);
    const requestingState = useSelector((store) => store.requestingState);
    const branchOptions = useSelector((store) => store.bankBranches);
    const bankBrands = useSelector((store) => store.bankBrands);

    const [firstTry, setFirstTry] = useState(true);
    const [formData, setFormData] = useState(defaultFormData);
    const [apiValidationError, setApiValidationError] = useState();
    const validationObj = { defaultFormData, formData, setFormData, location };
    const validateForm = useValidate(validationObj);

    const getBranchNameBasedOnId = (id) => {
        const branchData = branchOptions.find((branch) => parseInt(branch?.payload?.branchId) === id) ?? false;

        if (branchData) {
            return branchData;
        }
    };

    const formBrandId = signupForm[fields.branchOptions.key];
    const branchName = getBranchNameBasedOnId(parseInt(formBrandId));
    const [bankBranch, setBankBranch] = useState(branchName?.text ?? "");
    const [isBranchListOpen, setIsBranchListOpen] = useState(branchName ? false : true);
    const selectedBankBrand = bankBrands.find((bank) => bank.bank_code === signupForm[FINANCE_INFO_BANK_TYPE.bankTypes.key]);
    const selectedBankTextTemplate = translate(fields.selectedBank.text);
    const selectedBankText = selectedBankTextTemplate.replace("{{bank_brand}}", selectedBankBrand?.bank_name).replace("{{bank_number}}", selectedBankBrand?.bank_code);

    useEffect(() => {
        const delayDebounce = setTimeout(() => {
            validateForm(false);
        }, DELAY_DEBOUNCE);

        return () => clearTimeout(delayDebounce);
    }, [signupForm]);

    useEffect(() => {
        if (!branchName) {
            dispatch(Actions.removeFromSignupForm([fields.branchOptions.key]));
        }
    }, []);

    const handleInputChange = (e) => {
        setApiValidationError(false);

        let type = e?.target?.type;
        let name = e?.target?.name || e?.name;
        let val = e?.target?.value || e?.value;
        let key = formData[name]?.inputKey;

        if (type === "autocomplete") {
            key = fields.branchOptions.key;
            val = e.data.payload.branchId;
            setBankBranch(e.data.text);
        } else {
            if (type === "checkbox") {
                let isChecked = e.target.checked;
                key = e.target.name;

                if (!isChecked) {
                    dispatch(Actions.removeFromSignupForm([key]));
                    return;
                }

                val = BOOLEAN_VALUES[isChecked];
            } else if (type === "radio") {
                val = BOOLEAN_VALUES[val == BOOLEAN_VALUES.true];
            }

            if (formData[name]?.keyboardType === "tel" && !isNumber(val) && val?.length > 0) {
                return;
            }

            if (name === fields.branchOptions.name) {
                setBankBranch(val);
                dispatch(Actions.removeFromSignupForm([fields.branchOptions.key]));
                return;
            }
        }

        dispatch(Actions.updateSignupForm({ [key]: val }));
    };

    const handleNextButtonClick = (e) => {
        if (e) {
            e.preventDefault();
        }

        setFirstTry(false);

        let validationResult = validateForm(true);

        if (validationResult) {
            setFirstTry(true);

            const onSuccess = (response) => {
                if (response.status === 1) {
                    navigateToNextStep(validationResult, location, navigate);
                }
            };

            const onFailure = (response) => {
                if (response.status === 0) {
                    const errorId = parseInt(response?.err?.id);
                    const errorMessage = response?.err?.message || "";
                    setFirstTry(false);

                    // * IF WE RECEIVE AN ERROR OF INVALID BANK ACCOUNT DETAILS => HANDLE IT AS AN INLINE ERROR INSTEAD OF POPUP
                    if (errorId === 44 || errorId === 146) {
                        setApiValidationError(errorMessage);
                        return;
                    }

                    handleServerError(response);
                }
            };

            const props = {
                onSuccess,
                onFailure,
                payload: {
                    [FINANCE_INFO_BANK_TYPE.bankTypes.key]: signupForm[FINANCE_INFO_BANK_TYPE.bankTypes.key],
                    [fields.branchOptions.key]: String(signupForm[fields.branchOptions.key]),
                    [fields.bankAccountNumber.key]: String(signupForm[fields.bankAccountNumber.key]),
                    [fields.bankAccountOwners.key]: signupForm[fields.bankAccountOwners.key],
                },
            };

            Api.updateBankData(props);
        }
    };

    const showErrorMessage = (name) => {
        if (apiValidationError) {
            return BOOLEAN_VALUES.true;
        }

        return formData[name].isValid?.valid === false;
    };

    return (
        <form className="finance-info-bank-wrapper" onSubmit={handleNextButtonClick}>
            <div className="finance-info-bank-data">
                <HeaderTitle>{translate(fields.headerTitle.label)}</HeaderTitle>

                {selectedBankBrand ? (
                    <div className="finance-info-bank-selected-wrapper">
                        <div className="finance-info-bank-selected-logo">
                            <img src={selectedBankBrand.img} alt={selectedBankBrand.text} />
                        </div>
                        <div className="finance-info-bank-selected-text">{selectedBankText}</div>
                    </div>
                ) : (
                    <></>
                )}

                <Hint>
                    <div className="hint-header">{translate(fields.hint.header)}</div>
                    <div className="hint-content">{translate(fields.hint.text)}</div>
                </Hint>

                <AutoComplete
                    className="field"
                    fieldKey={"text"}
                    name={fields.branchOptions.name}
                    placeholder={translate(fields.branchOptions.placeholder)}
                    onChange={handleInputChange}
                    onSelect={handleInputChange}
                    options={branchOptions}
                    query={bankBranch}
                    value={bankBranch}
                    label={translate(fields.branchOptions.label)}
                    showError={!firstTry && formData[fields.branchOptions.name].isValid?.valid === false}
                    errorMessage={formData[fields.branchOptions.name].isValid?.errMsg}
                    isOpen={isBranchListOpen}
                    disabled={requestingState}
                />

                {formBrandId && (
                    <>
                        <TextInput
                            label={translate(fields.bankAccountNumber.label)}
                            name={fields.bankAccountNumber.name}
                            value={signupForm[fields.bankAccountNumber.key] || ""}
                            onChange={handleInputChange}
                            showError={!firstTry && showErrorMessage(fields.bankAccountNumber.name)}
                            errorMessage={apiValidationError || formData[fields.bankAccountNumber.name].isValid?.errMsg}
                        />

                        <TextInput
                            label={translate(fields.bankAccountOwners.label)}
                            name={fields.bankAccountOwners.name}
                            value={signupForm[fields.bankAccountOwners.key] || ""}
                            onChange={handleInputChange}
                            showError={!firstTry && formData[fields.bankAccountOwners.name].isValid?.valid === false}
                            errorMessage={formData[fields.bankAccountOwners.name].isValid?.errMsg}
                        />
                    </>
                )}
            </div>

            <Button isNext={true} onClick={handleNextButtonClick} />
        </form>
    );
};

export default BankData;
