import Au10tixError from "containers/au10tix/error";
import Au10tixSuccess from "containers/au10tix/success";

export const au10tixObject = {
    key: "au10tixPath",
    path: "au10tix",
    subRoutes: {
        success: {
            key: "au10tixSuccess",
            path: "success",
            component: <Au10tixSuccess />,
        },
        error: {
            key: "au10tixError",
            path: "error",
            component: <Au10tixError />,
        },
    },
};
