import Api from "api/requests";
import { generateDefaultFormData, isNumber, navigateToNextStep } from "app/functions";
import useApiError from "app/hooks/useApiError";
import useTranslate from "app/hooks/useTranslate";
import useValidate from "app/hooks/useValidate";
import Button from "components/forms/button";
import Checkbox from "components/forms/checkbox";
import Hint from "components/forms/hint";
import RadioButton from "components/forms/radio-button";
import HeaderTitle from "components/header_title";
import Subheader from "components/subheader";
import { BUSINESS_INFO_TYPE, DELAY_DEBOUNCE } from "constants/input-fields";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Actions from "redux/actions";
import "./index.scss";

const ProductFinance = ({ fields, location }) => {
    const defaultFormData = generateDefaultFormData(fields);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const translate = useTranslate();
    const handleServerError = useApiError();
    const signupForm = useSelector((store) => store.signupForm);

    const [firstTry, setFirstTry] = useState(true);
    const [formData, setFormData] = useState(defaultFormData);
    const validationObj = { defaultFormData, formData, setFormData, location };
    const validateForm = useValidate(validationObj);
    const isNonProfitOrganization = signupForm[BUSINESS_INFO_TYPE.businessType.key] === BUSINESS_INFO_TYPE.businessType.options.nonProfitOrganization.value ?? false;

    useEffect(() => {
        const delayDebounce = setTimeout(() => {
            validateForm(false);
        }, DELAY_DEBOUNCE);

        return () => clearTimeout(delayDebounce);
    }, [signupForm]);

    useEffect(() => {
        const fundingSourcesKey = fields.fundingSources.key;
        const currentFormValue = signupForm[fundingSourcesKey];
        const defaultNonProfitOrganizationValue = fields.fundingSources.defaultNonProfitOrganization;

        // * IF BUSINESS TYPE IS 'NON PROFIT ORGANIZATION' THEN WE SHOULD SEND A DEFAULT VALUE BECAUSE OTHER OPTIONS ARE NOT ACCESSIBLE
        if (isNonProfitOrganization) {
            const newFundingSourcesValue = {
                [fundingSourcesKey]: defaultNonProfitOrganizationValue,
            };

            dispatch(Actions.updateSignupForm(newFundingSourcesValue));
            return;
        }

        // * IF BUSINESS TYPE IS NOT 'NON PROFIT ORGANIZATION' AND WE GOT A DEFAULT VALUE OF 16 => THEN REMOVE IT
        if ((currentFormValue & defaultNonProfitOrganizationValue) > 0) {
            const newFundingSourcesValue = { [fundingSourcesKey]: currentFormValue & ~defaultNonProfitOrganizationValue };
            dispatch(Actions.updateSignupForm(newFundingSourcesValue));
        }
    }, []);

    const handleInputChange = (e) => {
        let type = e?.target?.type;
        let name = e?.target?.name || e?.name;
        let val = e?.target?.value || e?.value;
        let key = formData[name]?.inputKey;
        let id = e.target?.id;

        if (type === "checkbox") {
            let isChecked = e.target.checked;
            key = e.target.name;

            const [optionObj] = Object.values(fields.fundingSources.options).filter((val) => val.id === id);
            const bitwiseValue = optionObj.bitwiseValue;

            if (!isChecked) {
                // * REMOVE BITWISE VALUE IF UNCHECKED
                val = signupForm[key] & ~bitwiseValue;
            } else {
                // * ADD BITWISE VALUE IF CHECKED
                val = signupForm[key] | bitwiseValue;
            }

            if (val === 0) {
                dispatch(Actions.removeFromSignupForm([key]));
                return;
            }
        } else if (type === "radio") {
            val = Number(val);
        }

        if (formData[name]?.keyboardType === "tel" && !isNumber(val) && val?.length > 0) {
            return;
        }

        dispatch(Actions.updateSignupForm({ [key]: val }));
    };

    const handleNextButtonClick = (e) => {
        if (e) {
            e.preventDefault();
        }

        setFirstTry(false);

        let validationResult = validateForm(true);

        if (validationResult) {
            const onSuccess = (response) => {
                if (response.status === 1) {
                    navigateToNextStep(validationResult, location, navigate);
                }
            };

            const onFailure = (response) => {
                if (response.status === 0) {
                    handleServerError(response);
                }
            };

            const props = {
                onSuccess,
                onFailure,
                payload: {
                    [fields.averageTransaction.key]: signupForm[fields.averageTransaction.key],
                    [fields.monthlyClearing.key]: signupForm[fields.monthlyClearing.key],
                    [fields.fundingSources.key]: signupForm[fields.fundingSources.key],
                },
            };

            Api.numbersQuestions(props);
        }
    };

    return (
        <form className="product-info-finance-wrapper" onSubmit={handleNextButtonClick}>
            <div className="product-info-finance-data">
                <HeaderTitle>{translate(fields.headerTitle.label)}</HeaderTitle>
                <Subheader>{translate(fields.headerTitle.subheader)}</Subheader>

                <RadioButton
                    type={fields.averageTransaction.type}
                    label={translate(fields.averageTransaction.label)}
                    options={fields.averageTransaction.options}
                    name={fields.averageTransaction.name}
                    showError={!firstTry && formData[fields.averageTransaction.name].isValid?.valid === false}
                    errorMessage={formData[fields.averageTransaction.name].isValid?.errMsg}
                    onChange={handleInputChange}
                    value={signupForm[fields.averageTransaction.key] || ""}
                />

                <RadioButton
                    type={fields.monthlyClearing.type}
                    label={translate(fields.monthlyClearing.header)}
                    options={fields.monthlyClearing.options}
                    name={fields.monthlyClearing.name}
                    showError={!firstTry && formData[fields.monthlyClearing.name].isValid?.valid === false}
                    errorMessage={formData[fields.monthlyClearing.name].isValid?.errMsg}
                    onChange={handleInputChange}
                    value={signupForm[fields.monthlyClearing.key] || ""}
                />

                {!isNonProfitOrganization && (
                    <div className="product-info-finance-funding-sources">
                        <Checkbox
                            type={fields.fundingSources.type}
                            label={translate(fields.fundingSources.label)}
                            subLabel={translate(fields.fundingSources.subLabel)}
                            options={fields.fundingSources.options}
                            showError={!firstTry && formData[fields.fundingSources.name].isValid?.valid === false}
                            errorMessage={formData[fields.fundingSources.name].isValid?.errMsg}
                            onChange={handleInputChange}
                            value={signupForm[fields.fundingSources.key] || ""}
                        />
                    </div>
                )}

                <Hint>
                    <div className="product-info-finance-hint-header">{translate(fields.fundingSources.hintHeader)}</div>
                    <div className="product-info-finance-hint-text">{translate(fields.fundingSources.hintText)}</div>
                </Hint>
            </div>

            <Button isNext={true} onClick={handleNextButtonClick} />
        </form>
    );
};

export default ProductFinance;
