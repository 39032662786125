import Api from "api/requests";
import { generateDefaultFormData, isNumber, navigateToNextStep } from "app/functions";
import useApiError from "app/hooks/useApiError";
import useTranslate from "app/hooks/useTranslate";
import useValidate from "app/hooks/useValidate";
import Button from "components/forms/button";
import Checkbox from "components/forms/checkbox";
import RadioButton from "components/forms/radio-button";
import HeaderTitle from "components/header_title";
import { DELAY_DEBOUNCE, PRODUCT_INFO_GENERAL } from "constants/input-fields";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Actions from "redux/actions";
import "./index.scss";

const ProductDuration = ({ fields, location }) => {
    const defaultFormData = generateDefaultFormData(fields);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const translate = useTranslate();
    const signupForm = useSelector((store) => store.signupForm);

    const [firstTry, setFirstTry] = useState(true);
    const [formData, setFormData] = useState(defaultFormData);
    const validationObj = { defaultFormData, formData, setFormData, location };
    const validateForm = useValidate(validationObj);
    const handleServerError = useApiError();

    // * GET SELECTED SELLING TYPE FROM FORM
    const sellingBitwiseType = signupForm[PRODUCT_INFO_GENERAL.question_selling.key];

    // * GET SERVICES AND PRODUCTS BITWISE VALUES
    const servicesBitwise = PRODUCT_INFO_GENERAL.question_selling.options.services.bitwiseValue;
    const productsBitwise = PRODUCT_INFO_GENERAL.question_selling.options.products.bitwiseValue;

    // * CHECK THE TYPE OF THE SELECTED SELLING TYPE
    const isSellingServices = (sellingBitwiseType & servicesBitwise) > 0;
    const isSellingProducts = (sellingBitwiseType & productsBitwise) > 0;

    useEffect(() => {
        const delayDebounce = setTimeout(() => {
            validateForm(false);
        }, DELAY_DEBOUNCE);

        return () => clearTimeout(delayDebounce);
    }, [signupForm]);

    const handleInputChange = (e) => {
        let type = e?.target?.type;
        let name = e?.target?.name || e?.name;
        let val = e?.target?.value || e?.value;
        let key = formData[name]?.inputKey;
        let id = e.target?.id;

        if (type === "checkbox") {
            let isChecked = e.target.checked;
            key = e.target.name;

            const [optionObj] = Object.values(fields.productsOrigin.options).filter((val) => val.id === id);
            const bitwiseValue = optionObj.bitwiseValue;

            if (!isChecked) {
                // * REMOVE BITWISE VALUE IF UNCHECKED
                val = signupForm[key] & ~bitwiseValue;
            } else {
                // * ADD BITWISE VALUE IF CHECKED
                val = signupForm[key] | bitwiseValue;
            }

            if (val === 0) {
                dispatch(Actions.removeFromSignupForm([key]));
                return;
            }
        } else if (type === "radio") {
            val = Number(val);
        }

        if (formData[name]?.keyboardType === "tel" && !isNumber(val) && val?.length > 0) {
            return;
        }

        dispatch(Actions.updateSignupForm({ [key]: val }));
    };

    const handleNextButtonClick = (e) => {
        if (e) {
            e.preventDefault();
        }

        setFirstTry(false);

        let validationResult = validateForm(true);

        if (validationResult) {
            const onSuccess = (response) => {
                if (response.status === 1) {
                    navigateToNextStep(validationResult, location, navigate);
                }
            };

            const onFailure = (response) => {
                if (response.status === 0) {
                    handleServerError(response);
                }
            };

            const props = {
                onSuccess,
                onFailure,
                payload: {
                    ...(isSellingServices && { [fields.serviceDuration.key]: signupForm[fields.serviceDuration.key] }),
                    ...(isSellingProducts && { [fields.productDeliveryTime.key]: signupForm[fields.productDeliveryTime.key] }),
                    ...(isSellingProducts && { [fields.productsOrigin.key]: signupForm[fields.productsOrigin.key] }),
                },
            };

            Api.generalServiceQuestions(props);
        }
    };

    const renderProductsComponent = () => {
        return (
            <>
                <RadioButton
                    label={translate(fields.productDeliveryTime.label)}
                    subLabel={translate(fields.productDeliveryTime.subLabel)}
                    type={fields.productDeliveryTime.type}
                    name={fields.productDeliveryTime.name}
                    options={fields.productDeliveryTime.options}
                    showError={!firstTry && formData[fields.productDeliveryTime.name].isValid?.valid === false}
                    errorMessage={formData[fields.productDeliveryTime.name].isValid?.errMsg}
                    onChange={handleInputChange}
                    value={signupForm[fields.productDeliveryTime.key] || ""}
                    isShort={true}
                />

                <div className="product-info-duration-origin-wrapper">
                    <div className="product-info-duration-origin-header">{translate(fields.productsOrigin.label)}</div>
                    <div className="product-info-duration-origin-subheader">{translate(fields.productsOrigin.subLabel)}</div>

                    <div className="product-info-duration-origin-checkboxes">
                        {Object.values(fields.productsOrigin.options).map((option) => {
                            return (
                                <Checkbox
                                    key={option.id}
                                    type={fields.productsOrigin.type}
                                    id={option.id}
                                    name={option.name}
                                    label={translate(option.text)}
                                    onChange={handleInputChange}
                                    showError={false}
                                    isShort={true}
                                    checked={signupForm[option.name] & option.bitwiseValue}
                                />
                            );
                        })}
                    </div>

                    {!firstTry && formData[fields.productsOrigin.name].isValid?.valid === false && (
                        <div className="product-info-duration-origin-error-message">{formData[fields.productsOrigin.name].isValid?.errMsg}</div>
                    )}
                </div>
            </>
        );
    };

    const renderServicesComponent = () => {
        return (
            <RadioButton
                label={translate(fields.serviceDuration.label)}
                subLabel={translate(fields.serviceDuration.subLabel)}
                type={fields.serviceDuration.type}
                name={fields.serviceDuration.name}
                options={fields.serviceDuration.options}
                showError={!firstTry && formData[fields.serviceDuration.name].isValid?.valid === false}
                errorMessage={formData[fields.serviceDuration.name].isValid?.errMsg}
                onChange={handleInputChange}
                value={signupForm[fields.serviceDuration.key] || ""}
            />
        );
    };

    return (
        <form className="product-info-duration-wrapper" onSubmit={handleNextButtonClick}>
            <div className="product-info-duration-data">
                <HeaderTitle>{translate(fields.headerTitle.label)}</HeaderTitle>
                {translate(fields.headerTitle.sub)?.trim() && <div className="product-info-duration-sub-label">{translate(fields.headerTitle.sub)}</div>}

                {isSellingServices ? renderServicesComponent() : <></>}
                {isSellingProducts ? renderProductsComponent() : <></>}
            </div>

            <Button isNext={true} onClick={handleNextButtonClick} />
        </form>
    );
};

export default ProductDuration;
