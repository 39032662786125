import useTranslate from "app/hooks/useTranslate";
import Button from "components/forms/button";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "redux/actions";
import errorImage from "../../../assets/images/popup-error.svg";
import "./index.scss";

const BasicPopup = ({ payload }) => {
    const dispatch = useDispatch();
    const [animationClass, setAnimationClass] = useState("");
    const translate = useTranslate();

    const innerText = (payload && payload.text ? translate(payload.text) : translate("popup_default_error_text")) || "אירעה שגיאה כללית";
    const buttonText = (payload && payload.btnText ? translate(payload.btnText) : translate("popup_default_button_text")) || "אישור";
    let animationFrame;

    useEffect(() => {
        animateIn();

        return () => {
            cancelAnimationFrame(animationFrame);
        };
    }, []);

    const animateIn = () => {
        animationFrame = requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                setAnimationClass("active");
            });
        });
    };

    const completeAnimation = () => {
        if (animationClass !== "exit" && animationClass !== "done") {
            setAnimationClass("done");
        }
    };

    /* first remove the active class for exit animation then call the callback and remove the popup */
    const animateOut = () => {
        let callback = payload?.callback;

        setAnimationClass("exit");

        setTimeout(() => {
            if (callback) {
                callback();
            }

            cancelAnimationFrame(animationFrame);
            dispatch(Actions.removePopup());
        }, 200);
    };

    return (
        <div className={"backdrop general-msg " + animationClass} onClick={animateOut} onTransitionEnd={completeAnimation}>
            <div className={"popup_wrapper " + animationClass} onClick={(e) => e.stopPropagation()}>
                <div className="popup_content">
                    <div className="popup_error_image">
                        <img src={errorImage} alt={translate("popup_error_image_alt")} />
                    </div>
                    <div className="popup_text">{innerText}</div>
                </div>

                <Button onClick={animateOut} isPopup={true}>
                    {buttonText}
                </Button>
            </div>
        </div>
    );
};

export default BasicPopup;
