import { generateDefaultFormData, navigateToNextStep } from "app/functions";
import useTranslate from "app/hooks/useTranslate";
import useValidate from "app/hooks/useValidate";
import Button from "components/forms/button";
import RadioButton from "components/forms/radio-button";
import HeaderTitle from "components/header_title";
import { DELAY_DEBOUNCE } from "constants/input-fields";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Actions from "redux/actions";
import Api from "api/requests";
import "./index.scss";
import useApiError from "app/hooks/useApiError";

const BankType = ({ fields, location }) => {
    const defaultFormData = generateDefaultFormData(fields);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const translate = useTranslate();
    const signupForm = useSelector((store) => store.signupForm);
    const bankBrands = useSelector((store) => store.bankBrands);

    const [firstTry, setFirstTry] = useState(true);
    const [formData, setFormData] = useState(defaultFormData);
    const validationObj = { defaultFormData, formData, setFormData, location };
    const validateForm = useValidate(validationObj);
    const handleServerError = useApiError();

    useEffect(() => {
        const delayDebounce = setTimeout(() => {
            validateForm(false);
        }, DELAY_DEBOUNCE);

        return () => clearTimeout(delayDebounce);
    }, [signupForm]);

    useEffect(() => {
        // * RUN THIS METHOD ONLY IF BANKS ARE NOT AVAILABLE IN REDUX
        if (bankBrands.length === 0) {
            Api.getBanks();
        }
    }, []);

    const handleInputChange = (e) => {
        let name = e?.target?.name || e?.name;
        let val = e?.target?.value || e?.value;
        let key = formData[name]?.inputKey;

        dispatch(Actions.updateSignupForm({ [key]: Number(val) }));
    };

    const handleNextButtonClick = (e) => {
        if (e) {
            e.preventDefault();
        }

        setFirstTry(false);

        let validationResult = validateForm(true);

        if (validationResult) {
            const onSuccess = (response) => {
                if (response.status === 1) {
                    const { data } = response;

                    const branchesArr = [];
                    let idx = 1;
                    for (let [branchId, branchName] of Object.entries(data)) {
                        branchesArr.push({ text: `${branchId} - ${branchName}`, id: idx, payload: { branchId: branchId } });
                        idx++;
                    }

                    dispatch(Actions.setBankBranches(branchesArr));
                    navigateToNextStep(validationResult, location, navigate);
                }
            };

            const onFailure = (response) => {
                if (response.status === 0) {
                    handleServerError(response);
                }
            };

            const props = {
                onSuccess,
                onFailure,
                payload: {
                    [fields.bankTypes.key]: signupForm[fields.bankTypes.key],
                },
            };

            Api.updateBankBrand(props);
        }
    };

    // * VALIDATE THAT THE SELECTED BANK ID IS ACCEPTABLE
    const validateBankType = () => bankBrands.find((bank) => parseInt(bank.bank_code) === parseInt(signupForm[fields.bankTypes.key])) ?? false;

    return (
        <form className="finance-info-bank-type-wrapper" onSubmit={handleNextButtonClick}>
            <div className="finance-info-bank-type-data">
                <HeaderTitle>{translate(fields.headerTitle.label)}</HeaderTitle>

                <div className="banks-types-wrapper">
                    <RadioButton
                        type={fields.bankTypes.type}
                        label={translate(fields.bankTypes.header)}
                        options={bankBrands}
                        name={fields.bankTypes.name}
                        onChange={handleInputChange}
                        value={signupForm[fields.bankTypes.key]}
                        showError={!firstTry && formData[fields.bankTypes.name].isValid?.valid === false}
                        errorMessage={formData[fields.bankTypes.name].isValid?.errMsg}
                        textKey={"bank_name"}
                    />
                </div>

                <Button isNext={true} onClick={handleNextButtonClick} disabled={!validateBankType()} />
            </div>
        </form>
    );
};

export default BankType;
