import popupTypes from "constants/popup-types";
import { useDispatch } from "react-redux";
import Actions from "redux/actions";
import useTranslate from "./useTranslate";

const useApiError = () => {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const defaultErrorMessage = translate("popup_default_error_text");

    const addErrorPopup = (response) => {
        const popupPayload = {
            text: response?.err?.message ?? defaultErrorMessage,
            btnText: response?.btnText,
        };

        dispatch(
            Actions.addPopup({
                type: popupTypes.API_ERROR,
                payload: popupPayload,
            })
        );
    };

    return addErrorPopup;
};

export default useApiError;
