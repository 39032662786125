import { generateUniqueId } from "app/functions";
import useTranslate from "app/hooks/useTranslate";
import { BUSINESS_INFO_ADDRESS, FINANCE_INFO_BANK_TYPE } from "constants/input-fields";
import { useSelector } from "react-redux";
import "./index.scss";

const RadioButton = (props) => {
    const translate = useTranslate();
    const {
        type,
        label = "",
        subLabel = "",
        options,
        tabIndex = 0,
        name,
        showError = false,
        errorMessage,
        onChange,
        value,
        isShort,
        isOccupation,
        questionId,
        occupationValidationName,
        textKey = false,
        squareCorners = false,
    } = props;

    const isOptions = type === "options" || type === "options-column";

    // are the radio buttons stacked?
    const isStacked =
        type === "stacked" ||
        type === "stacked-image" ||
        type === "stacked-spaced" ||
        type === "stacked-spaced-dynamically" ||
        type === "stacked-spaced-image" ||
        type === "stacked-no-check";

    // are the radio buttons spaced?
    const isSpaced = type === "stacked-spaced" || type === "stacked-spaced-dynamically" || type === "stacked-spaced-image";

    const isOptionsAnArray = Array.isArray(options) ? options : Object.values(options);

    const occupationAreaQuestionsData = useSelector((store) => store.occupationAreaData.questions);
    const occupationAreaValidation = useSelector((store) => store.occupationAreaData.validation);
    const questionErrorMessage = occupationAreaValidation[occupationValidationName]?.isValid.errMsg;
    const errorMessageId = `${generateUniqueId(12)}_err`;

    const isOptionValue = (option) => {
        if (option.bank_code) {
            return option.bank_code;
        }

        return option.value;
    };

    return (
        <>
            {isOptions && (
                <div className="radio-button-options-wrapper">
                    <div className={`radio-button-options-main${type === "options-column" ? " column" : ""}`}>
                        {label && <div className="radio-button-options-label">{label}</div>}

                        <div className="radio-button-options-options-wrapper">
                            {isOptionsAnArray.map((option) => {
                                const isChecked = isOccupation ? occupationAreaQuestionsData[questionId] === option.value ?? "" : value === option.value;

                                return (
                                    <div
                                        key={option.id}
                                        className={`radio-button-options-option-wrapper${isChecked ? " radio-checked" : ""}${squareCorners ? " square-corners" : ""}`}
                                    >
                                        <input
                                            type="radio"
                                            id={option.id}
                                            name={name}
                                            value={option.value}
                                            className="radio-button-options-option-input"
                                            onChange={onChange}
                                            checked={isChecked}
                                            tabIndex={option.disabled ? -1 : tabIndex}
                                            {...(questionId ? { ["data-question-id"]: questionId } : {})}
                                            {...(showError ? { "aria-describedby": errorMessageId } : {})}
                                        />
                                        <label className="radio-button-options-option-label" htmlFor={option.id} tabIndex={-1}>
                                            {translate(option.text) ? translate(option.text) : option.text}
                                        </label>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    {showError && !isOccupation && (
                        <div className="radio-button-error-message" id={errorMessageId}>
                            {errorMessage}
                        </div>
                    )}
                    {isOccupation && !occupationAreaQuestionsData.hasOwnProperty(questionId) && (
                        <div className="radio-button-error-message" id={errorMessageId}>
                            {questionErrorMessage}
                        </div>
                    )}

                    {!showError && !isOccupation && <div className="radio-button-error-message-placeholder"></div>}
                    {isOccupation && occupationAreaQuestionsData.hasOwnProperty(questionId) && <div className="radio-button-error-message-placeholder"></div>}
                </div>
            )}

            {isStacked && (
                <div className={`radio-button-stacked-wrapper${isSpaced ? " spaced" : ""}${isShort ? " short" : ""}`}>
                    <div className="radio-button-stacked-main">
                        {label?.trim() && <div className="radio-button-stacked-label">{label}</div>}
                        {subLabel?.trim() && <div className="radio-button-stacked-sub-label">{subLabel}</div>}

                        <div
                            className={`radio-button-stacked-options-wrapper${isSpaced && type !== "stacked-spaced-image" ? " spaced" : ""}${
                                isSpaced && type === "stacked-spaced-image" ? " spaced-images" : ""
                            }`}
                        >
                            {isOptionsAnArray.map((option) => {
                                const isChecked = typeof value !== "undefined" && value === isOptionValue(option);
                                const isDisabled = option.disabled;
                                const isBankTypes = FINANCE_INFO_BANK_TYPE.bankTypes.name === name;
                                const isBusinessSellingType = BUSINESS_INFO_ADDRESS.businessSellingType.name === name;
                                const imageSource = typeof option.img === "object" ? (value === option.value ? option.img.selected : option.img.regular) : option.img;

                                return (
                                    <div
                                        key={option.id}
                                        className={`radio-button-stacked-option-wrapper${type === "stacked-spaced-dynamically" ? " spaced-dynamically" : ""}${
                                            type === "stacked-spaced" ? " spaced" : ""
                                        }${type === "stacked-spaced-image" ? " spaced-images" : ""}${isChecked ? " radio-checked" : ""}${
                                            isDisabled ? " radio-disabled" : ""
                                        }${type === "stacked-no-check" ? " no-check" : ""}${isBankTypes ? " bank-logos" : ""}${
                                            isBusinessSellingType ? " selling-type" : ""
                                        }`}
                                    >
                                        <input
                                            type="radio"
                                            id={option.id}
                                            name={name}
                                            value={isOptionValue(option)}
                                            className="radio-button-stacked-option-input"
                                            onChange={onChange}
                                            checked={isChecked}
                                            disabled={isDisabled}
                                            tabIndex={type === "stacked-no-check" ? -1 : tabIndex}
                                            {...(showError ? { "aria-describedby": errorMessageId } : {})}
                                        />
                                        <label
                                            className={`radio-button-stacked-option-label${type === "stacked-spaced-image" ? " spaced-images" : ""}`}
                                            htmlFor={option.id}
                                            tabIndex={type === "stacked-no-check" ? tabIndex : -1}
                                        >
                                            {type === "stacked-spaced-image" && (
                                                <div
                                                    className={`radio-button-stacked-option-stacked-image-wrapper${isBankTypes ? " bank-logos" : ""}${
                                                        isBusinessSellingType ? " selling-type" : ""
                                                    }`}
                                                >
                                                    <img src={imageSource} alt={option.value ?? option.text} />
                                                </div>
                                            )}

                                            <div className="radio-button-stacked-option-text-wrapper">
                                                <div className="radio-button-stacked-option-text">{translate(textKey ? option?.[textKey] : option.text)}</div>
                                                {isBankTypes ? <div className="radio-button-stacked-bank-code">{option.bank_code}</div> : <></>}
                                            </div>
                                        </label>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    {showError && (
                        <div className="radio-button-error-message" id={errorMessageId}>
                            {errorMessage}
                        </div>
                    )}
                    {!showError && <div className="radio-button-error-message-placeholder"></div>}
                </div>
            )}
        </>
    );
};

export default RadioButton;
