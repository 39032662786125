import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
// import { Prompt }      from 'react-router-dom';
import Actions from "redux/actions";
import { disableBodyScroll } from "body-scroll-lock";
import { clearAllBodyScrollLocks } from "body-scroll-lock";

import { generateUniqueId } from "app/functions";

//popup types
import popupTypes from "constants/popup-types";

//popup components
import BasicPopup from "./components/basic";
import TwoActionPopup from "./components/two-action";
import BusinessExistsPopup from "./components/business_exists";
import DocumentPreviewPopup from "./components/document_preview";

import "./index.scss";

export default function Popups() {
    const popupsArray = useSelector((store) => store.popupsArray);
    const dispatch = useDispatch();

    //stop body from scrolling while popup is open
    useEffect(() => {
        let popupContainer = document.querySelector("#popupContainer");
        disableBodyScroll(popupContainer);

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    //map popup types to popup components
    const getPopupComponent = (key, type, payload) => {
        let popupComponents = {
            /* general popups */
            [popupTypes.GENERAL_MESSAGE]: <BasicPopup key={key} payload={payload} />,
            [popupTypes.API_MESSAGE]: <BasicPopup key={key} payload={payload} />,
            [popupTypes.API_ERROR]: <BasicPopup key={key} payload={payload} />,
            [popupTypes.TWO_ACTION]: <TwoActionPopup key={key} payload={payload} />,
            [popupTypes.BUSINESS_EXISTS]: <BusinessExistsPopup key={key} payload={payload} />,
            [popupTypes.DOCUMENT_PREVIEW]: <DocumentPreviewPopup key={key} payload={payload} />,
        };

        let popupToReturn = type in popupComponents ? popupComponents[type] : <BasicPopup key={key} payload={{ text: "unknown popup type" }} />;
        return popupToReturn;
    };

    const renderPopups = () => {
        let popupsToRender = popupsArray.map((popup) => {
            let key = generateUniqueId();
            return getPopupComponent(key, popup.type, popup.payload);
        });

        return popupsToRender;
    };

    //close the popup when back button is pressed instead of going to previous page
    const closePopupOnBackButton = () => {
        dispatch(Actions.removePopup());
        return false;
    };

    return (
        <div className="popup" id="popupContainer">
            {/* <Prompt
                when    = { true }
                message = { closePopupOnBackButton }
            /> */}

            {renderPopups()}
        </div>
    );
}
