import HaPoalim from "../assets/logos/banks/hapoalim.svg";
import Leumi from "../assets/logos/banks/leumi.svg";
import MizrahiTfahot from "../assets/logos/banks/mizrahi-tfahot.svg";
import Discount from "../assets/logos/banks/discount.svg";
import Fibi from "../assets/logos/banks/fibi.svg";
import Union from "../assets/logos/banks/union.svg";
import IsraelPost from "../assets/logos/banks/israel-post.svg";
import Massad from "../assets/logos/banks/massad.svg";
import Mercantile from "../assets/logos/banks/mercantile.svg";
import Pagi from "../assets/logos/banks/pagi.svg";
import Yahav from "../assets/logos/banks/yahav.svg";
import Jerusalem from "../assets/logos/banks/jerusalem.svg";
import UBank from "../assets/logos/banks/u-bank.svg";
import OneZero from "../assets/logos/banks/one-zero.svg";
import Otsar from "../assets/logos/banks/otsar.svg";

export const generateBanksWithLogos = (banks) => {
    const newBanksArr = [];
    const bankCodeId = "bank_code";

    if (Array.isArray(banks) && banks.length > 0) {
        banks.map((bank) => {
            const newObj = { ...bank, id: `${bankCodeId}_${bank.id}`, img: BANKS_LIST[bank.bank_code]?.img };
            newBanksArr.push(newObj);
        });
    }

    return newBanksArr;
};

export const BANKS_LIST = {
    12: {
        img: HaPoalim,
    },
    10: {
        img: Leumi,
    },
    20: {
        img: MizrahiTfahot,
    },
    11: {
        img: Discount,
    },
    31: {
        img: Fibi,
    },
    13: {
        img: Union,
    },
    9: {
        img: IsraelPost,
    },
    46: {
        img: Massad,
    },
    14: {
        img: Otsar,
    },
    17: {
        img: Mercantile,
    },
    52: {
        img: Pagi,
    },
    4: {
        img: Yahav,
    },
    54: {
        img: Jerusalem,
    },
    26: {
        img: UBank,
    },
    18: {
        img: OneZero,
    },
};