import GeneralAgreementTerms from "containers/WizardSteps/AgreementTerms/general";
import CongratulationsScreen from "containers/WizardSteps/AgreementTerms/success";
import BusinessAddress from "containers/WizardSteps/BusinessInfo/address";
import BusinessName from "containers/WizardSteps/BusinessInfo/name";
import BusinessOwner from "containers/WizardSteps/BusinessInfo/owner";
import BusinessType from "containers/WizardSteps/BusinessInfo/type";
import ContactConfirmation from "containers/WizardSteps/ContactInfo/confirmation";
import ContactInfo from "containers/WizardSteps/ContactInfo/general";
import OtpValidation from "containers/WizardSteps/CustomerValidation/otp_validation";
import BankData from "containers/WizardSteps/FinanceInfo/BankData";
import BankType from "containers/WizardSteps/FinanceInfo/BankType";
import OccupationAreas from "containers/WizardSteps/OccupationAreas";
import ProductDuration from "containers/WizardSteps/ProductInfo/duration";
import ProductFinance from "containers/WizardSteps/ProductInfo/finance";
import GeneralProductInfo from "containers/WizardSteps/ProductInfo/general";
import ProductPlatforms from "containers/WizardSteps/ProductInfo/platforms";
import IdValidation from "containers/WizardSteps/UploadDocuments/IdInfo";
import UploadDocuments from "containers/WizardSteps/UploadDocuments/Other";
import BeginProcess from "containers/begin_process";
import Page404 from "containers/page404";
import RouteWrapper from "containers/route_wrapper";
import { Navigate } from "react-router";
import { BUSINESS_INFO_OWNER, CONTACT_INFO_GENERAL, UPLOAD_OTHER_DOCUMENTS } from "./input-fields";
import { mainRootRoutes } from "./main-routes";

const default404Page = Page404;
export const routeWrapper = <RouteWrapper />;

const routesMap = {
    introduction: {
        key: "introductionPath",
        path: mainRootRoutes.introduction,
        subRoutes: {
            begin: {
                path: "begin",
                key: "introductionBeginPath",
                component: BeginProcess,

                // * IS THIS MAIN DEFAULT PAGE TO START WITH
                isMainDefault: true,
            },
        },
    },
    customerValidation: {
        key: "customerValidationPath",
        path: mainRootRoutes.otpValidation,
        subRoutes: {
            otp: {
                key: "otpValidationPath",
                title: "otp_validation_page_title",
                component: OtpValidation,
            },
        },
    },
    businessInfo: {
        key: "businessInfoPath",
        path: mainRootRoutes.businessInfo,
        subRoutes: {
            type: {
                key: "businessTypePath",
                title: "business_type_page_title",
                component: BusinessType,
            },
            name: {
                key: "businessNamePath",
                title: "business_name_page_title",
                component: BusinessName,
            },
            address: {
                key: "businessAddressPath",
                title: "business_address_page_title",
                component: BusinessAddress,
            },
            owner: {
                key: "businessOwnerPath",
                title: "business_owner_page_title",
                component: BusinessOwner,
                isRequired: () => {
                    return BUSINESS_INFO_OWNER.isFormRequired();
                },
            },
        },
    },
    contactInfo: {
        key: "contactInfoPath",
        path: mainRootRoutes.contactInfo,
        subRoutes: {
            general: {
                key: "contactInfoGeneralPath",
                title: "contact_info_page_title",
                component: ContactInfo,
                isRequired: () => {
                    return CONTACT_INFO_GENERAL.isFormRequired();
                },
            },
            confirmation: {
                key: "contactInfoConfirmationPath",
                title: "contact_confirmation_page_title",
                component: ContactConfirmation,
            },
        },
    },
    occupationAreas: {
        key: "occupationAreasPath",
        path: mainRootRoutes.occupationAreas,
        subRoutes: {
            general: {
                path: "general",
                key: "occupationAreasGeneralPath",
                title: "occupation_areas_page_title",
                component: OccupationAreas,
            },
        },
    },
    productInfo: {
        key: "productInfoPath",
        path: mainRootRoutes.productInfo,
        subRoutes: {
            general: {
                key: "generalProductInfoPath",
                title: "general_product_page_title",
                component: GeneralProductInfo,
            },
            duration: {
                key: "productDurationPath",
                title: "product_duration_page_title",
                component: ProductDuration,
            },
            finance: {
                key: "productFinancePath",
                title: "product_finance_page_title",
                component: ProductFinance,
            },
            platforms: {
                key: "productPlatformsPath",
                title: "product_platforms_page_title",
                component: ProductPlatforms,
            },
        },
    },
    financeInfo: {
        key: "financeInfoPath",
        path: mainRootRoutes.financeInfo,
        subRoutes: {
            bank: {
                key: "bankFinancePath",
                title: "bank_brand_page_title",
                component: BankType,
            },
            "bank-data": {
                key: "bankDataFinancePath",
                title: "bank_data_page_title",
                component: BankData,
            },
        },
    },
    uploadDocuments: {
        key: "uploadDocumentsPath",
        path: mainRootRoutes.uploadDocuments,
        subRoutes: {
            other: {
                key: "otherDocumentsPath",
                title: "other_documents_page_title",
                component: UploadDocuments,
                isRequired: () => UPLOAD_OTHER_DOCUMENTS.uploadDocuments.isRequired(),
            },
            id: {
                key: "idValidationPath",
                title: "id_validation_page_title",
                component: IdValidation,
                isRequired: () => {
                    return BUSINESS_INFO_OWNER.isFormRequired();
                },
            },
        },
    },
    agreementTerm: {
        key: "mainAgreementTermPath",
        path: mainRootRoutes.agreementTerm,
        subRoutes: {
            general: {
                path: "general",
                key: "agreementTermsPath",
                title: "agreement_terms_page_title",
                component: GeneralAgreementTerms,
            },
            congratulations: {
                path: "congratulations",
                key: "agreementCongratulationsPath",
                title: "success_screen_page_title",
                component: CongratulationsScreen,
            },
        },
    },
    mainPath: {
        key: "mainPath",
        path: "/",
        component: Navigate,
        navigateTo: mainRootRoutes.otpValidation,
    },
    pageNotFound: {
        key: "pageNotFoundPath",
        path: "*",
        component: default404Page,
    },
};

export default routesMap;
