import useTranslate from "app/hooks/useTranslate";
import Button from "components/forms/button";
import "./index.scss";

const TermsOfUse = () => {
    const translate = useTranslate();

    const handleButtonClick = () => {
        window.close();
    };

    return (
        <div className="terms-of-use-content-wrapper">
            <ContentText />
            <Button onClick={handleButtonClick}>{translate("terms_of_use_close_button")}</Button>
        </div>
    );
};

// ! THIS IS MOCK FOR NOW
const ContentText = () => {
    return (
        <div className="terms-of-use-content-data">
            <div>
                <span>
                    <strong>תנאים כלליים לשימוש בשירות</strong>
                </span>
            </div>
            <div>&nbsp;</div>
            <div>
                <span>
                    <>
                        1.<span> </span>הגדרות
                    </>
                </span>
            </div>

            <div>
                <span>המונחים שלהלן יפורשו בתנאי שימוש אלה בהתאם להגדרות שלצדם:</span>
            </div>

            <div>
                <span>
                    1.1.<span> </span>״תקנון״ או ״תנאי השימוש״ - כל התנאים המפורטים במסמך זה כנוסחם מעת לעת;
                </span>
            </div>

            <div>
                <span>
                    1.2.<span> </span>״החברה״ - משולם פתרונות תשלום בע״מ, מנהליה וכל מי שפועל מטעמה בקשר עם השירות;&nbsp;
                </span>
            </div>

            <div>
                <span>
                    1.3.<span> </span>"מנפיקת הכרטיס" - חברת כרטיסי האשראי שהנפיקה את כרטיס האשראי של לקוח הקצה;
                </span>
            </div>

            <div>
                <span>
                    1.4.<span> </span>"חברת האשראי הסולקת" – בעל רישיון סולק, כהגדרתו בחוק הבנקאות (רישוי), התשמ"א-1981,&nbsp; שהחברה התקשרה עמו מעת לעת לשם קבלת שירותי
                    סליקה לבתי העסק;
                </span>
            </div>

            <div>
                <span>
                    1.5.<span> </span>״השירות״ - ביצוע תשלומים מאובטחים באינטרנט ו/או בכל יישום או אפליקציה ו/או באמצעות מסוף פיזי, בדרך של מתן שירותי מאגד פיננסי המרכז
                    חיובים וזיכויים של בתי עסק המבוצעים באמצעות כרטיסי חיוב או אמצעי תשלום אחרים;
                </span>
            </div>

            <div>
                <span>
                    1.6.<span> </span>״משתמש״ - כל אדם, לרבות בית העסק, אשר עושה שימוש באתר, לרבות גלישה באתר, או שימוש בשירות, לרבות הרשמה לשירות;&nbsp;
                </span>
            </div>

            <div>
                <span>
                    1.7.<span> </span>״לקוח קצה״ -&nbsp; מי שמחזיק באמצעי התשלום שבאמצעותו נעשה התשלום לבית העסק;&nbsp;&nbsp;
                </span>
            </div>

            <div>
                <span>
                    1.8.<span> </span>״בית העסק״ - מי שנרשם לשירות לצורך ביצוע תשלומי לקוחות, בין אם הוא מאוגד כתאגיד ובין אם לאו, לרבות מי מטעמו אשר עושה שימוש
                    בשירות;&nbsp;
                </span>
            </div>

            <div>
                <span>
                    1.9.<span> </span>״חשבון״ - החשבון המנוהל באתר עבור בית העסק;
                </span>
            </div>

            <div>
                <span>
                    1.10.<span> </span>״האתר״ - אפליקציית משולם פתרונות תשלום למכשירים סלולאריים ו/או אתר האינטרנט בכתובת www.meshulam.biz אוwww.Meshulam.co.il&nbsp; עבור
                    ועדי בתים, או כל דומיין אחר שיופעל על ידי החברה לצורך מתן השירות. האתר כולל גם את כל התכנים המצויים בו מעת לעת, וכן כל תוכנה או קוד מחשב הכלולים באתר
                    או משמשים להפעלתו ולגישה אליו, ולרבות חלקים, רכיבים ותכונות הנגישים או המופעלים באמצעות מכשירים סלולאריים או כל התקן אחר וכן הקוד של כל אפליקציה
                    המופעלת באמצעות מכשיר סלולארי או התקן כאמור;
                </span>
            </div>

            <div>
                <span>
                    1.11.<span> </span>״תשלומי לקוחות״ - כל תשלום לבית העסק אשר בית העסק מעוניין לגבות מלקוחות הקצה באמצעות השירות;
                </span>
            </div>

            <div>
                <span>
                    1.12.<span> </span>״תוכן״ או ״תכנים״ - נתונים, ניתוחים, הערות, פרסומות, ידיעות, טקסט, תמונות וסקירות, בין באופן גרפי ובין באופן קולי, שיועמדו לרשות
                    המשתמש בכל אמצעי שהוא;
                </span>
            </div>

            <div>
                <span>
                    1.13.<span> </span>"יום עסקים" – כל יום מימי השבוע שבו רוב בעלי רישיון בנק לפי חוק הבנקאות (רישוי), התשמ"א-1981, פתוחים לעסקים, למעט ימי שישי וערבי
                    חג; יום העסקים מסתיים בשעה 18:30; כל יום עסקים מתחיל במועד סיומו של יום העסקים הקודם;&nbsp;
                </span>
            </div>

            <div>
                <span>
                    1.14<span> </span>"פרט אימות מוגבר" - האמצעים שהינם ייחודיים ללקוח קצה ומאפשרים לאמת את זהותו ברמת ודאות גבוהה, כפי שייקבעו מעת לעת על ידי החברה על פי
                    הדין. בין היתר ומבלי לגרוע מכלליות האמור לעיל, שימוש באמצעים הבאים ייחשב כשימוש בפרט אימות מוגבר: בעסקה בבית העסק - שימוש בכרטיס חיוב ובו רכיב EMV
                    במסוף סליקה שאושר כתומך EMV תוך הזנת הקוד הסודי של הכרטיס; בעסקאות טלפוניות ובעסקאות באינטרנט- שימוש בשירות 3DSecure ("3DS");&nbsp;
                </span>
            </div>

            <div>
                <span>
                    1.15<span> </span>"חוק שירותי תשלום" או "החוק" - חוק שירותי תשלום, התשע"ט-2019;
                </span>
            </div>

            <div>
                <span>
                    1.16<span> </span>"פעולת תשלום במסמך חסר" – כהגדרתה בסעיף 29 בחוק שירותי תשלום.
                </span>
            </div>
            <div>&nbsp;</div>
            <div>
                <span>
                    <strong>
                        2.<span> </span>כללי
                    </strong>
                </span>
            </div>

            <div>
                <span>
                    2.1<span> </span>השימוש בשירות מוצע בכפוף לקבלה מלאה של כל התנאים, התניות וההצהרות הכלולים בתקנון זה כלשונם. עצם השימוש בשירות ייחשב לקבלה מלאה של
                    המשתמש לכל התנאים, התניות וההצהרות הכלולים במסמך זה. במקרה והמשתמש אינו מסכים עם תנאי השימוש, הוא לא יוכל לעשות שימוש בשירות.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    2.2<span> </span>החברה רשאית לשנות תקנון זה מעת לעת בהתאם לשיקול דעתה הבלעדי. החברה תמסור לבית העסק הודעה על שינוי כאמור לפחות 14 ימים מראש ובכתב. על
                    אף האמור, נדרש השינוי מסיבות הקשורות לניהול סיכונים, צו שיפוטי, דרישה של רשות מוסמכת, דרישה של חברת האשראי&nbsp; הסולקת או עקב שינוי בדין, תהא החברה
                    רשאית ליתן את ההודעה בתקופה קצרה מן האמור לעיל, ככל שהדבר נדרש. תקנון זה יהיה נגיש בגרסתו המעודכנת באתר.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    2.3<span> </span>עצם העובדה שאדם עשה שימוש בשירות גם לאחר שבוצעו שינויים בתקנון מהווה הסכמתו לשינויים. מובהר כי האחריות לבדוק האם בוצעו שינויים בתנאי
                    השימוש מעת לעת הינה של המשתמש בלבד.
                </span>
            </div>

            <div>
                <span>
                    2.4<span> </span>אם משתמש אינו מסכים לתנאי מתנאי הסכם זה, התרופה היחידה העומדת לו הינה להפסיק את השימוש בשירות בהודעה בכתב לחברה.&nbsp;&nbsp;
                </span>
            </div>
            <div>&nbsp;</div>
            <div>
                <span>
                    <strong>
                        3.<span> </span>תנאים מוקדמים לשימוש בשירות&nbsp;
                    </strong>
                </span>
            </div>

            <div>
                <span>
                    3.1<span> </span>השימוש בשירות הינו בכפוף לכך שמתקיימים לגבי בית העסק התנאים המצטברים שלהלן:
                </span>
            </div>

            <div>
                <span>
                    3.1.1<span> </span>לגבי יחיד - מלאו לו 18 שנה והוא כשיר להיקשר בהסכם מחייב;&nbsp;
                </span>
            </div>

            <div>
                <span>
                    3.1.2<span> </span>לגבי תאגיד - הוא רשאי להתקשר בתקנון זה לפי מסמכי ההתאגדות שלו והחלטות האורגנים המוסמכים בו ולבצע את התחייבויותיו על פיו;&nbsp;
                </span>
            </div>

            <div>
                <span>
                    3.1.3<span> </span>ההתקשרות אינה מהווה הפרה של הוראות דין, הסכם או התחייבות כלשהם של בית העסק והוא אינו מנוע על פי כל דין מלהתקשר בתקנון זה כלשונו;
                </span>
            </div>

            <div>
                <span>
                    3.1.4<span> </span>ככל שהוגדרו תנאים נוספים על ידי החברה כאמור בסעיף ‎3.2 שלהלן - עמידה מלאה בתנאים אלו.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    3.2<span> </span>החברה רשאית לקבוע מעת לעת דרישות נוספות לשימוש בשירות, ובכלל זה דרישות הרשמה נוספות, לרבות פרטים נוספים שיהיה על משתמש לספק לשם פתיחת
                    חשבון בשירות, הכול כפי שיפורסם באתר או יימסר למשתמש.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    3.3<span> </span>בעצם השימוש בשירות, לרבות הרשמה אליו כבית העסק, גלישה באתר או ביצוע תשלומי לקוחות, מצהיר המשתמש&nbsp; כי הוא עונה על דרישות סעיף ‎3
                    זה ומסכים לתנאי תקנון זה.
                </span>
            </div>
            <div>&nbsp;</div>
            <div>
                <span>
                    <strong>
                        4.<span> </span>הרשמה לשירות
                    </strong>
                </span>
            </div>

            <div>
                <span>
                    4.1<span> </span>משתמש המבקש לפתוח חשבון בשירות יוכל לעשות כן על ידי מילוי פרטי ההרשמה בדף ההרשמה או באמצעות פניה למוקד החברה והשלמת תהליך הרישום על
                    פי ההנחיות שיימסרו לו על ידי החברה, לרבות מסירת המסמכים ו/או האישורים שיידרשו לשם כך על ידי החברה, והשלמת ביצוע זיהוי לבית העסק ולנציגיו בהתאם
                    לדרישותיה.
                </span>
            </div>

            <div>
                <span>
                    4.2<span> </span>פתיחת חשבון לביצוע תשלומי לקוחות בשירות תיעשה רק לאחר קבלת אישור החברה כי בית העסק רשאי לפתוח חשבון בשירות כאמור (להלן: "אישור פתיחת
                    חשבון").&nbsp;&nbsp;
                </span>
            </div>

            <div>
                <span>
                    4.3<span> </span>לאחר פתיחת החשבון תישלח לבית העסק הודעת SMS למספר הטלפון שנמסר על ידו בעת ההרשמה או הודעה לכתובת הדואר האלקטרוני שנמסרה על ידו בעת
                    ההרשמה, במסגרתה יימסר לו מידע הכניסה. לצורך תחילת השימוש באתר, על בית העסק להזין את מידע הכניסה באתר ו/או לפעול בהתאם להוראות שתתקבלנה מהחברה. למען
                    הסר ספק מובהר כי בית העסק אינו רשאי לגבות תשלומי לקוחות באמצעות השירות טרם השלמת תהליך ההצטרפות וקבלת אישור החברה.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    4.4<span> </span>אישור סליקה ייעודי לאתר מסחר אלקטרוני של בית העסק. מובהר בזאת כי לצורך קבלת שירות סליקה באמצעות אתר מסחר אלקטרוני של בית העסק נדרש
                    בית העסק לקבל אישור סליקה ייעודי מהחברה לאתר המסחר, וזאת בנוסף לאישור פתיחת חשבון. לצורך מתן אישור הסליקה הייעודי רשאית החברה לבדוק את אתר המסחר
                    ולדרוש מבית העסק להתאים אותו לדרישותיה, כפי שיימסרו לו בכתב. מובהר כי אישור הסליקה הינו ייעודי עבור כתובת האתר שאושרה על ידי החברה בלבד, וכי לכל כתובת
                    אתר נוספת יידרש אישור נפרד. למען הסר ספק, אישור פתיחת החשבון איננו מאפשר סליקה באתר המסחר ואין לבצע סליקה באתר המסחר טרם קבלת אישור סליקה ייעודי, נוסף
                    על אישור פתיחת החשבון.
                </span>
            </div>

            <div>
                <span>
                    4.5<span> </span>מודגש ומובהר בזאת כי החברה רשאית, לפי שיקול דעתה הבלעדי, לסרב לאשר פתיחת חשבון לבית העסק ו/או להתנות את פתיחת החשבון בתנאים, לרבות
                    בקשר לשימוש בשירות ו/או מתן ערבויות ו/או בטוחות ו/או אישורים, בכל מקרה בו לדעת החברה קיים יסוד סביר לחשד כי השימוש בשירות על ידי בית העסק עלול לגרום
                    לחשיפה כלכלית ו/או חוקית או אחרת מכל סוג שהוא כלפי החברה, ו/או כאשר לדעת החברה הוא איננו בהתאם למדיניות ניהול הסיכונים של החברה.
                </span>
            </div>

            <div>
                <span>
                    4.6<span> </span>בית העסק מתחייב למסור לחברה במועד ההצטרפות, וכתנאי לקבלת השירות, את כל הפרטים והמסמכים שידרשו על ידה לצורך החלטתה לאשר את השימוש
                    בשירות, וכן כל מידע מהותי הנוגע למצבו הכלכלי או לחשיפה אחרת שעשויה להיות לחברה עקב מתן השירות (להלן: "המידע"). מבלי לגרוע מכלליות האמור לעיל, בית העסק
                    מתחייב לשתף פעולה עם החברה במסירת כל מידע הנדרש לצורך עמידה בדרישות החלות עליה ועל חברת האשראי הסולקת על פי דין, לרבות מכוח צווי איסור הלבנת הון
                    הרלוונטיים או הוראות רגולציה אחרות שחלות עליהן. מובהר ומוסכם כי החברה תהיה רשאית לבצע בדיקות אודות בית העסק ואודות המידע שמסר לה בית העסק כמפורט לעיל,
                    לרבות באמצעות פניה לבעל רישיון לפי חוק נתוני אשראי, התשע"ו-2016, או לכל גורם חוקי אחר. למען הסר ספק אין בקבלת המידע או בביצוע בדיקות כאמור כדי להטיל
                    חבות כלשהי על החברה או לגרוע מזכויותיה לפי הסכם זה.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    4.7<span> </span>&nbsp;למען הסר ספק מובהר כי החברה תהא רשאית לדרוש מבית העסק כל מידע כאמור בסעיף ‏‏4.6 לעיל גם לאחר פתיחת החשבון בשירות, והיא רשאית
                    להתנות את המשך קבלת השירות בקבלתו. עוד מובהר כי החברה תהא רשאית לבצע כל בדיקה לגבי בית העסק ולגבי המידע, בכל מועד שתמצא לנכון, לרבות כפי שתידרש מכוח
                    הוראות הדין והרגולציה החלה עליה. ככל שבדיקה כאמור תעלה מידע אודות בית העסק אשר לדעת החברה יש בו כדי ליצור חשש ליצירת חשיפה לחברה, תהיה החברה רשאית,
                    מבלי לגרוע מיתר זכויותיה לפי תקנון זה, לדרוש מבית העסק מידע נוסף ו/או אישורים ו/או בטוחות כתנאי להמשך מתן השירות, וכן תהא רשאית לפעול בהתאם להוראות
                    סעיף ‏‏20 להלן. מוסכם בזאת כי החברה תהא רשאית לעכב את העברת סכומי הגבייה החודשיים לבית העסק שלא ישתף עמה פעולה במסירת מידע ו/או אישורים ו/או בטוחות
                    כאמור, עד אשר יקיים את שנדרש ממנו, ואם לא קיים את הנדרש בתוך פרק הזמן שקבעה החברה, לסגור את חשבון בית העסק בהתאם להוראות סעיף ‏20 להלן.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    4.8<span> </span>מבלי לגרוע מן האמור לעיל, מובהר כי פתיחת חשבון בשירות ו/או תחילת השימוש בשירות על ידי בית העסק מותנית באישור חברת האשראי הסולקת. בית
                    העסק מוותר בזאת על כל טענה או דרישה במקרה בו לא ייפתח לו חשבון בשירות עקב סירוב חברת האשראי הסולקת, או שיידרש למסור מידע נוסף ו/או אישורים נוספים ו/או
                    למלא אחר דרישות אחרות של חברת האשראי הסולקת כתנאי לפתיחת החשבון בשירות או כתנאי להמשך השימוש בשירות. למען הסר ספק, כל דרישה מצד חברת האשראי הסולקת,
                    לרבות דרישה לסגירת החשבון או דרישה אחרת, יכולה לעלות גם לאחר פתיחת החשבון, ולא יהיה לבית העסק טענה בעניין.
                </span>
            </div>

            <div>
                <span>
                    4.9<span> </span>על אף האמור בסעיף ‎4.8 לעיל, החברה תהיה רשאית, לפי שיקול דעתה הבלעדי, לאשר לבית העסק לעשות שימוש בשירות בטרם התקבל אישור חברת האשראי
                    הסולקת לכך, בכפוף למגבלות שלהלן:
                </span>
            </div>

            <div>
                <span>
                    4.9.1<span> </span>תשלומי הלקוחות שיגבו על ידי בעל העסק במסגרת השירות יועברו לחשבון בית העסק רק לאחר קבלת אישור חברת האשראי הסולקת.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    4.9.2<span> </span>סך תשלומי הלקוחות שייגבו טרם קבלת אישור חברת האשראי הסולקת לא יעלה על 10,000 ש"ח או על סכום אחר שתקבע החברה.
                </span>
            </div>

            <div>
                <span>
                    4.10<span> </span>מבלי לגרוע מהוראות תקנון זה, מובהר כי לצורך קבלת השירות וכתנאי מקדים לצורך קבלת תשלומי לקוחות, יהיה על בית העסק להעביר לחברה העתק
                    צילום המחאה של בית העסק הכולל את פרטי חשבון הבנק שלו (שיהיו זהים לפרטי החשבון שעודכנו על ידי בית העסק), או אישור קיום חשבון מהבנק בו מתנהל החשבון
                    הכולל את אותם פרטים כאמור.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    4.11<span> </span>מובהר כי בית עסק שהיקף הסליקה השנתי שלו עלה על הסכום שנקבע מעת לעת על ידי חברת האשראי הסולקת [נכון למועד הוצאת מהדורה זו: 50,000
                    ש"ח], יורשה לעשות שימוש בשירות בכפוף לחתימתו על הסכם משולש עם החברה ועם חברת האשראי הסולקת (להלן: "ההסכם המשולש") ולהשלמת הליך זיהוי, אימות והכרת לקוח
                    (להלן: "הליך האימות") כפי שיידרש על ידי חברת האשראי הסולקת ולפי דין, לרבות פגישה פרונטלית או באמצעי זיהוי מרחוק, מילוי שאלונים, חתימה על הצהרות
                    ומסמכים, מסירת מסמכים ותעודות מזהות ומסירת כל מידע נדרש כאמור. החברה רשאית לסרב לפתוח חשבון לבית עסק שלא ישתף פעולה עם דרישות חברת האשראי הסולקת ועם
                    דרישותיה של החברה בקשר להסכם המשולש ולהליך האימות, וכן רשאית היא לעכב את העברת סכומי הגבייה החודשיים לבית העסק עד אשר יקיים את שנדרש ממנו, ואם לא קיים
                    את הנדרש בתוך פרק זמן שתקבע החברה, לסגור את חשבון בית העסק בהתאם להוראות סעיף ‏20 שלהלן.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    4.12<span> </span>החברה אינה מתחייבת כי אישור ההצטרפות לשירות ואישור חברת האשראי הסולקת יינתנו בתוך פרק זמן מסוים. ידוע לבית העסק כי מאחר שתהליך מתן
                    האישורים כרוך בגורמים חיצוניים&nbsp; לחברה, אישור בית העסק לתחילת השימוש בשירות עלול להתעכב.
                </span>
            </div>

            <div>
                <span>
                    4.13<span> </span>כל משתמש רשאי לפתוח חשבון אחד בלבד. החברה שומרת לעצמה את הזכות שלא לבצע פעולות של גולשים ולחסום ניסיונות לעקוף מדיניות זו, כגון
                    באמצעות שימוש במכונות (רובוטים) או תוכנות עזר, או בכל מקרה בו קיים יסוד סביר לחשש כי חשבון נפתח על ידי אותו משתמש.
                </span>
            </div>

            <div>
                <span>
                    4.14<span> </span>ביקש בית עסק לפתוח יותר מחשבון אחד, לא יעשה זאת אלא באישורה המפורש של החברה מראש ובכתב, לרבות בקשר לתמורה עבור פתיחת חשבון נוסף
                    כאמור. החברה רשאית לסרב, לפי שיקול דעתה המוחלט, לפתוח לבית העסק חשבון נוסף. נפתח לבית העסק חשבון נוסף מבלי שהדבר אושר על ידי החברה, יחסם החשבון הנוסף.
                    בנוסף, תהא החברה רשאית שלא לבצע תשלומי לקוחות של בית העסק בכל מקרה בו קיים יסוד סביר לחשד כי חשבון בשירות נפתח על ידי בית עסק שיש לו כבר חשבון בשירות.
                </span>
            </div>

            <div>
                <span>
                    4.15<span> </span>בכל מקרה שבו החברה סבורה שקיים יסוד סביר לחשש כי נפתח על ידי בית העסק חשבון שלא בהתאם לתנאי תקנון זה, החברה תהיה רשאית, לפי שיקול
                    דעתה, לחסום, להגביל או לסגור את החשבון, ויחולו לעניין זה הוראות סעיף 20 שלהלן.
                </span>
            </div>

            <div>
                <span>
                    4.16<span> </span>השימוש בשירות ובחשבון הוא אישי ואינו ניתן להעברה. בית העסק לא יאפשר לצד שלישי כלשהו גישה לפרטי החשבון או שימוש בשירות תחת שמו
                    ופרטיו.&nbsp;&nbsp;
                </span>
            </div>

            <div>
                <span>
                    4.17<span> </span>החברה רשאית, אך לא חייבת, לשלוח לבית העסק פרסומים, מבצעים, הנחות, עדכונים, חידושים וכדומה (להלן: "דיוור שיווקי") מעת לעת, בין היתר
                    באמצעות הודעות דואר אלקטרוני או SMS, בכפוף לקבלת הסכמת בית העסק בהתאם לאמור בסעיף 30א לחוק התקשורת (בזק ושידורים), התשמ"ב–1982 (להלן: "חוק התקשורת").
                    על אף האמור, בהתאם להוראת סעיף 30א(ג) לחוק התקשורת, תהא החברה רשאית לשלוח לבית העסק דיוור שיווקי בעניין מוצר ו/או שירות שרכש בית העסק מהחברה ו/או
                    בעניין מוצרים ו/או שירותים מסוג דומה להם, אף ללא הסכמתו. בית העסק יכול לבחור בכל עת להפסיק לקבל דיוור שיווקי מהחברה וזאת על ידי פנייה בכתב לחברה, או
                    דרך פנייה לחברה באופן בו שוגר הדיוור השיווקי, לפי בחירת בית העסק.
                </span>
            </div>
            <div>&nbsp;</div>
            <div>
                <span>
                    <strong>
                        5.<span> </span>פרטי בית העסק וחשבון בית העסק
                    </strong>
                </span>
            </div>

            <div>
                <span>
                    5.1<span> </span>בית העסק לא ישתף את פרטי המידע המשמשים לגישה לחשבון, ובכלל זה את שם המשתמש של בית העסק ו/או סיסמתו האישית (לעיל ולהלן: ״מידע
                    הכניסה״). בנוסף, לא יאפשר בית העסק גישה לחשבון למי שאינו מורשה לכך לפי תקנון זה וכן ימנע מביצוע כל פעולה אשר עלולה לסכן את אבטחת החשבון.
                </span>
            </div>

            <div>
                <span>
                    5.2<span> </span>נודע לבית העסק על סיכון לאבטחת החשבון או שיש לו חשש סביר לכך, לרבות כל אובדן, גניבה או חשיפה בלתי מורשית של מידע הכניסה או גישה בלתי
                    מורשית לחשבון, וכן כל אובדן או גניבה של החומרה, לרבות המכשיר הסלולרי או המחשב, המשמשת את בית העסק לצורך קבלת השירות, או שהיה לו כל חשד אחר לארוע אבטחה
                    בקשר עם השירות, יודיע על כך בית העסק באופן מידי לחברה ויפעל לשינוי מידע הכניסה ויישום הנחיות אבטחת מידע נחוצות אחרות ככל שיועברו לו על ידי החברה. בית
                    העסק ישתף פעולה עם החברה בחקירת הסיכון ו/או האירוע ובנקיטת צעדים למיגורו, תחקורו והטמעת אמצעי אבטחה על מנת למנוע הישנותו בעתיד. במקרה שמקור הסיכון
                    ו/או האירוע הוא במעשה או מחדל של בית העסק, יישא בית העסק בהוצאות הכרוכות בביצוע סעיף זה ובכל נזק שיגרם בשל מעשה או מחדל כאמור.
                </span>
            </div>

            <div>
                <span>
                    5.3<span> </span>על בית העסק לסגור את החשבון בתום כל יום עבודה, על-ידי התנתקות מהמערכת וסגירת הדפדפן (במקרה של כניסה לשירות ממחשב).
                </span>
            </div>

            <div>
                <span>
                    5.4<span> </span>בית העסק יהיה אחראי באופן מלא ובלעדי לשמירה על סודיות מידע הכניסה ולכל פעולה שתעשה באמצעות החשבון ו/או באמצעות מידע הכניסה, בין אם
                    הפעולות בוצעו או אושרו על ידו ובין אם לאו. מובהר כי בכל מקרה שבו החברה סבורה שקיים חשש ממשי כי נעשתה בחשבון פעילות המפרה את תנאי השימוש או פעילות
                    שאינה חוקית, החברה תהיה רשאית, לפי שיקול דעתה, לחסום, להגביל או לסגור את החשבון, ויחולו לעניין זה הוראות סעיף ‎20 שלהלן.
                </span>
            </div>

            <div>
                <span>
                    5.5<span> </span>מבלי לגרוע מההוראות המפורטות לעיל, בית העסק מתחייב בזאת להגביל את השימוש בחשבון על-ידי קטינים ולמנוע גישה אליו למי שהינו מתחת לגיל
                    16. בית העסק מקבל על עצמו אחריות מלאה בגין שימוש בלתי מורשה בשירות על-ידי קטינים כאמור.
                </span>
            </div>

            <div>
                <span>
                    5.6<span> </span>השימוש בשירות מותר רק לנציגי בית העסק להם אישרה החברה, מראש ובכתב, לעשות שימוש בשירות, ובית העסק מתחייב כי לא יאפשר שימוש בשירות אלא
                    לנציגיו שאושרו כאמור. בעת חילופי הרכב הנציגים המורשים מטעם בית העסק, על בית העסק לעדכן את החברה בכתב בפרטי הנציגים המורשים החדשים. הודעת העדכון תשלח
                    לחברה מאת כתובת הדוא"ל הרשומה בפרטי הקשר של בית העסק, ובמקרה ואין הדבר אפשרי- רשאית החברה לדרוש מבית העסק אישורים מתאימים לצורך הוכחת תקפות ההודעה.
                    למען הסר ספק, כל עוד לא ניתנה הודעה כאמור על ידי בית העסק תפעל החברה בהתאם לפרטי הנציגים שאושרו על ידה. מובהר כי בית העסק יהיה מחויב כלפי החברה בגין
                    כל הפעולות שייעשו בחשבון, ולא תחול על החברה כל אחריות בגין האמור.
                </span>
            </div>

            <div>
                <span>
                    5.7<span> </span>עדכונים ו/או הודעות בקשר לשירות יישלחו לבית העסק בהתאם לפרטי ההתקשרות שנמסרו על ידו בעת ההרשמה, ובכלל זה לכתובת הדוא״ל שנמסרה על ידו.
                    בית העסק אחראי לוודא כי פרטי ההתקשרות עמו, הרשומים באתר לגבי החשבון, הם מדויקים ועדכניים. בית העסק יודיע לחברה לאלתר ובכתב על כל עדכון או שינוי בפרטי
                    ההתקשרות האמורים, ויוודא כי פרטים אלה תוקנו. היה ולא עשה כן, לא תישמע מבית העסק כל טענה בדבר אי שליחת הודעה או עדכון מאת החברה.
                </span>
            </div>

            <div>
                <span>
                    5.8<span> </span>שינוי חשבון בית העסק ביוזמת העסק- ביקש בית העסק לשנות את חשבון הבנק לזיכוי, ישלח בקשה בכתב בנושא לכתובת הדואר האלקטרוני
                    support@meshulam.co.il וימסור לחברה את פרטיו של החשבון החדש לזיכוי ומסמך הוכחת חשבון (העתק שיק או אישור ניהול חשבון על שם בית העסק). שינוי חשבון בית
                    העסק יהיה כפוף להתמלאותם, במלואם, של התנאים המפורטים להלן:
                </span>
            </div>

            <div>
                <span>
                    5.8.1<span> </span>החשבון החדש לזיכוי יהיה חשבון בבנק בישראל, לזיכוי בשקלים ובמט"ח;
                </span>
            </div>

            <div>
                <span>
                    5.8.2<span> </span>בית העסק לא המחה את זכויותיו על פי הסכם זה ולא שיעבד אותן ולא נתן לגביהן הוראה אחרת, ללא אישור החברה בכתב ומראש. במקרה של מניעה
                    כאמור יבוצע השינוי רק אם בית העסק ימציא לחברה הסכמה מקורית בכתב של המוטב או הנמחה לביצוע השינוי כאמור;
                </span>
            </div>

            <div>
                <span>
                    5.8.3<span> </span>לא הוטלו על תשלומי הלקוחות המגיעים לבית העסק עיקול ו/או לא ניתן צו מניעה לתשלומם או צו שיפוטי אחר המונע או מעכב את התשלומים ואין
                    לצד שלישי כל זכות אחרת בהם. במקרה של מניעה כאמור, יבוצע השינוי רק אם בית העסק ימציא לחברה עותק מקור של צו ו/או החלטה של רשות שיפוטית על הסרת העיקול או
                    המניעה האחרת.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    5.8.4<span> </span>אין בבקשה לשינוי החשבון לזיכוי משום הפרה של הוראה שבדין ו/או של הוראה רגולטורית ו/או של הוראה ו/או צו ו/או החלטה ו/או פסק דין של
                    גוף שיפוטי או מעין שיפוטי.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    5.8.5<span> </span>החברה תהא רשאית להתנות את אישורה בקבלת מסמכים מסוימים, לרבות אישור הבנק בו מתנהל החשבון הישן ו/או הבנק בו מתנהל החשבון החדש ו/או
                    הצהרת בית העסק בכתב על עמידה בהוראות הדין ובהוראות הסכם זה.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    5.9<span> </span>החברה תהא רשאית להתנגד לשינוי החשבון לזיכוי (גם אם התקיימו מלוא התנאים המפורטים בסעיף ‏5.8 לעיל), אם לדעת החברה מצבה עשוי להשתנות
                    לרעה כתוצאה מן השינוי. הודעה על סירוב לשנות את החשבון לזיכוי תינתן על ידי החברה בתוך 14 ימי עסקים מיום קבלת הבקשה לשינוי.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    5.10<span> </span>מועד עדכון שינויים בפרטי חשבון בית העסק: ביקש בית העסק לשנות את החשבון לזיכוי והחברה נעתרה לבקשתו, ייעשה השינוי תוך 10 ימי עסקים
                    מיום קבלת הבקשה לשינוי, ובלבד שמולאו כל התנאים המפורטים בסעיף ‏5.8 לעיל. שונה החשבון לזיכוי כאמור, החברה תזכה את החשבון החדש לזיכוי עליו הודיע בית
                    העסק, החל ממועד השינוי, בכל תשלומי לקוחות שטרם הועברו לחשבון הקודם, נכון למועד עדכונו של החשבון החדש במערכות החברה. בכל מקרה שנודע לחברה על שינוי
                    בחשבון בית העסק, לרבות בבעלות ובזכויות בו, אשר לא נמסרה עליו הודעה לחברה, תהיה רשאית החברה לעכב את התשלום לבית העסק עד להגשת בקשת מתאימה על ידי בית
                    העסק ובדיקתה על ידי החברה.
                </span>
            </div>
            <div>&nbsp;</div>
            <div>
                <span>
                    <strong>
                        6.<span> </span>מדיניות פרטיות&nbsp;
                    </strong>
                </span>
            </div>

            <div>
                <span>
                    6.1<span> </span>החברה מתייחסת בכבוד לפרטיות לקוחותיה ומחויבת לשמור ולהגן על המידע האישי שהיא מחזיקה אודות המשתמשים. מדיניות הפרטיות להלן מתארת את
                    האופן בו החברה אוספת מידע, סוג המידע הנאסף, אופן השימוש במידע וכן הדרך בה המשתמש, כנשוא המידע, יכול להסירו או לשנותו.
                </span>
            </div>

            <div>
                <span>
                    6.2<span> </span>במדיניות זו, הביטוי "מידע אישי" משמעו כל מידע הנאסף על ידי החברה או נמסר לחברה על ידי המשתמש, שבאמצעותו ניתן באופן סביר לזהות אדם
                    ו/או מידע המוגן על פי חוק הגנת הפרטיות, התשמ"א-1981 (להלן: "חוק הגנת הפרטיות"), לרבות, אך לא רק, שם פרטי, שם משפחה, מס' ת.ז., כתובת, מספר טלפון,
                    דוא"ל, תאריך לידה, תשלומים, פרטי עסקאות, מידע על אמצעי תשלום וחשבון בנק.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    6.3<span> </span>במסגרת ההתקשרות של המשתמש מול החברה, המשתמש מסכים לכך שהחברה תאסוף, תשמור ותשתמש במידע האישי שלו באופן המתואר במדיניות זו. המשתמש
                    אינו חייב למסור את המידע האישי כאמור ומסירת המידע נעשית מרצונו החופשי ובהסכמתו, אולם, ללא מסירת המידע האישי, החברה לא תוכל להציע לו את השירותים. ככל
                    ומידע אישי כולל מידע מזהה אודות אנשים אחרים, המשתמש יקבל את כל ההסכמות הנדרשות על פי דין על מנת להעביר מידע אישי כאמור לחברה.
                </span>
            </div>

            <div>
                <span>
                    6.4<span> </span>המידע האישי שנאסף יישמר במאגר המידע של החברה, המתנהל על פי דין (להלן: "המאגר") ובאחריותה. השימוש בשירותי החברה, מעידים על הסכמת
                    המשתמש למדיניות פרטיות זו וכי פרטי המשתמש או כל מידע אישי אחר יישמרו וינוהלו במאגר כאמור. השימוש במידע שבמאגר יעשה בהתאם להוראות מדיניות פרטיות זו או
                    על פי הוראות כל דין – למטרות המפורטות להלן.
                </span>
            </div>

            <div>
                <span>
                    6.5<span> </span>המידע האישי יישמר במאגר כאמור, בין היתר, למטרות הבאות: (א) כדי לספק למשתמש את השירותים שרכש מהחברה; (ב) ליצירת קשר כאשר החברה סבורה
                    שקיים צורך בכך בקשר עם השירותים שרכש המשתמש מהחברה; (ג) לספק תמיכה ולטפל בבקשות או בתלונות; (ד) למטרות שיווק, לרבות, אך לא רק, לספק למשתמש מידע אודות
                    המוצרים והשירותים המוצעים על ידי החברה או שותפיה העסקיים, בכפוף להוראות כל דין חל; (ה) למטרות טיוב נתונים ומחקר; (ו) למטרת אימות זהות משתמש (לרבות
                    במסגרת תהליך האימות); (ז) על מנת למלא אחר דרישות כל חוק, תקנה או דבר חקיקה אחר וכן על מנת לסייע לרשויות מוסמכות ולכל צד שלישי, כשהחברה סבורה בתום לב
                    כי עליה לעשות כן; (ח) בכל מקרה של מחלוקת או הליך משפטי מכל סוג בין המשתמש לבין החברה, או בין המשתמש לבין לקוחות קצה או צדדים שלישיים ביחס לחברה או
                    לשירותים שהיא מספקת; ו-(ט) אם החברה מארגנת מחדש את פעילות ו/או מבנה החברה במסגרת שונה, או תחת ישות משפטית אחרת, או אם היא נרכשת או מתמזגת עם ישות אחרת
                    ובתנאי שישויות אלה מסכימות להיות מחויבות להוראות מדיניות אלה, בשינויים המתאימים.
                </span>
            </div>

            <div>
                <span>
                    6.6<span> </span>החברה תשתמש במידע האישי שנאסף כמפורט לעיל ותחלוק אותו עם עובדים, שותפים, קבלנים, ספקי שירותים, יועצים וחברות מסונפות אחרות למטרות
                    המפורטות בסעיף ‏6.5 לעיל. החברה עשויה לאחסן מידע בשירותי ענן ששרתיו ממוקמים מחוץ לישראל, לרבות במדינות בהן כללי אבטחת המידע והגנת הפרטיות אינם זהים
                    לאלה הנהוגים בישראל, ובמידת הצורך, תנקוט בצעדים כדי להבטיח רמה דומה של הגנה למידע האישי של המשתמש.
                </span>
            </div>

            <div>
                <span>
                    6.7<span> </span>על פי חוק הגנת הפרטיות, כל אדם זכאי לעיין במידע שעליו המוחזק במאגר מידע. אדם שעיין במידע שעליו ומצא כי אינו נכון, שלם, ברור או
                    מעודכן, רשאי לפנות לבעל מאגר המידע בבקשה לתקן את המידע או למחקו. פנייה כזאת יש להפנות לחברה, לכתובת הדואר האלקטרוני support@meshulam.co.il. בנוסף, אם
                    המידע שבמאגר משמש לצורך פניה אישית אל אדם, זה זכאי על פי חוק הגנת הפרטיות לדרוש בכתב שהמידע המתייחס אליו יימחק ממאגר המידע.
                </span>
            </div>
            <div>&nbsp;</div>
            <div>
                <span>
                    <strong>
                        7.<span> </span>השימוש בשירות
                    </strong>
                </span>
            </div>

            <div>
                <span>
                    7.1<span> </span>השירות ו/או האתר, לרבות כל התכנים והשירותים הניתנים במסגרתו, מסופקים כפי שהם (AS IS) ובהתאם לשיקול דעתה הבלעדי של החברה, והנהלת האתר
                    ו/או החברה לא יישאו בכל אחריות להתאמתם לצרכי המשתמשים ו/או למטרותיהם. השימוש בשירות נעשה על אחריותו הבלעדית של המשתמש, ולמשתמש לא תהיה כל תלונה, דרישה
                    או תביעה כנגד החברה בקשר להתאמת השירות, לרבות בקשר לתכנים באתר ולאפשרויות השימוש בהם, לרבות בדבר התאמתם למטרה כזו או אחרת.
                </span>
            </div>

            <div>
                <span>
                    7.2<span> </span>ידוע לבית העסק כי החברה אינה אחראית לפעילות בית העסק או מי מטעמו, והיא אינה אחראית ולא תהיה אחראית, מכל סיבה שהיא, ליחסים בין בית
                    העסק לבין לקוחות הקצה, לרבות לעסקה שתיקשר בין בית העסק ללקוח הקצה בעקבות השימוש בשירות.
                </span>
            </div>

            <div>
                <span>
                    7.3<span> </span>בית העסק יימנע מלהציג כלפי לקוחות הקצה כל מצג, מפורש או משתמע, ולפיו החברה או השירות קשורים לפעילות בית העסק.
                </span>
            </div>

            <div>
                <span>
                    7.4<span> </span>החברה רשאית לסגור את השירות או להפסיק את פעילותו, כולה או חלקה, ויחולו לעניין זה הוראות סעיף ‏23.3 שלהלן. בנוסף, החברה רשאית לשנות,
                    מעת לעת, את מבנהו, מראהו וזמינותו של השירות ו/או התכנים הכלולים בו, וכל זאת ללא צורך בהודעה מראש. המשתמש מוותר בזאת על כל דרישה, תביעה או תלונה בקשר
                    עם האמור.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    7.5<span> </span>השירות מיועד לשימוש ככלי עזר לביצוע תשלומי לקוחות בלבד, ולא יעשה בו כל שימוש אחר.&nbsp;&nbsp;
                </span>
            </div>

            <div>
                <span>
                    7.6<span> </span>בית העסק אינו רשאי להעתיק, להפיץ, לשדר, להציג, לבצע, לשכפל, לפרסם, לנצל, להנפיק רישיון, לייצר עבודות נגזרות, לעשות כל תיקון, למכור או
                    למכור בשנית מידע, תוכנה, מוצרים, שירותים או כל דבר אחר שמקורו בשירות, לכל מטרה שהיא.
                </span>
            </div>

            <div>
                <span>
                    7.7<span> </span>כמו כן לא יבצע בית העסק איסוף ושימוש במידע, תיאורים או מחירים כלשהם; שימוש הנגזר מהאתר או תוכנו; הורדה או העתקה של מידע לטובת גורמים
                    אחרים; וכל שימוש באמצעות כריית נתונים, רובוטים או כלים דומים המשמשים לאיסוף וחילוץ נתונים.
                </span>
            </div>

            <div>
                <span>
                    7.8<span> </span>השירותים המוצעים במסגרת השירות מוצעים רק במקום ובאופן בו הם מותרים לשימוש לפי דין. כל שימוש בשירותים במקום בו הינם אסורים, יהיה בטל
                    ומבוטל.
                </span>
            </div>

            <div>
                <span>
                    7.9<span> </span>למען הסר ספק מובהר כי השירות איננו שירות בנקאי מכל סוג שהוא.
                </span>
            </div>

            <div>
                <span>
                    7.10<span> </span>ככל שהדבר יהיה דרוש על פי דין, לרבות בהתאם להוראות חוק הפיקוח על שירותים פיננסיים (שירותים פיננסיים מוסדרים), התשע"ו-2016, חוק איסור
                    הלבנת הון, התש"ס-2000, וחוק איסור מימון טרור, התשס"ה-2005, וכן התקנות, החוזרים, ההוראות והצווים שהותקנו מכוחם, החברה עשויה לדווח על פעולות שביצעו
                    משתמשים וזאת מבלי ליידע אותם על כך.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    7.11<span> </span>בית העסק מתחייב בזאת למלא אחר כל ההוראות לגבי אופן השימוש בשירות כפי שיעודכנו מעת לעת, לרבות לעניין הנתונים שנדרש להזין באתר, ביצוע
                    התשלומים, אופן זיהוי הלקוחות, דרישת קבלת חתימה או אישור אחר מאת לקוח הקצה, שמירת מסמכים, אופן הפעלת מסופי סליקה פיזיים וכיוצא באלה.
                </span>
            </div>
            <div>&nbsp;</div>
            <div>
                <span>
                    <strong>
                        8.<span> </span>שירותים נוספים
                    </strong>
                </span>
            </div>

            <div>
                <span>
                    החברה תהא רשאית להציע שירותים נוספים, לרבות שירותים שאינם שירותי תשלום כהגדרתם בחוק שירותי תשלום, ובכלל זה שירות הפקת חשבונות, שירות הקדמת תשלומים,
                    פתרונות סליקה נוספים (לרבות פתרונות סליקה לבית העסק במסגרתם החברה איננה סולקת כמאגד) ושירותים טכנולוגיים ופיננסיים נוספים. על שירותים נוספים אלה יחולו
                    כל הוראות התקנון החלות ביחס לשירות, בשינויים המחויבים, וכן ההוראות הספציפיות שיוסכמו ביחס לשירותים כאמור, למעט אם נקבע אחרת על ידי החברה.&nbsp;&nbsp;
                </span>
            </div>
            <div>&nbsp;</div>
            <div>
                <span>
                    <strong>
                        9.<span> </span>זכויות יוצרים&nbsp;
                    </strong>
                </span>
            </div>

            <div>
                <span>
                    9.1<span> </span>כל התכנים המופיעים בשירות כפופים לדיני זכויות יוצרים, סימנים מסחריים, פטנטים ו/או כל דין אחר הקשור לזכויות קניין רוחני (להלן: ״זכויות
                    הקניין הרוחני״). בהתאם לדיני זכויות הקניין הרוחני החלים בישראל, ובהתאם להוראות אמנות בינלאומיות, זכויות הקניין הרוחני בכל הפרסומים, התכנים וסימני
                    המסחר (בין אם רשומים ובין אם לאו) המופיעים בשירות, שייכים לחברה. זכויות קניין רוחני אלו כוללות, בין השאר, טקסטים, תמונות, איורים, סימני מסחר (בין אם
                    רשומים ובין אם לאו), מפות, קטעי אודיו, קטעי וידיאו, גרפיקה, תוכנה ויישומים (להלן: ״החומר המוגן״).
                </span>
            </div>

            <div>
                <span>
                    9.2<span> </span>כל זכויות הקניין הרוחני בחומר המוגן הן בבעלות בלעדית של החברה או יש ברשותה הזכויות המלאות לשימוש בו וחל איסור מוחלט על העתקה, חלוקה
                    מחדש, שידור מחדש, להציג בפומבי, ועל פרסום של כל חומר ו/או תוכנה מהשירות ו/או כל חלקים מהם, וכן כל שימוש בלתי מורשה אחר אשר פוגע בזכויות הקניין הרוחני
                    של החברה לפי כל דין רלוונטי, ללא הודעה מוקדמת וללא אישור בכתב מהחברה.
                </span>
            </div>

            <div>
                <span>
                    9.3<span> </span>השירות, לרבות כל סימני המסחר (בין אם נרשמו או לא) או סימנים מסחריים מכל סוג שהוא, הם קניינה הבלעדי של החברה, והיא לבדה תהיה היחידה
                    הרשאית לעשות שימוש כלשהוא בהם, לרבות בשם המתחם של כתובת השירות או בכל סימן אחר או מותג אחר.
                </span>
            </div>

            <div>
                <span>
                    9.4<span> </span>על אף האמור, זכויות הקניין הרוחני בחומרים שיסופקו על ידי משתמש עבור פרסום בשירות הם בבעלותו ובאחריותו; המשתמש מאשר במתן תוכן לפרסום
                    בשירות, כי הוא הבעלים המלא של זכויות הקניין הרוחני בו והוא נותן את הסכמתו לשימוש בחומר על ידי החברה. כמו כן, המשתמש מצהיר כי תוכן זה איננו מפר זכויות
                    קניין רוחני או כל זכות אחרת של צד שלישי כלשהו. מובהר בזאת, כי בעת מתן החומר לפרסום בשירות על ידי משתמש, הוא מעניק לחברה, ללא תמורה כלשהי, רישיון בלתי
                    חוזר, כלל עולמי וללא הגבלה, לעשות כל שימוש כלשהו בחומר על ידי החברה, כולל שכפול, העתקה, הפצה או כל שימוש אחר לפי שיקול דעתה בכל מדיה ותוכן כלשהו.
                </span>
            </div>
            <div>&nbsp;</div>
            <div>
                <span>
                    <strong>
                        10<span> </span>פרסומות מסחריות בשירות&nbsp;
                    </strong>
                </span>
            </div>

            <div>
                <span>
                    10.1<span> </span>רק החברה וכל מי שקיבל היתר בכתב ממנה זכאים לפרסם פרסומות באתר ומידע מסחרי מטעמה או מטעם צד ג׳ באמצעות קישורים לשירותים אחרים שאינם
                    מופעלים על ידי החברה, אלא על ידי אחרים (להלן: ״שירותים אחרים״), או בכל שיטה שהם רואים לנכון ובכל מקום באתר בו תחפוץ לעשות כן (להלן: ״פרסומות״). כל
                    הזכויות בקשר לפרסומות, לרבות כל תמורה שתתקבל בגינן, תיזקף לזכות החברה בלבד.
                </span>
            </div>

            <div>
                <span>
                    10.2<span> </span>חל איסור מוחלט על מחיקה, פגיעה, שיבוש או הפרעה לכל פרסומות בדרך כלשהי. החברה תהיה רשאית, בכל עת, ללא הודעה מוקדמת, למחוק כל פרסומת
                    או מידע מסחרי אחר שפורסמו בניגוד לתנאי השימוש.
                </span>
            </div>

            <div>
                <span>
                    10.3<span> </span>תכנים אשר נמסרו למטרות פרסום בשירות יהיו חשופים לכלל המשתמשים בשירות. החברה אינה יכולה לחזות מה התגובות שיתקבלו בעקבות חשיפת התוכן.
                    החברה לא תישא באחריות כלפי משתמש או כל צד שלישי בגין התכנים והתגובות שיתקבלו אליהם.
                </span>
            </div>

            <div>
                <span>
                    10.4<span> </span>התניות שלהלן יחולו על השימוש בפרסומות, וזאת מבלי לגרוע משאר תנאי השימוש:
                </span>
            </div>

            <div>
                <span>
                    10.4.1<span> </span>באשר לקישורים (links) לשירותים אחרים: אלא אם צוין אחרת בשירות, אין כל יחסים משפטיים או מסחריים בין החברה לבעלי השירותים האחרים,
                    ולחברה אין כל שליטה או זכות לגבי החומר הנמצא בשירותים אלה.
                </span>
            </div>

            <div>
                <span>
                    10.4.2<span> </span>החברה לא תהיה אחראית לחומר הנמצא בשירותים אחרים - לרבות תוכן, נתונים, אלמנטים חזותיים או קישורים המופיעים בהם והשימוש בשירותים
                    האחרים כפוף לתנאי השימוש ו/או מדיניות הפרטיות החלים עליהם ומוצע לעיין בהם בטרם שימוש בשירותים כאמור.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    10.4.3<span> </span>אין בקישורים לשירותים אחרים כדי להתפרש כמתן אישור, המלצה או העדפה על ידי החברה לשירותים אלו, למפעילי השירות, ו/או למוצרים ו/או
                    שירותים המוצגים בהם.
                </span>
            </div>

            <div>
                <span>
                    10.4.4<span> </span>כאשר נכלל קישור בשירות זה, נעשה מאמץ להבטיח כי הקישור עצמו הינו הקישור המתאים. עם זאת, יתכן כי לאורך זמן, חלו שינויים בשירות
                    המקושר. אם המשתמש סבור כי הקישור אינו מתאים, הוא מתבקש לפנות לתמיכה.
                </span>
            </div>

            <div>
                <span>
                    10.4.5<span> </span>במקרים בהם לחברה ולשירות אחר יש יחסים משפטיים או מסחריים, זכויות היוצרים והקניין הרוחני של התוכן בשירותים אלו, הן בבעלות צד שלישי
                    שהעניק לחברה את הסכמתו לעשות שימוש בתוכן. המשתמש אינו רשאי להעתיק, להפיץ מחדש, להציג בפומבי, לפרסם את החומר, ו/או תוכנה מהשירות, ללא אישור מראש ובכתב
                    מאת בעלי הזכויות בתוכן.
                </span>
            </div>

            <div>
                <span>
                    10.4.6<span> </span>במקרה של סתירה בין תנאי שימוש אלו זה לבין תנאי השימוש בשירותים אחרים, תנאי השימוש האלו יגברו.
                </span>
            </div>

            <div>
                <span>
                    10.4.7<span> </span>החברה לא תישא באחריות, בשום אופן, עבור נתונים ותוכן, כולל אלמנטים ויזואליים, אליהם מפנים קישורים ולא תהיה אחראית לתוצאה כלשהי
                    הנובעת מהשימוש בהם או מהסתמכות עליהם, לרבות כל נזק ישיר או עקיף ו/או הפסד ו/או הוצאה, עגמת נפש שעלולים להיגרם כתוצאה של חשיפה ו/או השימוש בשירותים
                    אחרים ו/או מקורות כאמור, לרבות כל חלק מהם.
                </span>
            </div>
            <div>&nbsp;</div>
            <div>
                <span>
                    <strong>
                        11.<span> </span>תשלומי לקוחות
                    </strong>
                </span>
            </div>

            <div>
                <span>
                    11.1<span> </span>בית העסק מצהיר ומתחייב כי תשלומי הלקוחות שיבוצעו על ידו במסגרת השירות ייגבו באופן חוקי ובמסגרת סמכותו של בית העסק והתקשרותו עם
                    לקוחות הקצה ובהתאם להוראות כל דין.
                </span>
            </div>

            <div>
                <span>
                    11.2<span> </span>מבלי לגרוע מן האמור לעיל, בית העסק מתחייב כי:
                </span>
            </div>

            <div>
                <span>
                    11.2.1<span> </span>כל תשלום לקוח המבוצע על ידו במסגרת השירות נעשה לאחר שיידע את לקוח הקצה בדבר התשלום, מהותו וסכומו, ולקוח הקצה הסכים לכך.
                </span>
            </div>

            <div>
                <span>
                    11.2.2<span> </span>כל תשלום לקוח המבוצע על ידו במסגרת השירות נעשה עבור בית העסק בלבד. חל איסור על שימוש בשירות לצורך גביית תשלומים עבור אחר.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    11.2.3<span> </span>תשלומי הלקוחות יבוצעו רק עבור אספקת מוצרים או מתן שירותים שבית העסק מספק במהלך העסקים הרגיל, ובמסגרת תחום הפעילות עליו בית העסק
                    הצהיר במועד ההצטרפות כפי שאושר על ידי החברה. חל איסור על גביית תשלומים שלא במסגרת תחום הפעילות שאושר כאמור. בית העסק מתחייב להודיע לחברה בכתב באופן
                    מיידי ביחס לכל שינוי במטרות בית העסק ובתחומים בהם הוא עוסק, במישרין או בעקיפין. בית העסק לא יגבה תשלומי לקוחות עבור מטרות ותחומים אלה, אלא לאחר שאושרו
                    על ידי החברה מראש ובכתב.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    11.2.4<span> </span>בית העסק מתחייב כי תשלומי הלקוחות ישקפו עסקאות אמת אשר למיטב ידיעתו של בית העסק לא בוצעו בתרמית.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    11.2.5<span> </span>&nbsp;בית העסק מתחייב לא לתת ללקוחות כסף מזומן או תחליפי כסף במסגרת עסקה המבוצעת באמצעות השירות.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    11.2.6<span> </span>בית העסק מתחייב שלא לבצע עסקאות בהן אמצעי התשלום של לקוח הקצה הינו בבעלות בית העסק ו/או מי מבעלי המניות של בית העסק ו/או מורשה
                    חתימה של בית העסק.
                </span>
            </div>

            <div>
                <span>
                    11.3<span> </span>קביעת סכומי תשלומי הלקוחות תעשה על ידי בית העסק ועל אחריותו בלבד. אין לחברה ו/או לאתר כל חלק בקביעת תשלומי הלקוחות והיא לא תהיה
                    אחראית בשום אופן ביחס לסכומי התשלומים כפי שנמסרו על ידי בית העסק, לרבות לבדיקת אמיתות גובה התשלומים, להטבות שהוענקו על ידי בית העסק או על ידי צדדים
                    שלישיים מטעמו וכיוצא באלה.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    11.4<span> </span>ידוע לבית העסק כי הסכום המרבי הניתן לסליקה באמצעות השירות הינו 20,000 ש"ח עבור עסקה רגילה ו-40,000 ש"ח עבור עסקת תשלומים, וזאת למעט
                    אם אישרה החברה לבית העסק בכתב סליקת סכום עסקה גבוה יותר, או ככל שהודיעה החברה לבית העסק על הגבלת סכום העסקה לסכום נמוך יותר. מובהר כי בית העסק אינו
                    רשאי לפצל עסקה אחת למספר עסקאות באופן העוקף את הסכום המרבי הניתן לסליקה עבור עסקה כאמור בסעיף זה או כפי שנקבע על ידי החברה.
                </span>
            </div>

            <div>
                <span>
                    11.5<span> </span>מבלי לגרוע מהאמור לעיל, מובהר כי החברה רשאית לקבוע מגבלות נוספות בקשר עם תשלומי הלקוחות, כפי שיימסרו לבית העסק במועד ההצטרפות, וכפי
                    שיעודכנו על ידי החברה מעת לעת לרבות ומבלי למצות בנושאים הבאים: סכום מקסימאלי לתשלום עבור לקוח קצה לעסקה, לחודש או למשך תקופה אחרת; סכום כולל לתשלומי
                    לקוחות לחודש או למשך תקופה; משך התקופה עבורה מבוצעים תשלומי הלקוחות; סוג תשלומי הלקוחות שבית העסק יהיה רשאי לבצע באמצעות השירות (לרבות הוראות קבע
                    ועסקאות בתשלומים); וכן כל מגבלה סבירה אחרת.
                </span>
            </div>

            <div>
                <span>
                    11.6<span> </span>הופרו המגבלות שנקבעו על ידי החברה לפי סעיף ‎11 זה, תהיה החברה רשאית לחסום או לבטל את מתן השירות בהתאם להוראות סעיף ‎‏20 שלהלן או
                    לעכב העברת תשלומי לקוחות לבית העסק, או לדרוש מבית העסק אישורים ו/או בטוחות כתנאי למתן השירות ו/או לצורך העברת תשלומי הלקוחות לבית העסק.&nbsp;
                </span>
            </div>
            <div>&nbsp;</div>
            <div>
                <span>
                    <strong>
                        12.<span> </span>עמלות ותשלומים נוספים
                    </strong>
                </span>
            </div>

            <div>
                <span>
                    12.1<span> </span>בתמורה לשימוש בשירות ישלם בית העסק עמלת סליקה בגין כל תשלום לקוח שיבוצע באמצעות השירות עמלת הסליקה תיקבע כאחוז מסכום תשלום הלקוח
                    (להלן: "העמלה האחוזית"). למרות האמור לעיל מובהר כי בהתאם לחבילת השירות אליה הצטרף הלקוח, עמלת הסליקה יכולה לכלול בנוסף גם סכום קבוע מראש לתשלום בגין
                    כל תשלום לקוח וכל משלוח חשבונית דיגיטלית ללא ביצוע תשלום ועבור פעולות נוספות, בהתאם לקבוע בתנאי החבילה (להלן: "עמלת פעולה"; להלן העמלה האחוזית ועמלת
                    הפעולה יחד ולחוד: "עמלת הסליקה"). עמלת הסליקה תנוכה מסכומי הגבייה שיועברו לבית העסק.
                </span>
            </div>

            <div>
                <span>
                    12.2<span> </span>למען הסר ספק מובהר כי בהתאם לתנאי החבילה, עמלת פעולה יכולה להיקבע עבור כלל תשלומי הלקוחות או הפעולות המבוצעות בבית העסק, או החל
                    מהיקף עסקאות חודשי מסויים, ככל ונקבעו במסגרת תנאי החבילה כמות פעולות אשר כלולות בתשלום אשר מעבר להן תחול עמלת פעולה נוספת.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    12.3<span> </span>החברה רשאית לקבוע מעת לעת תשלומים נוספים בגין השימוש בשירות, ובכלל זה, בגין פתיחת חשבון בשירות ובגין מסוף סליקה פיזי, וכן תשלומים
                    בגין שירותים נוספים. ככלל, התשלומים האמורים יגבו מאמצעי התשלום שמסר בית העסק לחברה, למעט אם נקבע אחרת על ידי החברה. על אף האמור, ככל שבית העסק לא סיפק
                    לחברה אמצעי תשלום או ככל שאמצעי התשלום שסופק על ידו לא יאפשר את גביית התשלומים, ינוכו התשלומים הנוספים מסכומי הגביה שיועברו לבית העסק (עמלת הסליקה
                    ו/או כל תשלום נוסף אותו תגבה החברה מבית העסק כאמור בסעיף ‎12.2 זה יקראו להלן: ״עלויות השירות״).
                </span>
            </div>

            <div>
                <span>
                    12.4<span> </span>עלויות השירות יקבעו בהתאם לתעריפים המפורסמים באתר ולשירותים שרכש בית העסק (ראו את תעריפי השירות בקישור שלהלן:
                    https://www.meshulam.biz), למעט אם הוסכם עם בית העסק אחרת ונמסר לו על כך בכתב.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    12.5<span> </span>במקרה בו בית העסק פנה לרישום לשירות דרך מערכת חיצונית המתממשקת לשירותי החברה ("המערכת החיצונית"), והצטרף לחבילה בתנאים מיוחדים
                    המוצעת למצטרפים דרך המערכת החיצונית ("חבילת המערכת) כאמור, יחולו תנאי חבילת המערכת על עסקאות שיחייב בית העסק באמצעות המערכת החיצונית, בלבד. במקרה בו
                    בית העסק יבצע חיובים ישירות במערכת משולם ולא דרך המערכת החיצונית, יחולו על העסקאות תנאי חבילות הסליקה המוצעות על ידי משולם, כאמור בסעיף 12.4 לעיל,
                    בלבד ותנאי חבילת המערכת לא יחולו על עסקאות כאמור. מובהר כי כברירת מחדל, ככל ובית העסק לא הצטרף לחבילת סליקה אחרת, יחולו על עסקאות כאמור תנאי חבילת pay
                    as you go.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    12.6<span> </span>מבלי לגרוע מן האמור, תעריף עמלת הסליקה לא יפחת מ-50 אגורות + מע"מ בגין כל תשלום לקוח (להלן: "העמלה המינימלית"). למען הסר ספק מובהר
                    כי גם במקרה בו עמלת הסליקה ננקבה באחוזים מגובה העסקה וחישובה באחוזים נמוך יותר מהעמלה המינימלית, תגבה החברה בגין העסקה את העמלה המינימלית.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    12.7<span> </span>החברה תהיה רשאית לשנות מעת לעת את תעריפי עלויות השירות (לרבות בשל שינוי בתעריפי שירות bit או Apple Pay או Google Pay, או שינוי
                    בהסדרים של החברה מול הגורמים המתפעלים שירותים אלה), וכן תהא רשאית לקבוע כי שירות שסופק ללא עלות יסופק בעלות, או כי יסופק בעלות החל מהיקף פעולות מסוים
                    של בית העסק, וזאת בהודעה שתישלח לבית העסק 14 ימים מראש. התעריפים המעודכנים יפורסמו באתר ויחולו החל במועד שצוין בהודעה לבית העסק. לא הודיע המשתמש לחברה
                    כי הוא מעוניין להפסיק את השירות בעקבות הודעת עדכון תעריפים כאמור בתוך 30 יום, ייחשב הוא כמי שמסכים לשינוי.
                </span>
            </div>

            <div>
                <span>
                    12.8<span> </span>מובהר כי החברה אינה אחראית לעמלות השונות הנגבות מלקוח הקצה על ידי מנפיקת הכרטיס.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    12.9<span> </span>החברה מוכרת מעת לעת חומרה&nbsp; המיועדת לשימוש לצורך שרותי הסליקה של החברה, ואשר כוללת תוכנות הפעלה המיועדות למטרה זו בלבד. מובהר כי
                    החברה לא תהיה אחראית לכל שימוש אחר שיעשה בחומרה וכן אינה מתחייבת כי תוכנות ההפעלה יתמכו בו. בהתאם, תעריפי רכישת החומרה מוצעים על ידי החברה בתמורה
                    לשימוש בחומרה לצורך שירותי הסליקה של החברה בלבד. מובהר כי ככל שבית העסק יבצע שימוש אחר בחומרה בטרם הסתיימה תקופת האחריות שנקבעה לחומרה, יחויב בית העסק
                    בתשלום חד פעמי לחברה בגובה ההפרש בין תעריף השוק של החומרה, כפי שמופיע אצל החברה, ובין התעריף ששולם על ידו לרכישת החומרה.&nbsp;
                </span>
            </div>
            <div>&nbsp;</div>
            <div>
                <span>
                    <strong>
                        13.<span> </span>ביצוע תשלומי לקוחות
                    </strong>
                </span>
            </div>

            <div>
                <span>
                    13.1<span> </span>ביצוע תשלומי לקוחות באמצעות השירות מותנה במסירת כל הפרטים המבוקשים באתר והזנתם לביצוע התשלום. בכלל זה, בעסקאות טלפוניות ובעסקאות
                    באינטרנט נדרש בית העסק להזין את תיאור העסקה ואת פרטי לקוח הקצה, לרבות מספר הטלפון שלו, וזאת כתנאי לביצוע תשלומי הלקוחות.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.2<span> </span>החברה לא תהיה אחראית בגין אי ביצוע תשלום או ביצוע שגוי של תשלום או כל נזק אחר שיגרם בשל אי מסירת כל הפרטים הדרושים או בשל כל סיבה
                    אחרת אשר אינה בשליטתה של החברה.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.3<span> </span>לאחר הזנת פרטי כרטיס האשראי, יבוצע ניסיון לסליקת הכרטיס בסכום התשלום.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.4<span> </span>שימוש בית העסק בהרשאה לחיוב/הוראת קבע מלקוחות קצה- בכל מקרה שבו בית העסק קיבל מלקוח קצה הרשאה לחיוב של כרטיס חיוב או אמצעי תשלום אחר
                    (להלן בסעיף זה: "הכרטיס"), המותנית באישורה של מנפיקת הכרטיס (להלן: "המנפיקה"), לרבות בגין עסקת יסוד שעניינה מתן שירות מסוים על ידי בית העסק, ברציפות
                    או לסירוגין במשך תקופה, אף אם היא אינה נקובה בזמן, או עסקת יסוד שעניינה אספקת מוצרים במשך תקופה, ברציפות או לסירוגין, ובין אם החיובים הינם בסכום קבוע
                    או משתנה (להלן: "הרשאה לחיוב"), יחולו ההוראות שלהלן:&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.4.1<span> </span>ידוע לבית העסק כי אישור ההרשאה על ידי המנפיקה כפוף לחוק. מבלי לגרוע מכלליות האמור, ישדר בית העסק הרשאה לחיוב לחברה רק לאחר שזיהה
                    את לקוח הקצה באמצעות פרט אימות מוגבר. לבקשת החברה, יפרט בית העסק בפני החברה את השיטות, האמצעים והנהלים המשמשים אותו לזיהוי לקוח הקצה ואימות זהותו
                    והסכמתו.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.4.2<span> </span>בנוסף, לצורך הקמת הרשאה לחיוב בקשר לכרטיס, יקיים בית העסק את הנהלים הנהוגים אצל מנפיקת הכרטיס, לרבות ביחס לאופן זיהוי לקוח הקצה
                    ותיעוד הסכמתו למתן ההרשאה.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.4.3<span> </span>ככל שבקשת בית העסק לקבלת הרשאה לחיוב תעשה באמצעות החברה או בסיועה, על בית העסק לקיים אחר הוראות החברה ביחס להרשאה לחיוב, כפי שיהיו
                    מעת לעת.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.4.4<span> </span>החברה רשאית להתנות את הקמת ההרשאה לחיוב בבדיקה כי נערך זיהוי של הלקוח וכי התקבלה הסכמתו המפורשת להרשאה לחיוב, והיא רשאית לסרב
                    לביצוע ההרשאה, בין היתר, במקרה שבו בית העסק לא קיים אחר דרישות חוק שירותי תשלום, נהלי המנפיקה הרלוונטית והוראות החברה, או שלא ניתן לוודא כי בית העסק
                    זיהה את לקוח הקצה באמצעות פרט אימות מוגבר.
                </span>
            </div>

            <div>
                <span>
                    13.4.5<span> </span>&nbsp;בית העסק מצהיר כי ידוע לו כי הקמת הרשאה לחיוב מותנה באישור המנפיקה. ידוע לבית העסק כי על פי חוק שירותי תשלום, המנפיקה מחויבת
                    לתת את תשובתה לבקשה להקמת הרשאה לחיוב בתוך זמן מוגדר, ואולם אין לחברה שליטה על עניין זה ולא תחול עליה כל אחריות לעיכובים הנובעים מכך. במקרה שהמנפיקה
                    סירבה לאשר הרשאה לחיוב, החברה תעביר לבית העסק את נימוקי הסירוב, ככל שאלה נמסרו לה.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.4.6<span> </span>בית העסק מצהיר כי ידוע לו כי אישור ו/או קליטה של הרשאה לחיוב על ידי החברה אינה מהווה אישור של החברה לחיוב מסוים על פיה או אישור
                    ביחס למסגרת האשראי של לקוח הקצה, והדבר אינו מטיל על החברה חובה להמשיך ולחייב את לקוח הקצה על פי אותה ההרשאה.
                </span>
            </div>

            <div>
                <span>
                    13.4.7<span> </span>בית העסק מתחייב לעשות שימוש בהרשאה לחיוב ובכל הרשאה אחרת שנתן לקוח הקצה אך ורק לאותם עניינים שבגינם נתן אותה לקוח הקצה ובהתאם
                    לתנאים ולמגבלות שנקבעו בין בית העסק ולקוח הקצה. בכלל זה, בית העסק לא ידרוש לבצע חיוב העולה על הסכום שנקבע בהרשאה לחיוב והחברה אינה מחויבת לאפשר חיוב
                    העולה על סכום כאמור.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.4.8<span> </span>בית העסק מצהיר כי ידוע לו כי על פי חוק שירותי תשלום, הרשאה לחיוב שלא נעשה בה שימוש במשך 24 חודשים ממועד אישור ההרשאה או ממועד
                    החיוב האחרון שנעשה מכוחה, לפי המאוחר, אינה תקפה.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.4.9<span> </span>ביטול הרשאה לחיוב מצד לקוח קצה או ביטול של חיוב- נמסר לבית העסק כי בהתאם לחוק שירותי תשלום, ככל שבית העסק קיבל מלקוח הקצה הרשאה
                    לחיוב, לקוח הקצה רשאי לבטל הרשאה זו בהודעה למנפיקה ו/או בהודעה לבית העסק, וכי עם ביטול כאמור, בית העסק אינו רשאי עוד לבצע חיובים מכוח ההרשאה, והמנפיקה
                    אינה רשאית עוד לכבד חיובים מכוח ההרשאה לחיוב. כן נמסר לבית העסק, כי על פי החוק, לקוח הקצה רשאי לבטל חיוב שבוצע מכוח הרשאה לחיוב, בהודעה למנפיקה בפרק
                    הזמן הקבוע בחוק, וכי במקרה כזה, נדרשת השבה של סכום החיוב ללקוח הקצה. בנוסף, נמסר לבית העסק כי על פי החוק נדרשת השבה של חיוב מכוח הרשאה לחיוב או חלקה,
                    במקרה שהחיוב נעשה תוך חריגה מן ההרשאה (לרבות חיוב בסכום גבוה מן המותר על פי תנאי ההרשאה, חיוב מכוח הרשאה שפג תוקפה או חיוב במועד שונה מזה שנקבע
                    בהרשאה), וכן במקרה שסכום החיוב חרג מהסכום שלקוח הקצה יכול היה לצפות שיחויב בו באופן סביר בהתחשב בחיובים הקודמים שבוצעו מכוח אותה הרשאה ובנסיבות
                    העניין. בכל מקרה בו החברה תידרש להשיב סכום כלשהוא באחת מן הנסיבות המפורטות בסעיף זה, היא תהיה רשאית לחייב את בית העסק בסכום אותו חויבה להשיב ובהוצאות
                    הסבירות שנגרמו לה עקב כך.&nbsp;&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.5<span> </span>תשלום חוזר. במקרה של תשלום חוזר של הוראת קבע (או במקרה אחר של שימוש חוזר בפרטי אשראי שמורים), באחריות בית העסק ליידע את לקוח הקצה כי
                    אם לא יעלה בידי החברה לגבות את תשלומי הלקוח במועד שנקבע לתשלום, תהא החברה רשאית, לפי שיקול דעתה, לשוב ולסלוק את כרטיס החיוב של לקוח הקצה עד פירעון
                    מלוא התשלום.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.6<span> </span>עסקאות בתשלומים. במסגרת פריסת חיוב מסוים למספר תשלומים נפרדים, יבוצע כל תשלום כעסקה נפרדת ועצמאית באותו היום בכל חודש קלנדארי עוקב,
                    בהתאם למועד התשלום הראשוני. החברה רשאית לדרוש מבית העסק אישורים נוספים בגין ביצוע עסקאות בתשלומים.
                </span>
            </div>

            <div>
                <span>
                    13.7<span> </span>תשלומי לקוחות יבוצעו במטבע שקלי בלבד אלא אם קבעה החברה אחרת בהתאם לשיקול דעתה הבלעדי.
                </span>
            </div>

            <div>
                <span>
                    13.8<span> </span>עם ביצוע התשלום או בסמוך לאחריו, יישלח ללקוח הקצה אישור תשלום למספר הטלפון שהוזן בעת ביצוע התשלום, וזאת כשירות לבית העסק. יובהר כי
                    לקוח הקצה אינו מקבל שירותי תשלום מכל סוג שהוא מהחברה וכי שירותי התשלום ניתנים לבית העסק בלבד.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.9<span> </span>לאחר ביצוע תשלום, יופיעו בפירוט עסקאות כרטיס האשראי של לקוח הקצה פרטיו של בית העסק באופן שלהלן: ״משולם- [שם בית העסק]״. ידוע עם זאת
                    לבית העסק כי פרטי בית העסק שיופיעו בחברת האשראי לגבי התשלום כאמור יהיו פרטי החברה ולא פרטי בית העסק.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.10<span> </span>למען הסר ספק מובהר כי בית העסק אחראי לכל תשלום מס הנובע מתשלום המבוצע במסגרת השירות ולכל דיווח הנדרש על פי כל דין בקשר לתשלומים
                    כאמור, ככל שנדרש, ולחברה לא תהיה כל אחריות בקשר עם האמור.
                </span>
            </div>

            <div>
                <span>
                    13.11<span> </span>החברה רשאית לסרב לבצע או לבטל מידית תשלומי לקוחות שבוצעו באמצעות החשבון, או לעכב את העברתם לבית העסק, ובמקרה שהחברה כבר זיכתה את
                    חשבון בית העסק בגין תשלומי הלקוחות היא תהא רשאית לחייב את בית העסק בסכום התשלומים כאמור או לקזז את הסכום מכל תשלום המגיע או שיגיע ממנה לבית העסק, ככל
                    שקיים לדעתה חשד סביר כי לקוח הקצה לא הסכים למלוא פרטי התשלום, או שהתשלום בוצע תוך חריגה מהרשאה שנתן, או מטעמים סבירים אחרים ולרבות הטעמים הבאים:&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.11.1<span> </span>על פי הוראות תפעול שנמסרו לבית העסק על ידי החברה או על פי תנאי האישור שהחברה נתנה לבית העסק, נדרש שימוש בפרט אימות מוגבר, אחד או
                    יותר (כגון שימוש ברכיב EMV ובקוד סודי), ולא נעשה שימוש בפרטי האימות האמורים.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.11.2<span> </span>על פי תנאי האישור שהחברה נתנה לבית העסק לביצוע פעולת תשלום במסמך חסר נדרש שימוש ברכיב חיוני מסוים, אחד או יותר, ולא נעשה שימוש
                    ברכיב החיוני האמור.
                </span>
            </div>

            <div>
                <span>
                    13.11.3<span> </span>&nbsp;בכל מקרה בו החברה תיווכח לדעת כי חשבון בית העסק זוכה בגין העסקה בטעות.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.11.4<span> </span>בית העסק נתבקש להעביר לידי החברה אישורי הוכחת עסקה, לרבות כל מסמך הנוגע לעסקה ו/או תרגום של מסמכי העסקה לאנגלית במקרה של עסקה
                    שבוצעה באמצעי תשלום זר ובית העסק לא מילא בקשה זו.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.11.5<span> </span>אמצעי התשלום הינו בבעלות בית העסק ו/או מי מבעלי המניות של בית העסק ו/או מורשה חתימה של בית העסק.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.11.6<span> </span>בכל מקרה בו החברה תיווכח כי לבית העסק יש לקוח קצה אחד בלבד, או כי למעלה מ- 50% מהיקף העסקאות או מחזור העסקאות שנסלקו על ידי החברה
                    או מפעילותו העסקית של בית העסק מתבצעת מול לקוח קצה יחיד.&nbsp;&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.11.7<span> </span>בית העסק לא קיים איזו הוראה אחרת מההוראות הכלולות בתקנון זה או מההוראות שנמסרו לו על ידי החברה ו/או הובהר כי המצגים שהציג בית
                    העסק בגדר תקנון זה ו/או איזה מבין נספחיו הם כוזבים.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.11.8<span> </span>עסקת היסוד שבגינה נעשית העסקה אינה מסוג עסקות היסוד שבית העסק רשאי ו/או נוהג לבצע, או שהיא אינה מסוג עסקות היסוד שהחברה או חברת
                    האשראי הסולקת נוהגות ו/או רשאיות ו/או מוכנות לבצע, או שקיים חשד שעסקת היסוד אינה חוקית או שהיא עלולה להוות הלבנת הון/מימון טרור.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.12<span> </span>חיוב באמצעות אפליקציית bit&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.12.1<span> </span>החברה תאפשר לבית העסק, בהתאם לחבילת השירות אליה הוא משתייך, לבצע תשלומי לקוחות באמצעות אפליקציית התשלומים bit (להלן: "חיוב
                    ב-bit").
                </span>
            </div>

            <div>
                <span>
                    13.12.2<span> </span>החיוב ב-bit מאפשר בשלב זה ביצוע עסקאות רגילות בכרטיס אשראי ישראלי בלבד. למען הסר ספק, בשלב זה החיוב ב-bit אינו מאפשר ביצוע עסקאות
                    בתשלומים, חיוב בהוראות קבע בכרטיס אשראי או עסקאות באמצעות כרטיסי תייר.
                </span>
            </div>

            <div>
                <span>
                    13.12.3<span> </span>השימוש בשירות חיוב ב-bit כפוף לעלויות השירות כפי שיפורסמו מעת לעת על ידי החברה באתר החברה, כמפורט בקישור שלהלן:
                    https://www.meshulam.biz, ולהוראות תקנון זה, למעט אם הסכימו הצדדים אחרת.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.12.4<span> </span>למען הסר ספק כל ההוראות החלות על תשלומי לקוחות לפי הסכם זה יחולו גם על תשלומים באמצעות חיוב ב-bit. בכלל זה (מבלי למצות), מובהר
                    כי:
                </span>
            </div>

            <div>
                <span>
                    13.12.4.1<span> </span>&nbsp;תשלומי הלקוחות בגין החיובים ב-bit יועברו לחשבון הבנק של בית העסק, במועדים ובתנאים החלים על יתר תשלומי הלקוחות.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.12.4.2<span> </span>ביטול עסקאות שנעשו באמצעות חיוב ב-bit ייעשה באופן הקבוע בסעיף ‎‏16 שלהלן, ובכפוף לכל ההוראות בדבר סירוב, עצירה או ביטול עסקאות
                    המפורטות בתנאי שימוש אלו. בכלל זה, על לקוח הקצה לפנות בעניינים אלו לבית העסק. מובהר כי פניות בנושאים אלה שיועברו לכל גורם אחר ויופנו לחברה יועברו לבית
                    העסק, כפי שנעשה בכל מקרה אחר של ביטול תשלום.
                </span>
            </div>

            <div>
                <span>
                    13.12.5<span> </span>בעת ביצוע התשלום באפליקציית bit על ידי לקוח הקצה, כפוף הלקוח גם לתנאי השימוש של אפליקציית bit של בנק הפועלים. מובהר כי תנאי
                    השימוש של אפליקציית bit אינם חלים במערכת היחסים שבין החברה לבית העסק וכי במקרה של סתירה בין הוראות תקנון זה להוראות תנאי השימוש באפליקציית bit, יחולו
                    הוראות תקנון זה.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.12.6<span> </span>מובהר כי השימוש בחיוב ב-bit על ידי בית העסק הינו מטעמי נוחות טכנולוגית בלבד וכי בנק הפועלים המפעיל את אפליקציית bit אינו אחראי
                    להעברת תשלומי הלקוחות אל החברה או לעסקת היסוד בין לקוח הקצה ובית העסק. כמו כן, על לקוח הקצה לפנות בכל עניין בקשר לעסקת היסוד לבית העסק.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.12.7<span> </span>מובהר כי בנק הפועלים מנטר את השימוש באפליקציית bit וכי הוא עשוי לבצע בקרה, לרבות בדיקה מדגמית או פרטנית, של חוקיות השימוש
                    באפליקציה ובכלל זה לפנות לבית העסק לצורך כך. כמו כן, בהתאם להוראות הדין, מידע המוחזק בידי החברה אודות בית העסק בקשר לשימוש באפליקציית bit, יועבר לבנק
                    הפועלים לצורכי ניטור כאמור.&nbsp; &nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.13<span> </span>חיוב באמצעות אפליקציית&nbsp; Apple Pay
                </span>
            </div>

            <div>
                <span>
                    13.13.1<span> </span>החברה מספקת תמיכה בחיוב באמצעות פלטפורמת Pay&nbsp; Apple של חברת Apple Inc. (להלן: "Apple") על גבי אפשרויות התשלום של משולם
                    (להלן: "שירות Apple Pay").&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.13.2<span> </span>השימוש בשירות חיוב ב- Apple Pay בתשלום עמלת סליקה לכל חיוב בהתאם לחבילת הסליקה של בית העסק. למען הסר ספק, החברה רשאית לעדכן את
                    תעריפי שירות Apple Pay בכל מקרה בו יחול שינוי בהסדרים בין החברה וביןApple&nbsp; ו/או כל גורם אחר המפעיל את השירות..
                </span>
            </div>

            <div>
                <span>
                    13.13.3<span> </span>השירות זמין רק עבור עסקאות רגילות או עסקאות תשלומים. השירות אינו תומך בעסקאות הוראת קבע או כל עסקה לחיוב חוזר של פרטי אשראי
                    שנשמרו.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.13.4<span> </span>השירות זמין אך ורק במכשירים מתוצרת Apple ברכישות המבוצעות דרך דפדפן Safari בלבד.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.13.5<span> </span>נתוני בית העסק כפי שיימסרו על ידו בעת הרישום לשירות יימסרו ל- Apple ויישמרו על ידה ו/או על ידי צד שלישי מטעמה.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.13.6<span> </span>השירות כפוף לתנאי השימוש של שירות Apple Pay של חברת Apple בכתובת ("תנאי Apple"):
                </span>
            </div>

            <div>
                <span>hנttps://developer.apple.com/apple-pay/terms/apple-pay-web/</span>
            </div>

            <div>
                <span>
                    בהסכמתו להצטרף לשירות ו/או בעצם השירות, בית העסק מאשר את הסכמתו לתנאי Apple המהווים חלק בלתי נפרד מטופס זה והסכמה אליהם מהווה תנאי מקדים לשימוש
                    בשירות.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.13.7<span> </span>Apple רשאית מעת לעת לשנות ו/או לעדכן את תנאי Apple, ובית העסק יהיה מחוייב בהתאם לנוסחם המעודכן של תנאי Apple. מבלי לגרוע מן האמור
                    לעיל, בית העסק עשוי להידרש לאשר את התנאים המעודכנים כתנאי להמשך השימוש בשירות.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.13.8<span> </span>אין לעשות שימוש שירות Apple Pay לצורך חיוב תרומות, לרבות עבור כל גוף או אדם אחר.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    13.13.9<span> </span>משולם תהיה רשאית לחסום את השירות באופן מיידי לבית העסק במקרה שבית העסק יפר את תנאי Apple ו/או על פי דרישה מחברת Apple.
                </span>
            </div>

            <div>
                <span>
                    13.13.10<span> </span>ידוע לבית העסק כי משולם רשאית להעביר ל Apple מידע ונתונים אודות שימוש בית העסק בשירות, לרבות אגב ניטור פעילות או מידע על פעולה
                    חריגה או בלתי תקינה, ו- Apple תהיה רשאית לעשות בהם שימוש לפי דין ובכלל זה לפי תנאי Apple.
                </span>
            </div>

            <div>
                <span>
                    13.13.11<span> </span>ידוע לבית העסק כי Apple רשאית לבצע שינויים בשירות, ולצורך כך או מכל סיבה אחרת לבצע הפסקה זמנית או של השירות או חלק ממנו לצורך
                    תיקונים, התאמות, שינויים או שדרוגים, ולבית העסק לא תהיה כל טענה כלפי Apple או כלפי משולם בשל כך.
                </span>
            </div>

            <div>
                <span>
                    13.13.12<span> </span>ידוע לבית העסק כי השירות תלוי בתמיכה על ידי Apple ומשכך משולם לא תהיה אחראית לכל תקלה או בעיה או הפסקה בשירות שמקורה בחברת
                    Apple.
                </span>
            </div>

            <div>
                <span>
                    13.13.13<span> </span>משולם רשאית להפסיק את השירות בכל עת בהודעה לבית העסק, לרבות בכל מקרה בו יופסק מתן השירות על ידי Apple.
                </span>
            </div>
            <div>&nbsp;</div>
            <div>
                <span>
                    <strong>
                        14.<span> </span>שימוש במסוף סליקה
                    </strong>
                </span>
            </div>

            <div>
                <span>
                    בכפוף להוראות הסכם זה, מתחייב בית העסק כי בעת שימוש במסוף סליקה, לא יקבל כרטיס חיוב כאמצעי תשלום, אם נוכח, או היה עליו לדעת בנסיבות העניין, כי התקיים
                    אחד או יותר מאלה (לרבות במקרה של חשש או חשד כי התקיים אחד מאלה, לפי העניין):
                </span>
            </div>

            <div>
                <span>
                    14.1<span> </span>לקוח הקצה אינו ה"משלם", דהיינו, הוא אינו האיש שפרטיו רשומים על גבי הכרטיס.
                </span>
            </div>

            <div>
                <span>
                    14.2<span> </span>מספר הכרטיס המודפס על חשבונית העסקה אינו זהה למספר המוטבע על גבי הכרטיס.
                </span>
            </div>

            <div>
                <span>
                    14.3<span> </span>הכרטיס אינו תקף.
                </span>
            </div>

            <div>
                <span>
                    14.4<span> </span>טרם ביצוע העסקה או במהלכה נתקבלה בבית העסק הודעה כי הכרטיס נחסם, בוטל או שאין לקבלו מסיבה אחרת.
                </span>
            </div>

            <div>
                <span>
                    14.5<span> </span>הייתה בבית העסק תשתית לעשיית עסקאות באמצעי תשלום מאובטח והחיוב נעשה ללא הקשת הקוד הסודי של לקוח הקצה, למעט אם על פי ההוראות שנמסרו
                    לבית העסק על ידי החברה הדבר אינו נדרש.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    14.6<span> </span>התעורר חשד כי הכרטיס מזויף.
                </span>
            </div>

            <div>
                <span>
                    14.7<span> </span>ארבע הספרות האחרונות של המספר המוטבע על פני הכרטיס אינן זהות לארבע הספרות האחרונות במספר הנקוב על גב הכרטיס.
                </span>
            </div>
            <div>&nbsp;</div>
            <div>
                <span>
                    <strong>
                        15.<span> </span>העברת תשלומי הלקוחות לבית העסק
                    </strong>
                </span>
            </div>

            <div>
                <span>
                    15.1<span> </span>מסלול זיכוי שבועי. בכל יום שני יועברו לחשבון הבנק של בית העסק תשלומי הלקוחות שבוצעו בהצלחה באמצעות השירות בשבוע הקודם (קרי, בימי
                    ראשון עד שבת בשבוע הקודם) והועברו לחברה בפועל מחברת האשראי הסולקת, בניכוי עלויות השירות ו/או כל עלות אחרת בה חב בית העסק לחברה. יובהר כי התשלומים
                    יופיעו בחשבון הבנק של בית העסק ביום העסקים העוקב למועד ההעברה.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    15.2<span> </span>מסלול זיכוי חודשי. עד ל-9 בכל חודש קלנדארי, יועברו לחשבון הבנק של בית העסק תשלומי הלקוחות שבוצעו בהצלחה באמצעות השירות בחודש
                    הקלנדארי שקדם לו והועברו לחברה בפועל מחברת האשראי הסולקת, בניכוי עלויות השירות ו/או כל עלות אחרת בה חב בית העסק לחברה. יובהר כי התשלומים יופיעו בחשבון
                    הבנק של בית העסק ביום העסקים העוקב למועד ההעברה.
                </span>
            </div>

            <div>
                <span>
                    15.3<span> </span>על אף האמור לעיל, החברה רשאית להודיע לבית העסק מעת לעת על שינוי במועדי זיכוי חשבון בית העסק. הודעה על כך תימסר לבית העסק, מראש
                    ובכתב, במועדים המפורטים בסעיף ‏2.2 לעיל. מבלי לגרוע מהוראות סעיף ‏2.2 האמור, מובהר כי במקרים בהם נדרש לדעת החברה שינוי מידי במועדי הזיכוי, לרבות בשל
                    סיכונים הכרוכים בסליקה, החברה תהיה רשאית לעשות כן בהודעה שתכנס לתוקף באופן מידי.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    15.4<span> </span>הצטרפות למסלול זיכוי שבועי והקדמת מועד הזיכוי. הצטרפות בית עסק למסלול שבועי כפופה לאישור החברה ולבדיקה בדבר מידת הסיכון או החשיפה
                    בפעילות בית העסק בהתאם למדיניות ניהול הסיכונים של החברה ולדרישות חברת האשראי הסולקת.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    15.4.1<span> </span>בהתאם, החברה תהא רשאית לסרב לבקשת בית העסק להצטרף למסלול הזיכוי השבועי, ולא תהא לבית העסק כל טענה בעניין. בכלל זה, החברה רשאית
                    לסרב לבקשה כאמור ל:
                </span>
            </div>

            <div>
                <span>
                    15.4.1.1<span> </span>בתי עסק שאינם עוסקים באספקה מידית;
                </span>
            </div>

            <div>
                <span>
                    15.4.1.2<span> </span>בתי עסק בתחום מרובה סיכון (לפי הגדרות ניהול הסיכונים של החברה);&nbsp;
                </span>
            </div>

            <div>
                <span>
                    15.4.1.3<span> </span>בתי עסק עם היסטוריית הכחשות או ביטולי עסקאות מרובים או ריבוי כישלונות חיוב;&nbsp;
                </span>
            </div>

            <div>
                <span>
                    15.4.1.4<span> </span>בתי עסק חדשים שטרם צברו ותק מינימלי של 3 חודשי סליקה;&nbsp;
                </span>
            </div>

            <div>
                <span>
                    15.4.1.5<span> </span>בתי עסק שלא היו פעילים באופן רציף טרם מועד הבקשה;
                </span>
            </div>

            <div>
                <span>
                    15.4.1.6<span> </span>מכל עילה אחרת אשר לדעת החברה, לפי מידת החשיפה וניהול הסיכונים, זמן הזיכוי הסביר לבית העסק הוא במועד זיכוי חודשי, והכל על פי
                    שיקול דעתה הבלעדי של החברה.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    15.4.2<span> </span>סורבה בקשה כאמור, והיו בידי בית העסק נתונים בקשר לפעילות בית העסק המצדיקים לטעמו בחינה מחדש של הבקשה, רשאי בית העסק לפנות אל החברה
                    לבחינה מחודשת את הבקשה.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    15.4.3<span> </span>הוראות סעיף זה יחולו בשינויים המחויבים גם לגבי בקשת בית עסק קיים בשירות הנמצא במסלול הזיכוי החודשי ומבקש לעבור למסלול הזיכוי
                    השבועי.
                </span>
            </div>

            <div>
                <span>
                    15.4.4<span> </span>יובהר כי מעבר בין חבילות זיכוי עשוי להיות כרוך בשינוי עלויות השירות. בכלל זה מובהר כי חלק ממסלולי התמחור של חבילות הזיכוי החודשי
                    אינו אפשרי במעבר לחבילת זיכוי שבועית - לדוגמא, במעבר מחבילת זיכוי חודשית שבה עלות השירות מבוססת על תמחור לפי היקף סליקה חודשי (קרי, עמלת הסליקה לחודש
                    מסוים נקבעת לפי היקף הסליקה החודשי באותו חודש), מעבר לחבילה שבועית יהיה כרוך בתמחור חדש של החבילה שאינו מבוסס על מדידת היקף סליקה. במקרה כאמור במידה
                    ובית העסק יהיה מעוניין לבצע מעבר לחבילת זיכוי שבועי ובקשתו אושרה, עלויות השירות יהיו בהתאם לנהוג בחברה לפי קביעת החברה לסוג החבילות האמור.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    15.5<span> </span>הייתה עסקת היסוד עם לקוח הקצה עסקת יסוד שנקבעו לה בדין הוראות מיוחדות המאפשרות ביטולה על ידי לקוח הקצה לאחר עשייתה, תהא החברה רשאית
                    להודיע לבית העסק, מראש ובכתב, במועדים המפורטים בסעיף ‏2.2 לעיל, כי ככלל, יזוכו תשלומי הלקוחות המתייחסים לעסקות מסוג זה, רק במועד שבו חלף הזמן הנתון
                    ללקוח הקצה לבטל את העסקה ו/או את עסקת היסוד. הוראה כאמור יכול שתתייחס לכלל תשלומי הלקוחות או לסוג מסוים של תשלומי לקוחות, בסכום מסוים או בגין סוגים
                    מסוימים של מוצרים או שירותים, או לבתי עסק בעלי אפיון מסוים.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    15.6<span> </span>מבלי לגרוע מהוראות תקנון זה, תהא החברה רשאית לדרוש מבית העסק מעת לעת אישורים ו/או אמצעי זיהוי נוספים לצורך אימות פרטי חשבון בית העסק
                    ו/או פרטי תשלומי לקוחות, כתנאי להעברת תשלומי הלקוחות ו/או מתן השירות לבית העסק. יודגש, למען הסר ספק, כי האמור לעיל יחול גם לאחר הצטרפות בית העסק
                    לשירות, וככל ויידרשו אישורים או אמצעי זיהוי נוספים כאמור, עקב דרישת חברת האשראי הסולקת או מכל סיבה אחרת, מסירתם תהווה תנאי להעברת תשלומי הלקוחות לבית
                    העסק. בכלל זה, ולמען הסר ספק מובהר כי החברה רשאית לדרוש מבית העסק, במקרה בו לדעת החברה קיים חשש לביטול תשלומי לקוחות ו/או לפעילות בלתי תקינה של בית
                    העסק ו/או לחשיפה אחרת של החברה, חתימה על התחייבויות ו/ערבויות או בטחונות נוספים כתנאי להעברת תשלומי הלקוחות והיא רשאית לעכב את תשלומי הלקוחות עד
                    למסירתם כאמור.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    15.7<span> </span>למען הסר ספק מובהר כי החברה לא תשלם, והיא אינה מחויבת לשלם ריבית, הכנסה ו/או תשלום אחר, ביחס לכל סכום שיוחזק על ידה עבור בית העסק.
                </span>
            </div>
            <div>&nbsp;</div>
            <div>
                <span>
                    <strong>
                        16.<span> </span>ביטול, עצירה והפסקה של תשלומי לקוחות והכחשת עסקאות&nbsp;
                    </strong>
                </span>
            </div>

            <div>
                <span>
                    16.1<span> </span>ביטול על ידי לקוח:
                </span>
            </div>
            <div>&nbsp;</div>
            <div>
                <span>לקוח קצה שפנה לשירות הלקוחות של החברה לצורך ביטול עסקה, רשאית החברה להפנות אותו לבית העסק על מנת שיבצע מולו את ביטול העסקה.&nbsp;</span>
            </div>

            <div>
                <span>
                    16.2<span> </span>ביטול על ידי בית העסק:
                </span>
            </div>

            <div>
                <span>
                    16.2.1<span> </span>בית העסק רשאי לבטל באמצעות השירות תשלומי לקוחות שטרם הועברו לחשבון בית העסק. במקרה כאמור יזוכה חשבונו של לקוח הקצה בסכום התשלום
                    שבוטל.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    16.2.2<span> </span>על אף האמור לעיל, במקרים שיפורטו להלן, בית העסק אינו רשאי לבטל באופן עצמאי את תשלום לקוח הקצה באמצעות השירות ויהיה עליו לפנות
                    למוקד החברה בבקשה לביטול התשלום:
                </span>
            </div>

            <div>
                <span>
                    16.2.2.1<span> </span>ביטול תשלום במסגרת עסקה בתשלומים;
                </span>
            </div>

            <div>
                <span>
                    16.2.2.2<span> </span>ביטול תשלום במסגרת הרשאה לחיוב והוראת קבע.
                </span>
            </div>

            <div>
                <span>
                    16.2.3<span> </span>ביטול תשלום שהועבר לחשבון בית העסק - ככלל, יעשה ביטול התשלום על פי הסכמה הדדית בין בית העסק ללקוח הקצה, ולא באמצעות החברה. על אף
                    האמור, החברה תהיה רשאית, אך לא חייבת, לבצע את ביטול התשלום. החליטה החברה כאמור, תחייב החברה את חשבון בית העסק במלוא סכום התשלום הנדרש בביטול ותזכה
                    בסכום זה את לקוח הקצה.&nbsp;&nbsp;
                </span>
            </div>

            <div>
                <span>
                    16.2.4<span> </span>על אף האמור לעיל, יובהר כי לא יבוטל תשלום לקוח על ידי החברה כאשר יתרת חשבונו של בית העסק אינה מאפשר חיובו בתשלום המלא.&nbsp;&nbsp;
                </span>
            </div>

            <div>
                <span>
                    16.3<span> </span>ביטול ביוזמת החברה:
                </span>
            </div>

            <div>
                <span>
                    החברה רשאית (אך אינה חייבת) לבטל או לעצור כל תשלום שלגביו התעורר חשד שאינו תקין, לרבות כמפורט בסעיף ‏13.11 לעיל, ולחייב את חשבון בית העסק או כל אמצעי
                    תשלום הרשום על שמו במלוא סכום התשלום הנדרש בביטול. עשתה החברה כאמור, תהא רשאית לזכות את לקוח הקצה בסכום התשלום שבוטל, זאת מבלי שתהא לבית העסק כל טענה
                    ו/או דרישה בעניין.&nbsp;&nbsp;
                </span>
            </div>

            <div>
                <span>
                    16.4<span> </span>הפסקת ביצוע תשלום עקב טענות משלם בדבר אי אספקת מוצר או שירות- נמסר לבית העסק כי בהתאם לחוק שירותי תשלום, לקוח הקצה רשאי לדרוש הפסקת
                    תשלומים בגין עסקה, במקרה שלטענתו הנכס או השירות שנרכש בעסקת היסוד לא סופק על אף שחלף מועד האספקה שעליו הוסכם בין לקוח הקצה לבית העסק, או שבית העסק
                    הפסיק לטענתו דרך קבע לספק נכסים או שירותים שהוסכם לגביהם, ובתנאי שלקוח הקצה טוען כי הודיע לבית העסק על ביטול עסקת היסוד, ככל שניתן היה להודיע לו על
                    כך.&nbsp; מובהר כי שימוש בפרט אימות מוגבר אינו מהווה הגנה לבית העסק מפני טענה הלקוח בהתאם לסעיף זה, וכי בכפוף לתנאים שפורטו לעיל, יופסק ביצוע הוראת
                    התשלום וחיוב לקוח הקצה מכוחה החל ממועד מסירת הדרישה על ידי לקוח הקצה.
                </span>
            </div>

            <div>
                <span>
                    16.5<span> </span>הפסקת ביצוע של פעולת תשלום מובטחת בשל חדלות פירעון- נמסר לבית העסק כי בהתאם לחוק שירותי תשלום, החברה עשויה להידרש להפסיק את ביצועה
                    של פעולת תשלום בגין עסקת יסוד של בית עסק שמספק מוצרים באספקה שאינה מיידית, אם הובא לידיעתה כי הוגשה לבית המשפט, לגבי בית העסק, בקשה למתן צו לכינוס
                    נכסים או צו פירוק או צו לפתיחת הליכי חדלות פירעון. מובהר כי החברה עשויה שלא לדעת האם בית העסק מספק נכסים באספקה שאינה מידית ועל כן עשויה להידרש להניח
                    שזה אכן המצב. ככל שהחברה תיווכח, לפי שיקול דעתה, שבית העסק סיפק את הנכס ללקוח הקצה, בהתאם לפניה אפשרית בעניין זה ללקוח הקצה ו/או לבית המשפט ו/או לבעל
                    התפקיד שמונה בהליכי חדלות הפירעון, היא רשאית על פי חוק שירותי תשלום להעביר את תשלומי הלקוחות שעוכבו לבית העסק. בכל מקרה, תפעל החברה בהתאם להוראות בית
                    המשפט המוסמך.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    16.6<span> </span>טענות לקוח קצה בדבר שימוש לרעה באמצעי תשלום- נמסר לבית העסק כי בהתאם לחוק, קיימת הגבלה על אחריותו של לקוח הקצה בגין שימוש לרעה
                    באמצעי תשלום, דהיינו, ביצוע עסקה על ידי שימוש באמצעי התשלום או ברכיב חיוני שלו כמשמעותו בחוק שירותי תשלום בידי מי שאינו זכאי לכך (כגון במקרה של אובדן
                    של הכרטיס או גניבה של הכרטיס או פרטיו). על פי חוק שירותי תשלום, החברה רשאית לחייב את בית העסק בגין הסכום בו זוכה לקוח קצה בשל טענה בדבר שימוש לרעה,
                    במקרה שבו הייתה אפשרות לשימוש בפרט אימות מוגבר כמפורט בסעיף 32 לחוק שירותי תשלום אולם בפועל לא נעשה בו שימוש, וכן במקרה בו השימוש לרעה נעשה על ידי בית
                    העסק עצמו. מובהר כי אם בית העסק יבחר שלא לעשות שימוש באמצעים המהווים פרט אימות מוגבר, או יאפשר עסקאות בלא שימוש בהם, החברה תהא רשאית לחייבו בגין כל
                    סכום שתידרש להשיב ללקוח הקצה או לחברת האשראי הסולקת בשל שימוש לרעה באמצעי התשלום ובגין ההוצאות שנגרמו לה עקב כך.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    16.7<span> </span>פעולת תשלום במסמך חסר- נמסר לבית העסק, כי בהתאם לחוק שירותי תשלום, לקוח הקצה עשוי להתכחש לפעולת תשלום במסמך חסר, ובמקרה שהודיע
                    למנפיקת הכרטיס כי הוא לא ביצע את פעולת התשלום או כי הסכום לחיוב הוגדל בלא הרשאה, הוא זכאי להשבה של סכום החיוב או של ההפרש הנטען על ידו. מובהר כי ככל
                    שפעולת התשלום נעשתה תוך שימוש בפרט אימות מוגבר לא תיחשב הפעולה לפעולת תשלום במסמך חסר. פנה בית העסק לחברה בטענה כי לקוח הקצה אכן ביצע את פעולת התשלום
                    במסמך חסר או שהתחייב גם בסכום ההפרש כאמור, החברה תעביר בהקדם האפשרי את פנייתו לחברת האשראי הסולקת לשם בדיקתה. עבור הטיפול בפניה כאמור תגבה החברה מבית
                    העסק דמי טיפול כפי שיקבעו על ידי החברה, וזאת בין אם נמצא צודק בטענותיו ובין אם לאו. מובהר כי לא תהיה לחברה כל אחריות בגין תוצאות העברת פניית בית העסק
                    לחברת האשראי הסולקת כאמור ואופן הטיפול בפנייה, והיא אינה מתחייבת לעמידת חברת האשראי הסולקת במועד למענה לפנייה ואינה אחראית לכל נזק שיגרם לבית העסק
                    כתוצאה מכך.
                </span>
            </div>

            <div>
                <span>
                    16.8<span> </span>במקרה שהחברה תחויב להשיב סכום כלשהו בשל הכחשת פעולת תשלום במסמך חסר, היא תחייב את בית העסק בסכום אותו חויבה להשיב, בצירוף הוצאות
                    סבירות שנגרמו לה עקב כך.&nbsp; &nbsp;&nbsp;
                </span>
            </div>

            <div>
                <span>
                    16.9<span> </span>החברה רשאית לאסור על בית העסק, במועד ההצטרפות לשירות או בכל מועד לאחר מכן ככל שהדבר נדרש לפי שיקול דעת החברה,&nbsp; לבצע פעולות
                    תשלום, כולן או חלקן (כגון עסקאות מסכום מסוים או בצורת תשלום מסוימת) ללא פרט אימות מוגבר. הפר בית העסק איסור כאמור (לרבות ביצוע העסקאות האמורות ללא פרט
                    אימות מוגבר), ייחשב הדבר כהפרה יסודית של הוראות תקנון זה והחברה תהא רשאית לפעול בין היתר בהתאם לסעיף ‏20 להלן, לרבות, למען הסר ספק, בקשר עם עיכוב
                    העברת תשלומי הלקוחות.
                </span>
            </div>

            <div>
                <span>
                    16.10<span> </span>בית העסק יבצע את העסקה באמצעות המערכות האלקטרוניות המקיימות אחר דרישות החברה, יזין את כל הפרטים הנדרשים וישמור ברשותו את פרטי העסקה
                    כנדרש על ידי החברה, לתקופה שלא תפחת משנה. סופק השירות או המוצר שהוזמן ללקוח הקצה באמצעות הדואר, ישמור בית העסק ברשותו אישור על המשלוח בדואר במשך תקופה
                    שלא תפחת מ-24 (עשרים וארבעה) חודשים ממועד ביצוע העסקה, או 18 (שמונה עשר) חודשים מהתשלום האחרון בקשר אליה, אם מדובר בעסקה בתשלום נדחה או עסקת תשלומים,
                    ויציגו לחברה על פי דרישתה.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    16.11<span> </span>טענות לקוח קצה בדבר שינוי חיוב ללא הרשאה- נמסר לבית העסק כי בהתאם לחוק שירותי תשלום, העסקות נשוא הסכם זה נחשבות להוראות תשלום
                    באמצעות המוטב, וכי בהתאם לכך, במקרה בו לקוח קצה טוען כי בית העסק הגדיל ללא הרשאה את סכום החיוב בגין עסקה, נדרשת השבת ההפרש ללקוח הקצה. בהתאם, החברה
                    רשאית לחייב את בית העסק בכל סכום אותו חויבה להשיב בשל טענות מצד לקוח הקצה בדבר שינוי חיוב ללא הרשאה, וכן בהוצאות שיגרמו לה עקב כך.&nbsp;&nbsp;
                </span>
            </div>

            <div>
                <span>
                    16.12<span> </span>עצירת תשלום או חיוב מכוח הוראות הדין או החלטה של רשות מוסמכת- הייתה מניעה על פי דין או מכוח פסק דין או החלטת ערכאה שיפוטית או החלטת
                    רשות מוסמכת אחרת, לזכות את בית העסק, או שבהתאם להם נדרשה החברה לעצור, לעכב או להפסיק לבצע תשלומי לקוחות לבית העסק, לרבות ביחס לתשלומים מסוימים, או
                    לחייב את בית העסק בסכום העסקה, כולה או חלקה, רשאית החברה לפעול כאמור.
                </span>
            </div>

            <div>
                <span>
                    16.13<span> </span>טענות לקוח קצה בדבר מרמה, הונאה, הטעיה- הודיע לקוח הקצה לבית העסק, לחברה או למנפיקת הכרטיס, כי הוא מבקש שלא לחייבו בסכום עסקה בשל
                    כך שהעסקה או עסקת היסוד נעשתה תוך מרמה ו/או הונאה ו/או הטעיה ו/או תיאור מטעה באופן מהותי של המוצר או השירות שנרכשו, ונראה לחברה, לפי שיקול דעתה
                    הבלעדי, כי על פני הדברים יש בסיס לטענות לקוח הקצה, רשאית החברה שלא לזכות את בית העסק בסכום העסקה, ובמקרה שחשבון בית העסק כבר זוכה בגינה, תהא החברה
                    רשאית לחייב את בית העסק בסכום העסקה או לקזז את סכום העסקה מסכומים המגיעים ו/או שיגיעו לבית העסק מהחברה.&nbsp;&nbsp;
                </span>
            </div>

            <div>
                <span>
                    16.14<span> </span>עצירת תשלום או חיוב מכוח החלטה שיפוטית או החלטה של רשות מוסמכת או ארגון בינלאומי-מבלי לגרוע מהוראות סעיפים ‏16.4 עד ‏16.8 ו-‏16.11
                    עד ‏16.13 לעיל, הייתה מניעה על פי דין או מכוח פסק דין, החלטה או צו של בית משפט או של ערכאה שיפוטית אחרת (להלן: "הצו"), או מכוח החלטה של רשות אשר החברה
                    או חברת האשראי הסולקת כפופה להוראותיה, לזכות את בית העסק, או שמכוח אותו צו או החלטה החברה ו/או חברת האשראי הסולקת נדרשת לעצור, לעכב או להפסיק תשלומים
                    לבית העסק, בכלל או ביחס לתשלומים מסוימים, או לחייב את בית העסק בסכום העסקה, כולה או חלקה, רשאית החברה או חברת האשראי הסולקת לפעול כאמור מכוח הצו או
                    ההחלטה, ובית העסק מתיר לה ומסמיך אותה לעשות כן. האמור יחול גם ביחס לצו או החלטה, שעל פיה נדרשת החברה לזכות את לקוח הקצה, חברת האשראי הסולקת או מנפיקת
                    הכרטיס, לפי העניין, בסכום בו חויבו. אם בית העסק כבר זוכה בסכום כאמור תהיה החברה רשאית לחייב את בית העסק בסכום האמור.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    16.15<span> </span>עצירת תשלום, ביטול תשלום או חיוב מכוח החלטה של חברת האשראי הסולקת ו/או מנפיקת הכרטיס - בכל מקרה בו חברת האשראי הסולקת ו/או מנפיקת
                    הכרטיס יחייבו את החברה בגין תשלום לקוח בהתאם לסמכותם על פי דין, ובכלל זה מכוח עילות הביטול והעצירה שנמנו בסעיפים‏ ‏‏16.4 עד ‏16.8 ו-‏16.11 עד ‏ עד
                    ‏16.14 לעיל (להלן: "עילות ביטול ועצירה"), החברה תהא רשאית לעכב או לבטל את תשלום הלקוח או לחייב את בית העסק בסכום בו חויבה וכן לקזז את הסכום מכל סכום
                    המגיע לבית העסק מהחברה.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    16.16<span> </span>בכל מקרה בו החברה נדרשת על פי הדין או על פי תקנון זה להשיב סכומים ללקוח הקצה, לחברת האשראי הסולקת או למנפיקת הכרטיס, לסרב לבצע
                    תשלום או לעצור פעולת תשלום, להפסיקה או לעכבה, לרבות בגין התקיימות עילות הביטול והעצירה, יחולו בנוסף ההוראות הבאות:
                </span>
            </div>

            <div>
                <span>
                    16.16.1<span> </span>אין בעצירת התשלומים או בהפסקתם או בחיוב בית העסק או לקוח הקצה או מנפיקת הכרטיס או בזיכוי של מי מהם בנסיבות האמורות, כדי לפגוע
                    בזכות או בטענה של בית העסק כלפי לקוח הקצה ולהיפך.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    16.16.2<span> </span>במקרה שבית העסק חולק על טענות לקוח הקצה, עליו לפנות בעניין זה ללקוח הקצה בלבד. מובהר כי בכל מקרה של חילוקי דעות בין בית העסק
                    ללקוח הקצה שבו רשאית החברה להעביר טענות של מי מהצדדים לחברת האשראי הסולקת ו/או מנפיקת הכרטיס או להיפך, לא יהיה בכך כדי להטיל עליה כל חובה לעשות כן או
                    כדי להטיל עליה אחריות בקשר לכך. מובהר כי אין בזכויות החברה לפי סעיף‏ 16 זה כדי להוות הליך שיפוטי, והפעלתן עשויה להיעשות באופן חלקי ו/או לא ממצה. עוד
                    מובהר כי החברה אינה אחראית לבדיקה שתערך על ידי חברת האשראי הסולקת או מנפיקת הכרטיס ביחס לטענות לקוח הקצה ו/או בית העסק.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    16.16.3<span> </span>בכל מקרה בו קיימת לחברה על פי חוק שירותי תשלום או על פי תקנון זה זכות להכריע בשאלה הנוגעת לעצירת תשלומים, הפסקתם, חיוב או זיכוי
                    בית העסק, החברה רשאית (אך אינה חייבת) לפנות לבית המשפט המוסמך בעניין, והיא רשאית לעכב תשלומים ולהחזיקם בידיה עד להחלטת בית המשפט.
                </span>
            </div>

            <div>
                <span>
                    16.16.4<span> </span>בכל מקרה בו ניתן צו שיפוטי בקשר לפעולה שהחברה נקטה כמפורט לעיל, תפעל החברה על פי אותו צו שיפוטי ותהיה פטורה מאחריות לכל פעולה על
                    פי צו כאמור.
                </span>
            </div>

            <div>
                <span>
                    16.16.5<span> </span>בכל מקרה בו רשאית החברה על פי תקנון זה או על פי דין לחייב את בית העסק בסכום מסוים, תהא החברה רשאית לקזז את הסכום האמור מתשלומי
                    הלקוחות שאמורים להיות מועברים לבית העסק, וזאת החל מהחודש שלאחר הביטול או ממועד הזיכוי הקרוב ביותר להעברת תשלומי הלקוחות לבית העסק, לפי המוקדם מבניהם.
                    האמור בסעיף זה מהווה הודעת קיזוז לפי כל דין ובית העסק מסכים בזאת לביצוע הקיזוז כאמור.
                </span>
            </div>

            <div>
                <span>
                    16.16.6<span> </span>במקרה בו אין באפשרותה של החברה לקזז את הסכום כאמור בסעיף ‏16.16.5 לעיל, או במקרה בו בוטל השירות על ידי בית העסק, תהא החברה רשאית
                    לחייב את בית העסק בסכום האמור באמצעות כל אמצעי תשלום הרשום על שם בית העסק בחברה. במקרה בו אין בידה אמצעי תשלום כאמור או שלא עלה בידה לחייבו, מתחייב
                    בית העסק להשיב לחברה את הסכום האמור, בתוך 7 ימים ממועד דרישתה לעשות כן בכתב.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    16.16.7<span> </span>יובהר כי אין באמור לעיל, כדי לגרוע מזכות החברה לשוב ולהעביר תשלום לבית העסק או לזכות את בית העסק בגובה התשלום שקוזז, והכל בכפוף
                    להוראות הדין, להוראות פסק דין או צו שיפוטי או כל רשות מוסמכת.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    16.17<span> </span>למען הסר ספק מובהר כי אין בביטול תשלום, מכל סוג שהוא ומכל עילה שהיא, כדי להוות עילה להחזר עלויות השירות, לרבות עמלת הסליקה, או כל
                    תשלום אחר ששילם בית העסק עבור השירות, ולא יוחזרו עלויות השירות, לרבות עמלת הסליקה, במקרה של ביטול תשלום. על אף האמור, מובהר כי לא תיגבה עמלת סליקה
                    בגין תשלום שבוטל באותו יום עסקים שבו בוצע התשלום, ובלבד שהתשלום לא שודר.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    16.18<span> </span>מבלי לגרוע מן האמור לעיל, החברה תהיה רשאית לחייב את בית העסק בעלויות שירות בגין פעולת ביטול תשלום ו/או זיכוי לקוח קצה שחל לגביה
                    סעיף ‏16 זה. בנוסף, החברה תהא רשאית לחייב את בית העסק בהוצאות הסבירות שייגרמו לה עקב ביצוע פעולת ביטול ו/או זיכוי לקוח הקצה כאמור, לרבות בעלויות
                    התפעול, שעות עבודה וקנסות שחויבה בהן החברה. מובהר כי עלויות השירות ו/או ההוצאות הסבירות כאמור ייגבו מבית העסק באופן המפורט בסעיפים ‏16.16.5 ו-‏16.16.6
                    לעיל.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    16.19<span> </span>מובהר כי במקרה בו בית העסק ידרוש כי ייערך בירור בנוגע לטענת לקוח הקצה לשימוש לרעה באמצעי התשלום- לרבות במקרה שתבוצע הכחשה, ונמצא כי
                    בית העסק לא עשה שימוש בפרט אימות מוגבר לשם מתן הוראת התשלום, יחויב בית העסק בעלות בגין הטיפול בבירור כאמור, כפי שתקבע מעת לעת על ידי החברה, ויחולו
                    בנושא זה הוראות סעיף ‎16.7 לעיל.&nbsp;&nbsp;
                </span>
            </div>

            <div>
                <span>
                    16.20<span> </span>הבהרות בנוגע לשירות 3DSecure ("3DS")
                </span>
            </div>

            <div>
                <span>
                    16.20.1<span> </span>שירות 3DS יפעל רק בכפוף למילוי פרטי כרטיס החיוב המלאים בעת ביצוע התשלום.
                </span>
            </div>

            <div>
                <span>
                    16.20.2<span> </span>מובהר כי עסקה שתבוצע ללא השלמת תהליך אימות מלא של פרטי לקוח הקצה באמצעות שירות 3DS, לא תהיה מוגנת מהכחשת העסקה על ידי לקוח
                    הקצה.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    16.20.3<span> </span>מובהר כי לא ניתן להשתמש בשירות 3DS בעת הקמת תשלומי לקוחות באמצעות הוראות קבע או בעת חיוב חוזר של כרטיס חיוב שפרטיו נשמרו מראש.
                </span>
            </div>

            <div>
                <span>
                    16.20.4<span> </span>מבלי לגרוע מהאמור בתקנון זה מובהר כי בכל מקרה בו שירות 3DS אינו זמין או אינו אפשרי או אינו תקין בעת ביצוע העסקה, ביצוע העסקה ללא
                    אימות פרטי לקוח הקצה באמצעות השירות הינו באחריות בית העסק, והכל כמפורט בסעיף ‏16 זה.&nbsp;&nbsp;
                </span>
            </div>

            <div>
                <span>
                    16.21<span> </span>&nbsp;שיתוף פעולה וסיוע של בית העסק בבדיקת חיובים ועסקאות
                </span>
            </div>

            <div>
                <span>
                    16.21.1.<span> </span>בכל מקרה בו נטען להתקיימות עילה מעילות הביטול והעצירה ו/או אם נדרש לדעת החברה בירור של חיובים או עסקאות לצורך בקרה וניהול
                    סיכונים, ישתף בית העסק פעולה עם החברה בבירור כאמור. מבלי לגרוע מכלליות האמור, בית העסק ימסור לחברה את כל המסמכים והמידע שיש בידו בקשר לעסקת היסוד,
                    לחיוב ולטענות לקוח הקצה, לרבות פרטי קשר של לקוח הקצה, העתקי חשבוניות, תעודות משלוח, הסכמים, תכתובת עם לקוח הקצה, תיעוד ממוחשב וכל מידע או מסמך רלבנטי
                    אחר. בית העסק יעביר את המסמכים והמידע לחברה בהקדם האפשרי.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    16.21.2.<span> </span>בית העסק (לרבות עובדיו, מנהליו והפועלים מטעמו) ימסור לחברה גרסה מלאה ומפורטת של כל העובדות והנסיבות הידועות לו ביחס לטענותיו,
                    טענות לקוח הקצה ולעסקת היסוד. בית העסק יאפשר לנציגי החברה לקבל מידע בעל פה מנציגי בית העסק המכירים את הפרטים הרלבנטיים לעסקת היסוד או לטענות לקוח
                    הקצה.
                </span>
            </div>

            <div>
                <span>
                    16.21.3.<span> </span>לבקשת החברה, יתמוך בית העסק את טענותיו ותשובותיו בתצהיר החתום על ידי המוסמכים מטעמו ו/או על ידי האנשים מטעמו הבקיאים בעובדות
                    הרלבנטיות.
                </span>
            </div>

            <div>
                <span>
                    16.21.4.<span> </span>ככל שבית העסק לא יקיים אחר חובתו על פי סעיף זה, הוא יישא בכל העלויות, ההוצאות והנזקים שיגרמו לחברה בשל אי מסירת מסמכים ומידע
                    כאמור או בשל מסירת מסמכים ומידע חסרים, שגויים או מטעים וזאת מבלי לגרוע מיתר זכויות החברה לפי תקנון זה, לרבות זכותה לעכב את הכספים שבית העסק זכאי להם
                    אשר מוחזקים או יוחזקו אצל החברה לפי סעיף ‏‏20.4 להלן.
                </span>
            </div>
            <div>&nbsp;</div>
            <div>
                <span>
                    <strong>
                        17.<span> </span>סיום השירות
                    </strong>
                </span>
            </div>

            <div>
                <span>
                    17.1<span> </span>ההתקשרות בין החברה לבית העסק תעמוד בתוקף עד שבית העסק ימסור לחברה הודעה על רצונו בסיום השירות כמפורט להלן (להלן: "הודעת סיום").
                </span>
            </div>

            <div>
                <span>
                    17.2<span> </span>הודעת סיום תשלח בידי נציג בית העסק המוסמך לכך לכתובת הדוא״ל של החברה שלהלן support@meshulam.co.il.&nbsp;&nbsp;
                </span>
            </div>

            <div>
                <span>
                    17.3<span> </span>מועד סיום שירות הסליקה יהיה בתום יום העסקים שלאחר מועד מסירת הודעת הסיום, ולאחר מועד זה לא יהיה ניתן לבצע תשלומי לקוחות חדשים (להלן:
                    "מועד סיום שירות הסליקה").&nbsp;
                </span>
            </div>

            <div>
                <span>
                    17.4<span> </span>מועד סיום שירות ניהול החשבון, יהיה בתום 5 ימי עסקים מהמועד שבו השלים בית העסק את הפעולות הדרושות לסגירת החשבון כדלקמן: השלמת ביצוע
                    תשלומי לקוחות ששודרו עד מועד סיום שירות הסליקה, פירעון חובות וחיובי בית העסק לחברה ובירור כל הטענות מכוח עילות הביטול והעצירה (להלן: "מועד סיום שירות
                    ניהול החשבון").&nbsp;
                </span>
            </div>

            <div>
                <span>
                    17.5<span> </span>מובהר כי החל ממועד סיום שירות הסליקה תיחסם בפני בית העסק הגישה לחשבון באמצעות האתר ולא יתאפשר ביצוע תשלומי לקוחות חדשים. לצד זאת,
                    החברה תמשיך ותנפיק לבית העסק דוחות אודות חשבונו, ובית העסק יהיה רשאי לקבל פרטים נוספים אודות חשבונו באמצעות פניה לחברה, כל זאת עד למועד סיום שירות
                    ניהול החשבון.
                </span>
            </div>

            <div>
                <span>
                    17.6<span> </span>בית העסק יהיה אחראי לכל תשלומי הלקוחות שיבוצעו עד למועד סיום שירות הסליקה, ולרבות לתשלום עמלת הסליקה בגינם.
                </span>
            </div>
            <div>&nbsp;</div>
            <div>
                <span>
                    <strong>
                        18.<span> </span>איסור על שימוש בלתי חוקי או אסור&nbsp;
                    </strong>
                </span>
            </div>

            <div>
                <span>
                    18.1<span> </span>המשתמש מתחייב בזאת שלא לעשות בשירות כל שימוש בלתי חוקי או לתכלית בלתי חוקית או כל שימוש האסור לפי התנאים, ההתניות וההצהרות הכלולים
                    בתקנון זה, וכי השימוש בשירות יעשה על ידו רק בהתאם לתנאי תקנון זה.
                </span>
            </div>

            <div>
                <span>
                    18.2<span> </span>מבלי לגרוע מהאמור לעיל, המשתמש מתחייב בזאת לפעול כדלקמן:
                </span>
            </div>

            <div>
                <span>
                    18.2.1<span> </span>להימנע מביצוע כל פעולה בשירות, אשר סותרת את האישור שנמסר לו כמשתמש בשירות, לרבות הפרעה ופריצה לשירות או למסד נתונים או לכל מקור או
                    קטע בשירות שלא אושר לו להיכנס אליו או פענוח שלו, וכן להימנע מגישה שאינה מורשית למערכות או קבצים של החברה;
                </span>
            </div>

            <div>
                <span>
                    18.2.2<span> </span>להימנע מכל פעולה שעלולה להגביל, לעכב או לשבש את השימוש בשירות על ידי משתמשים אחרים בשירות;
                </span>
            </div>

            <div>
                <span>
                    18.2.3<span> </span>להימנע משימוש בשירות לשם ביצוע הימורים בלתי חוקיים, הפצת וירוס מחשב, גישה למערכות או לקבצים ללא אישור או כל שימוש אחר כזה העלול
                    לגרום נזק ו/או שיבוש לשירות ו/או לכל צד שלישי;
                </span>
            </div>

            <div>
                <span>
                    18.2.4<span> </span>להימנע ממשלוח "דואר זבל" למשתמשים אחרים בשירות;
                </span>
            </div>

            <div>
                <span>
                    18.2.5<span> </span>לא להעלות, לפרסם, לשדר או להפיץ בדרך אחרת חומר גס, מגונה, פורנוגרפי, מיני, דברי תועבה או כל חומר אחר שיש בו כדי לפגוע ברגשותיהם של
                    משתמשים אחרים בשירות;
                </span>
            </div>

            <div>
                <span>
                    18.2.6<span> </span>לא להפיץ לשון הרע, השמצה, לעג ודברי שנאה וכן לא לעקוב אחר, להפחיד, לאיים או להציק למשתמשים אחרים בשירות;
                </span>
            </div>

            <div>
                <span>
                    18.2.7<span> </span>לא להעלות או לשדר (או לנסות לעשות כך) קבצים המכילים וירוסים, סוסים טרויאניים, תולעים, פצצות-זמן, canceibots, קבצים פגומים או
                    נתונים, או כל תוכנה דומה שעלולה להזיק לשירות או למחשבים של משתמשים אחרים בשירות;
                </span>
            </div>

            <div>
                <span>
                    18.2.8<span> </span>לא להפר זכות חוזית, זכות קניינית או זכות אחרת של צד שלישי כלשהו (ובכלל זה זכות יוצרים, סימן מסחרי, פטנט, סוד מסחר וזכות לפרטיות)
                    לרבות באמצעות העלאה, שידור, הפצה, או הפיכת כל מידע או חומר לזמין באמצעות השירות;
                </span>
            </div>

            <div>
                <span>
                    18.2.9<span> </span>להימנע מיצירת זהויות שקריות, זהויות מרובות, פתיחת מספר חשבונות, הגדרת חשבון בשמו של מי שאינו בית העסק, שימוש בשמות בוטים או הפרה
                    בדרך אחרת של תנאי השירות של כל יישומי צד שלישי באמצעותם מתבצעת הגישה אל השירות;
                </span>
            </div>

            <div>
                <span>
                    18.2.10<span> </span>לא להיחשף או לעשות שימוש בסיסמאות או במידע פרטי אחר של משתמשים אחרים בשירות לרבות מידע המאפשר זיהוי אישי (אם בטקסט, תמונה או
                    וידאו), זיהוי מסמכים או קבלת נתונים פיננסיים אודותיהם;
                </span>
            </div>

            <div>
                <span>
                    18.2.11<span> </span>לא להעלות או לשדר (או לנסות לעשות כך), ללא הרשאה מפורשת של החברה, כל חומר הפועל כמנגנון מידע פסיבי או אקטיבי, לרבות תבניות
                    גרפיות, פיקסלים, באגים, קבצי cookie או התקנים דומים אחרים;
                </span>
            </div>

            <div>
                <span>
                    18.2.12<span> </span>לא להשתמש באופן לא תקין בערוצי התמיכה או השירות לצורך תלונות כוזבות כנגד החברה;
                </span>
            </div>

            <div>
                <span>
                    18.2.13<span> </span>להימנע מפיתוח, הפצה או הודעה בפומבי בדרך אחרת למשתמשים אחרים אודות תוכנות, תכניות או יישומים על מנת לרמות את השירות;
                </span>
            </div>

            <div>
                <span>
                    18.2.14<span> </span>לא לנצל, להפיץ או להודיע בפומבי על שגיאה, שימוש לרעה או באג אשר נותן יתרון לא מכוון ו/או לא הוגן בשימוש בשירות;
                </span>
            </div>

            <div>
                <span>
                    18.2.15<span> </span>לא להשכיר, להחכיר, למכור, לסחור, להעניק מתנה, או להעביר בכל דרך אחרת את החשבון או גישה לחשבון, לכל אדם ללא הרשאה מפורשת בכתב
                    מהחברה;
                </span>
            </div>

            <div>
                <span>
                    18.2.16<span> </span>לא לעסוק בכל פעילות הונאה ביחס לשיטות התשלום או לעקוב אחר מנגנונים אלו;&nbsp;
                </span>
            </div>

            <div>
                <span>
                    18.2.17<span> </span>להימנע מכל ניסיון להשתמש בשירות דרך שירות שאינו מורשה על-ידי החברה. מובהר כי שימוש כזה יהיה באחריות בית העסק בלבד, וכי החברה אינה
                    נוטלת כל אחריות לשימוש כאמור;
                </span>
            </div>

            <div>
                <span>
                    18.2.18<span> </span>לא להפיץ או לתקשר בדרך אחרת נתונים אודות משתמשים אחרים או כל צד שלישי&nbsp; באמצעות השירות.
                </span>
            </div>

            <div>
                <span>
                    18.2.19<span> </span>לא להפריע ליכולתם של אחרים ליהנות מהשימוש בשירות, לרבות להכביד או לשבש או לסייע להעמסת יתר או הפרעה אחרת של שרתי השירות, או לנקוט
                    פעולות שיש בהן כדי להפריע או להגדיל מהותית את עלות אספקת השירות לצורך הנאת המשתמשים.
                </span>
            </div>
            <div>&nbsp;</div>
            <div>
                <span>
                    <strong>
                        19.<span> </span>הפרעות ושיבושים בפעילות השירות
                    </strong>
                </span>
            </div>

            <div>
                <span>
                    19.1<span> </span>ככלל, השירות זמין במשך 24 שעות ביממה, 7 ימים בשבוע. עם זאת, החברה איננה יכולה להבטיח שירות מתמיד ובלתי פוסק.
                </span>
            </div>

            <div>
                <span>
                    19.2<span> </span>מבלי לגרוע מהוראות תקנון זה, מובהר כי-
                </span>
            </div>

            <div>
                <span>
                    19.2.1<span> </span>החברה לא תישא באחריות לכל נזק או הפסד העשוי להתרחש בשל נסיבות שאינן בשליטתה, לרבות שיבוש או תקלה טכנית במערכת, ובכלל זה עומס יתר;
                </span>
            </div>

            <div>
                <span>
                    19.2.2<span> </span>החברה לא תישא באחריות לכל נזק או הפסד כתוצאה מתקלות או בעיות בציוד השירות ו/או בתוכנה;
                </span>
            </div>

            <div>
                <span>
                    19.2.3<span> </span>החברה אינה אחראית לכל שיבוש במחשב המשתמש או בחיבור שלו לאינטרנט, כמו גם לכל שיבוש בשירותי הגישה לאינטרנט ו/או ברשת האינטרנט, ולא
                    תהיה אחראית לכל שיבוש ברשת האינטרנט המקומית או הבינלאומית או בקווי התקשורת, ולא יהיה בשיבושים ובתקלות האמורים או בכל שגיאה שתגרם בשל כך כדי להקנות
                    למשתמש עילה לביטול השירות ו/או ההתקשרות עם החברה והיא לא תישא בכל אחריות לכל תקלה, שיבוש או שגיאה שייגרמו בשל כך.
                </span>
            </div>

            <div>
                <span>
                    19.3<span> </span>מבלי לגרוע מן האמור לעיל מובהר, כי החברה אינה יכולה להבטיח כי השימוש יפעל ו/או ייתמך באופן מלא בכל מערכות ההפעלה ו/או מוצרי המחשב
                    ו/או המכשירים הניידים, וכי ייתכנו שיבושים בשירות עקב שימוש במכשירים ואו מערכות הפעלה ו/או גרסאות שאינם נתמכים.
                </span>
            </div>

            <div>
                <span>
                    19.4<span> </span>החברה משתמשת בטכנולוגיה של סביבה מתקדמת ומבצעת מאמצים סבירים לאבטח את המידע העובר בין משתמשים לבין שרתי החברה. עם זאת, אין החברה
                    יכולה לאבטח את מערכות המידע והתקשורת אבטחה מושלמת מפני חדירות בלתי מורשות ושימוש אסור. מובהר כי החברה לא תישא בכל אחריות במקרים של גילוי ושימוש במידע
                    הנמסר על ידי המשתמש במהלך השימוש בשירות הנובעים מחדירות בלתי מורשות של צדדים שלישיים לשירות.
                </span>
            </div>

            <div>
                <span>
                    19.5<span> </span>בית העסק מסכים ומאשר כי ידוע לו שחרף אמינות האתר ואמצעי הבקרה השונים בהם נוקטת החברה, ייתכנו, מעת לעת, מקרים שבהם יתבצעו בחשבונו
                    זיכויים או חיובים שגויים או פעולות שגויות (להלן: ״טעויות״). בית העסק מסכים כי בכל מקרה שיתברר לחברה על קיומן של טעויות כאמור, בכל זמן שהוא, החברה תהא
                    רשאית לחייב או לזכות את החשבון, לפי העניין, בסכום הטעות, על מנת לתקנה ולהשיב את המצב לקדמותו.
                </span>
            </div>
            <div>&nbsp;</div>
            <div>
                <span>
                    <strong>
                        20.<span> </span>סעדים בגין הפרת תנאים
                    </strong>
                </span>
            </div>

            <div>
                <span>
                    20.1<span> </span>מבלי לגרוע מיתר זכויותיה לפי תנאי השימוש, החברה תהיה רשאית להפסיק את ההתקשרות עם בית העסק או לחסום אותו, וכן כל בית עסק נוסף בבעלותו
                    או בית עסק הקשור אליו, מלבצע פעולות בחשבון ובאתר, לאחר מתן התראה בכתב 7 ימים מראש, בנסיבות חריגות המצדיקות זאת ובכללן בכל אחד מהמקרים הבאים:&nbsp;
                </span>
            </div>

            <div>
                <span>
                    20.1.1<span> </span>בית העסק מסר לחברה מידע שאינו נכון;&nbsp;
                </span>
            </div>

            <div>
                <span>
                    20.1.2<span> </span>לא ניתן לגבות מבית העסק את עלויות השירות לפי סעיף 12 לעיל, מכל סיבה שהיא.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    20.2<span> </span>מבלי לגרוע מיתר זכויותיה על פי דין ועל פי תנאי השימוש, בהתקיים כל אחד מן המקרים המפורטים להלן, תהא החברה רשאית, לפי שיקול דעתה
                    הבלעדי, לפעול באופן מידי וללא הודעה מראש, למנוע ו/או להגביל את כניסת בית העסק לשירות ו/או לחסום ו/או להגביל ו/או להפסיק את אספקת השירותים לבית העסק,
                    לרבות מניעת הגישה לחשבון או לביצוע תשלום, באופן זמני או קבוע, ו/או להקפיא את הזכויות הנובעות מן השירות לבית העסק ו/או להכפיפן למילוי חובותיו לחברה עקב
                    ההפרה, ו/או לנכות כל סוג של הטבה או זכות שהוענקה לחשבון, או כל חלק ממנה:
                </span>
            </div>

            <div>
                <span>
                    20.2.1<span> </span>בית העסק הפר הוראה מהוראות תנאי שימוש אלו;
                </span>
            </div>

            <div>
                <span>
                    20.2.2<span> </span>בית העסק ביצע שימוש החורג משימוש המותר על פי תנאי שימוש אלה;
                </span>
            </div>

            <div>
                <span>
                    20.2.3<span> </span>בית העסק עושה שימוש בשירות שלא לצורך תחום הפעילות שהוצהר על ידו בעת ההצטרפות לשירות או שיש לחברה חשד סביר שנעשה שימוש כאמור;
                </span>
            </div>

            <div>
                <span>
                    20.2.4<span> </span>בית העסק עלול לגרום להפרעה למשתמשים אחרים;
                </span>
            </div>

            <div>
                <span>
                    20.2.5<span> </span>בית העסק עושה שימוש בשירות באופן המהווה עוולה אזרחית או פלילית;
                </span>
            </div>

            <div>
                <span>
                    20.2.6<span> </span>מעשי בית העסק חורגים מכללי התנהגות סבירים;
                </span>
            </div>

            <div>
                <span>
                    20.2.7<span> </span>נתקבלו תלונות חוזרות מצד לקוחות קצה כנגד בית העסק;
                </span>
            </div>

            <div>
                <span>
                    20.2.8<span> </span>המידע שמסר בית העסק בעת ההצטרפות לשירות היה חלקי או מוטעה, או התגלה מידע אחר אודות בית העסק כאמור בסעיף ‏4.6 לעיל;
                </span>
            </div>

            <div>
                <span>
                    20.2.9<span> </span>אם עלה חשש ממשי כי בית העסק שותף לביצוע מעשי הונאה בכרטיסים באמצעות בית העסק;
                </span>
            </div>

            <div>
                <span>
                    20.2.10<span> </span>במקרה בו עולה חשש ממשי כי בית העסק מבצע מעשי הונאה כלפי לקוחות או משלמים, לרבות במקרה של ריבוי מקרים המהווים עילות ביטול ועצירה;
                </span>
            </div>

            <div>
                <span>
                    20.2.11<span> </span>אם עלה חשש ממשי כי בית העסק מנסה לעקוף את הסכום המרבי הניתן לסליקה עבור עסקה באמצעות פיצול עסקאות בניגוד לאמור בסעיף ‏11.4;
                </span>
            </div>

            <div>
                <span>
                    20.2.12<span> </span>במקרים בו נגרמו לחברה נזקים לרבות תדמיתיים, ובכלל כך כתוצאה משימוש בכרטיסים גנובים או בלתי תקינים או מזויפים או כתוצאה מהתכחשות
                    לקוחות קצה לעסקאות במסמך חסר או דרישותיהם להפסקת החיוב בשל אי קיום עסקה יסוד;
                </span>
            </div>

            <div>
                <span>
                    20.2.13<span> </span>במקרה בו בית העסק ביצע עסקה החורגת מהרשאות אשר הוענקו לו על ידי החברה לבצעה;&nbsp;
                </span>
            </div>

            <div>
                <span>
                    20.2.14<span> </span>במקרה בו הפר בית העסק איזה מהחובות המוטלות עליו, ככל שמוטלות, על פי חוק איסור הלבנת הון או כל הוראת דין, חוק או חיקוק שתבוא
                    במקומו או פעל באופן שעלול לגרום להפרה של החובות המוטלות על החברה מכוח חוק איסור הלבנת הון;
                </span>
            </div>

            <div>
                <span>
                    20.2.15<span> </span>אם בוצעו בבית העסק פעולות או עסקאות המעלות חשש ממשי למרמה, הונאה, הלבנת הון, מימון טרור או פעולה בלתי חוקית אחרת או שהתקיימו
                    אינדיקציות לדעת החברה כי התקשרות עם בית העסק מהווה בסיס לביצוע עבירות הלבנת הון ו/או מימון טרור, ולא עלה בידי בית העסק להמציא מסמכים ומידע הנותנים
                    מענה לאינדיקציות האמורות ומזימים את החשש העולה מהם;
                </span>
            </div>

            <div>
                <span>
                    20.2.16<span> </span>במקרה בו בית העסק התבקש להעביר מסמכים ומידע המיועדים לאפשר לחברה לעמוד בחובות המוטלות עליה בקשר למניעת הלבנת הון ומימון טרור,
                    לרבות בהתאם לדרישות הדין, הוראה ו/או הנחיה ו/או נוהל של גוף מאסדר ולנהלי ומדיניות החברה בתחום מניעת הלבנת הון ומימון טרור, ו/או מסמכים ומידע הדרושים
                    לחברה על מנת לוודא כי פעילות הלקוח או עסקה שעשה אינם מעוררים חשש להלבנת הון או למימון טרור, ובית העסק לא העביר את אותם מסמכים;
                </span>
            </div>

            <div>
                <span>
                    20.2.17<span> </span>במקרה שבית העסק קיבל החלטה בדבר שינוי מבנה או מיזוג או שינוי בעלות או שליטה;
                </span>
            </div>

            <div>
                <span>
                    20.2.18<span> </span>במקרה של מוות או פסילת דין, שיש בו כדי להשפיע לרעה על יכולתה של החברה לאכוף את ההסכם או להגן על זכויותיה לפיו;
                </span>
            </div>

            <div>
                <span>
                    20.2.19<span> </span>במקרה שבו הוגשה בקשה למעצר או מאסר של מי מבעלי בית העסק או שמי מבעלי בית העסק הורשע ו/או נחקר ו/או הוגש נגדו כתב אישום בעבירה
                    חמורה – זולת אם חלפה תקופת ההתיישנות לפי חוק המרשם הפלילי ותקנות השבים, התשמ"א-1981. במידה והבעלים הינו תאגיד - נדרש כי העדר הרשעה כאמור תתקיים גם
                    לגבי בעלי השליטה בו ונושאי המשרה בו, והכל – אם לפי שיקול דעתה של החברה, ההרשעה, החקירה או האישום עלולים להשפיע לרעה על יכולתה של החברה לאכוף את ההסכם
                    או להגן על זכויותיה;
                </span>
            </div>

            <div>
                <span>
                    20.2.20<span> </span>אם יוטל עיקול על נכסי בית העסק או על חלק מהותי מהם או אם תיעשה פעולה של הוצאה לפועל נגדם, ובלבד שלא בוטלו בתוך 45 ימים;
                </span>
            </div>

            <div>
                <span>
                    20.2.21<span> </span>אם יחלו כנגד בית העסק הליכי חדלות פירעון או אם תוגש נגד בית העסק או נגד חלק מהותי מנכסיו בקשה למינוי כונס נכסים או מנהל מיוחד;
                </span>
            </div>

            <div>
                <span>
                    20.2.22<span> </span>במקרה שתירשם על ידי רואה חשבון הערת עסק חי בדוחות הכספיים של בית העסק;
                </span>
            </div>

            <div>
                <span>
                    20.2.23<span> </span>במקרה שנמחק או אמור להימחק שם בית העסק מפנקס כלשהוא המתנהל על פי דין, או שיפקע או יבוטל רישיון או היתר הדרושים לבית העסק לצורך
                    פעילותו;
                </span>
            </div>

            <div>
                <span>
                    20.2.24<span> </span>אם יופסקו הפעילות העסקית או עסקי המסחר של בית העסק ולא יחודשו תוך 14 יום מאותה ההפסקה;
                </span>
            </div>

            <div>
                <span>
                    20.2.25<span> </span>אם חשבון בית העסק לא היה פעיל במשך שישה חודשים רצופים. לעניין זה ייחשב בין היתר אי ביצוע עסקאות חדשות במשך 6 חודשים כחשבון לא
                    פעיל.
                </span>
            </div>

            <div>
                <span>
                    20.2.26<span> </span>בכל מקרה בו הפר בית העסק מצג או התחייבות שניתנו טרם הצטרפותו של בית העסק לשירות נשוא הסכם זה, כפי שיעודכן על ידו, או בכל מקרה בו
                    בית העסק הפר מצג או התחייבות במהלך תקופת הסכם זה, לרבות במקרה שבו בית העסק לא עדכן את החברה בדבר שינויים במצגים, הצהרות ומידע שמסר לחברה.
                </span>
            </div>

            <div>
                <span>
                    20.3<span> </span>מובהר כי החברה רשאית להפעיל את הסעדים המפורטים בסעיף ‏20.2 לעיל גם כלפי כל בית עסק נוסף בבעלות בית העסק או בית עסק הקשור אליו.
                </span>
            </div>

            <div>
                <span>
                    20.4<span> </span>&nbsp;מבלי לגרוע מן האמור לעיל, מובהר כי לחברה מוקנית זכות עיכבון על כל הכספים שבית העסק זכאי להם אשר מוחזקים או יוחזקו אצל החברה.
                    החברה תהיה רשאית לעכב העברה של כל תשלום לבית העסק ובכלל זה תשלומי לקוחות, וזאת עד לקיום כל התחייבויות בית העסק כלפיה, ולרבות אם טרם הגיע מועד קיומם של
                    ההתחייבויות אך קיים לדעת החברה חשש ממשי כי יופרו. כמו כן, החברה תהיה רשאית לעכב את הכספים המגיעים לבית העסק בקרות כל אחד מהמקרים המצוינים בסעיפים
                    ‏‏‏20.1&nbsp; ו-‏20.2, עד למועד בו, לפי שיקול דעת החברה, לא קיימת חשיפה לחברה.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    20.5<span> </span>מבלי לגרוע מזכויותיה לפי תקנון זה, מובהר כי החברה זכאית לקזז מכל סכום אשר יגיע לבית העסק בשל כל התחייבות אשר יתחייב כנגד החברה ללא
                    צורך בהתראה, והכל בין אם הגיע מועד פירעונם&nbsp; של איזה מהסכומים האמורים ובין אם לאו, ובכלל זה לפי הוראות סעיף ‏‏16.16.5 לעיל.
                </span>
            </div>

            <div>
                <span>
                    20.6<span> </span>החברה תודיע לבית העסק על הפעלת זכויות העיכבון או הקיזוז כאמור לעיל, בסמוך לאחר הפעלתן.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    20.7<span> </span>מובהר כי במקרה של סיום חשבון עקב הנסיבות המתוארות לעיל, בית העסק עלול לאבד את שם המשתמש ואת שאר ההגדרות שלו בשירות, ואולם, החברה לא
                    תישא באחריות לכל נזק העלול להיגרם בשל כך. הוקצה לבית העסק יותר מחשבון אחד, החברה עשויה להחליט על סיום כל החשבונות הרשומים על שמו.
                </span>
            </div>

            <div>
                <span>
                    20.8<span> </span>בית העסק מצהיר כי ידוע לו כי במקרה של השעיה או ביטול של זכויות המשויכות לחשבון או סיום החשבון, בית העסק לא יוכל לעשות שימוש בשירות
                    ללא הרשאה מפורשת מהחברה. החברה שומרת לעצמה את הזכות לסרב לשמור על חשבונות שבוטלו או לספק גישה לשירות.
                </span>
            </div>

            <div>
                <span>
                    20.9<span> </span>סבר בית העסק כי ננקטה כנגד החשבון שלו פעולה בשגגה, ביכולתו לפנות בעניין לחברה באמצעות כתובת הדוא״ל שלהלן: support@meshulam.co.il.
                </span>
            </div>

            <div>
                <span>
                    20.10<span> </span>מובהר כי בית עסק אשר יורחק מהשירות והיחסים עמו ינותקו כתוצאה ממעשה או מחדל שהקימו עילה לכך, לא יהא רשאי לחזור ולהשתמש בשירות גם תחת
                    שם משתמש אחר. האמור יחול גם לגבי שימוש חדש בשירות על ידי בית עסק אחר בבעלותו או בית עסק הקשור אליו, ומובהר כי גם לגבי בתי עסק כאמור תהיה החברה רשאית
                    לסרב לפתוח חשבון בשירות.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    20.11<span> </span>בית העסק מצהיר כי ידוע לו כי מוטלת עליו מלוא האחריות הנובעת מהעלאת פרטים לשירות, או מביצוע הגדרות מסוימות בשירות, וכי לחברה לא תהיה
                    כל אחריות בעניין, והוא מתחייב בזאת לשפות ולפצות את החברה בגין כל טענה, דרישה ו/או תביעה שתוגש נגדה בקשר לפרטים או להגדרות שיועלו או יבוצעו על ידו
                    באתר.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    20.12<span> </span>מבלי לגרוע מהוראות תקנון זה ומיתר זכויות החברה, מובהר כי בכל מקרה בו החברה פעלה לפי תנאי שימוש אלו לעיכוב תשלומי לקוחות ו/או לכל
                    טיפול חריג בפעילות בית העסק בחשבון, לרבות בשל ביטול עסקאות או פעילות בחשבון שאיננה בהתאם להצהרות שניתנו על ידי בית העסק בעת הצטרפותו לשירות ו/או עקב
                    הפרה אחרת של תנאי שימוש אלו, רשאית החברה, על פי שיקול דעתה הסביר, לגבות מבית העסק דמי טיפול בהתאם להוצאות שנגרמו לחברה בפועל בגין האמור לעיל וכן שכר
                    טרחת עורך דין, ולקזזם מתשלומי הלקוחות שיועברו לבית העסק.&nbsp;&nbsp;
                </span>
            </div>
            <div>&nbsp;</div>
            <div>
                <span>
                    <strong>
                        21<span> </span>הגבלת אחריות
                    </strong>
                </span>
            </div>

            <div>
                <span>
                    21.1<span> </span>מבלי לגרוע מהוראות תקנון זה ובכפוף להוראות כל דין, מובהר כי החברה ו/או ספקיה השונים לא יהיו אחראים לכל נזק, ישיר או עקיף, שעלול
                    להיגרם למשתמש או לכל צד שלישי כלשהו כתוצאה מהשימוש בשירות זה (ובכלל זה לנזק אקראי, מיוחד או תוצאתי, או כל נזק אחר מכל סוג שהוא, לרבות נזק פיצויים בגין
                    נזקי גוף, אובדן הכנסות, הפסקת עסקים, אובדן נתונים או אובדן מידע עסקי כלשהו), לרבות בשל מעשים או מחדלים של צדדים שלישיים, אירועי כוח עליון ועוד. כמו
                    כן, החברה ו/או ספקיה השונים לא יהיו אחראים לכל נזק או הוצאה שיגרמו בגין פעילותה לפי תנאי שימוש אלו בקשר עם סירוב, עצירה, עיכוב או ביטול תשלומי לקוחות,
                    או לכל טיפול חריג בפעילות בית העסק בחשבון, זאת בכפוף להוראות כל דין.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    21.2<span> </span>מבלי לגרוע מהוראות תקנון זה, מובהר כי החברה אינה צד לעסקת היסוד בין בית העסק ללקוח הקצה, ואינה אחראית למעשה או מחדל של בית העסק,
                    לרבות ביחס לטיב המוצר או השירות, מועד האספקה, מחירו, תנאי המכירה, מצגי בית העסק, עמידתו בדרישות הדין וכיוצא באלה. ככל שתוגש כנגד החברה תביעה בגין מעשה
                    או מחדל של בית העסק כאמור, מתחייב בית העסק לשפות את החברה בגין תביעה כאמור, ולרבות בגין כל העלויות, ההוצאות, הנזקים והחבויות שיגרמו לחברה בעטיה.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    21.3<span> </span>מבלי לגרוע מכלליות האמור לעיל ובכפוף להוראות כל דין, הרי שבכל מקרה של מחלוקת ו/או תביעה של בית העסק כנגד החברה ו/או מי מטעמה, מכל
                    עילה שהיא,&nbsp; תהיה אחריותה של החברה ו/או מי מטעמה, והסעד היחיד לו יהיה זכאי בית העסק, מוגבלים עד לסכום עמלת הסליקה ששולמה על ידו בשלושת החודשים
                    שקדמו למחלוקת ו/או לתביעה, לפי העניין.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    21.4<span> </span>מבלי לגרוע מן האמור לעיל, מובהר כי כל דרישה ו/או טענה ו/או תביעה הקשורה לשירות שלא הוגשה בתוך שנה אחת ממועד יצירת העילה לדרישה ו/או
                    טענה ו/או תביעה כאמור, תהיה בטלה.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    21.5<span> </span>מודגש כי לעניין סעיף זה, תחשב ״החברה״ כהגדרתה בסעיף ‏1.2 לעיל, לרבות עובדיה, נציגיה וכל מי שפועל מטעמה.
                </span>
            </div>

            <div>
                <span>
                    21.6<span> </span>שימוש בשירות מהווה הצהרה כי מגבלת האחריות המפורטת בסעיף זה ידועה למשתמש ומוסכמת עליו, וכי ידוע לו כי השימוש בשירות מוצע לו בכפוף לה.
                </span>
            </div>

            <div>
                <span>
                    22<span> </span>תיקון המראה, התוכן, או אופי השירות
                </span>
            </div>

            <div>
                <span>
                    22.1<span> </span>החברה ו/או ספקיה השונים רשאים לבצע בכל עת שיפורים ו/או תיקונים בשירות, לרבות שינוי בזמינות ובהיקף של תוכן, קישורים או באנרים.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    22.2<span> </span>לבית העסק לא תהיה טענה, תביעה או דרישה לחברה ו/או ספקיה בקשר לביצוע שינויים כאמור לעיל, ו/או בקשר לנזק ו/או תקלות אשר עלולות להיגרם
                    כתוצאה מהשינויים לרבות מתהליך הביצוע שלהם.
                </span>
            </div>
            <div>&nbsp;</div>
            <div>
                <span>
                    <strong>
                        23.<span> </span>כללי
                    </strong>
                </span>
            </div>

            <div>
                <span>
                    23.1<span> </span>החברה רשאית להמחות את זכויותיה והתחייבויותיה בשירות לפי שיקול דעתה הבלעדי, במלואן או בחלקן.
                </span>
            </div>

            <div>
                <span>
                    23.2<span> </span>על אף האמור בתנאי שימוש אלה, עלה מחזור המכירות השנתי של בית העסק על 30 מיליון ש"ח בשנה או על סכום אחר שיקבע מעת לעת לפי סעיף 51(ב)
                    לחוק שירותי תשלום, לא יחולו ביחס לבית העסק הזכויות המוקנות למוטב מכוח החוק, למעט כמפורט בסעיף 51(ב) לחוק, ויחולו לגביו התנאים שימסרו לו במועד
                    ההצטרפות, כפי שיוסכמו בכתב על ידי בית העסק והחברה.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    23.3<span> </span>מבלי לגרוע מהוראות סעיפים ‏‏20.1‏ ו- 20.2 לעיל, החברה רשאית על פי שיקול דעתה הבלעדי להפסיק את ההתקשרות עם בית העסק בכל עת, בהודעה
                    בכתב שתישלח לבית העסק 45 ימים מראש. לעניין מועדי סיום השירות יחולו סעיפים ‏17.3 ו-‏17.4 שלעיל.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    23.4<span> </span>דיווח לבית העסק. החברה תעביר לבית העסק דיווח אודות תשלומי הלקוחות שבוצעו באמצעות השירות בתדירות חודשית לפחות (או בתדירות אחרת, כפי
                    שיוחלט על ידי החברה על פי שיקול דעתה ובכפוף להוראות הדין). החברה תפרט במסגרת הדיווח את סכום תשלומי הלקוחות שנגבו עבור בית העסק בתקופה המדווחת ואת
                    העלויות המנוכות ממנו.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    23.5<span> </span>על תקנון זה יחולו דיני מדינת ישראל. סמכות השיפוט בקשר עם התקנון וכל העניינים הנובעים ממנו מוקנית לבתי המשפט המוסמכים בתל אביב-יפו
                    ולאלה בלבד.
                </span>
            </div>

            <div>
                <span>
                    23.6<span> </span>מובהר ומוסכם כי רישומי החברה יהוו ראיה לכאורה בכל מקרה של מחלוקת בקשר לשירות ו/או למימוש תנאי שימוש אלו.
                </span>
            </div>

            <div>
                <span>
                    23.7<span> </span>תנאי שימוש אלו מהווים ההסכם השלם והמלא בין המשתמש ובין החברה ביחס לשירות. מובהר כי במקרה של סתירה או כל סוג של חוסר התאמה בין התנאים
                    המפורטים בתנאי שימוש אלו ובין כל פרסום אחר, בכתב, בעל פה או בכל צורה אחרת בנוגע לשירות, יגברו הוראות תנאי שימוש אלה.
                </span>
            </div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>
                <span>
                    <strong>תמצית התנאים המהותיים</strong>
                </span>
            </div>

            <div>
                <span>&nbsp;בהתאם לסעיף 7(ב) לחוק שירותי תשלום, התשע"ט-2019&nbsp;</span>
            </div>

            <div>
                <span>להלן תיאור תמציתי בלבד של עיקרי התקנון. בכל מקרה של סתירה בין תמצית זו לבין ההסכם, יגברו הוראות ההסכם.</span>
            </div>
            <div>&nbsp;</div>
            <div>
                <span>
                    1.<span> </span>זהות נותן השירות: משולם פתרונות תשלום בע״מ (להלן: "החברה").
                </span>
            </div>

            <div>
                <span>
                    2.<span> </span>מהות השירות: ביצוע תשלומים מאובטחים באינטרנט ו/או בכל יישום או אפליקציה ו/או באמצעות מסוף פיזי, בדרך של מתן שירותי מאגד פיננסי המרכז
                    חיובים וזיכויים של בתי עסק המבוצעים באמצעות כרטיסי חיוב או אמצעי תשלום אחרים, וכן שירותים טכנולוגיים ופיננסיים אחרים המסופקים על ידי החברה.
                </span>
            </div>

            <div>
                <span>
                    3.<span> </span>תנאי השירות:
                </span>
            </div>

            <div>
                <span>
                    •<span> </span>החברה תספק לבית העסק חשבון בשירות, באמצעותו יוכל לגבות תשלומי לקוחות המבוצעים באמצעות אמצעי תשלום.&nbsp;&nbsp;
                </span>
            </div>

            <div>
                <span>
                    •<span> </span>החברה תהא רשאית לסרב לבצע תשלומים או לבטלם, וכן להשיב את התשלום ללקוח הקצה או לקזז את התשלום מבית העסק ככל שחויבה בו, הכול בהתאם
                    לנסיבות המפורטות בתקנון, ובכלל זה בשל חריגה מהוראות התקנון, הפרת הוראות הדין, חשד להונאה או מרמה או הלבנת הון.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    4.<span> </span>מועדי חיובים וזיכויים: החברה תזכה את בית העסק בסכומי תשלומי הלקוחות במועד זיכוי שבועי&nbsp; או חודשי.&nbsp;&nbsp;
                </span>
            </div>

            <div>
                <span>
                    5.<span> </span>הכחשה וביטול עסקאות:&nbsp;
                </span>
            </div>

            <div>
                <span>
                    •<span> </span>בהתאם לדין וכמפורט בתקנון, בנסיבות מסוימות זכאים לקוחות הקצה לבטל עסקאות או למנוע המשך תשלומים בגינן ועשויים להיות זכאיים להחזר כספי,
                    לרבות בשל אי אספקת מוצר, שימוש לרעה באמצעי התשלום, הכחשת עסקה במסמך חסר, שינוי חיוב ללא הרשאה, ביטול הרשאה לחיוב או עסקה שנעשתה תוך מרמה, הונאה או
                    הטעיה.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    •<span> </span>בית העסק רשאי לבטל באמצעות השירות תשלומי לקוחות בהתאם להוראת הקבועות בתקנון.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    6.<span> </span>עלות השירות - עמלות ותשלומים:&nbsp;
                </span>
            </div>

            <div>
                <span>
                    •<span> </span>בית העסק ישלם עמלת סליקה בגין כל תשלום לקוח שיבוצע באמצעות השירות, אשר תנוכה מסכומי תשלומי הלקוחות שיועברו לבית העסק.&nbsp;
                </span>
            </div>

            <div>
                <span>
                    •<span> </span>בנוסף, החברה רשאית לקבוע, מעת לעת, תשלומים נוספים בגין השימוש בשירות, לרבות בגין פתיחת חשבון בשירות וכן תשלומים בגין שירותים נוספים
                    המסופקים על ידי החברה.
                </span>
            </div>

            <div>
                <span>
                    •<span> </span>תעריפי עמלת הסליקה ועלויות השירות הנוספות יהיו כמפורט באתר החברה בכתובת www.meshulam.biz, למעט אם הוסכם עם בית העסק אחרת בכתב.
                </span>
            </div>

            <div>
                <span>
                    7.<span> </span>דרישות תפעוליות: השימוש בשירות יעשה בכפוף לתנאי התקנון, כפי שיעודכנו מעת לעת, לרבות לעניין הנתונים שנדרש להזין כתנאי לשירות, ביצוע
                    התשלומים, אופן זיהוי הלקוחות, דרישת קבלת חתימה או אישור אחר מאת לקוח הקצה, שמירת מסמכים, אופן הפעלת מסופי סליקה פיזיים, שימוש בפרט אימות מוגבר וכיוצא
                    באלה.
                </span>
            </div>

            <div>
                <span>
                    8.<span> </span>תקופת ההתקשרות/ סיום התקשרות: ההתקשרות תחל במועד אישורה על ידי החברה, ותימשך עד להחלטת מי הצדדים לסיים את ההתקשרות, כמפורט בתקנון.
                </span>
            </div>

            <div>
                <span>
                    •<span> </span>סיום ביוזמת בית העסק: בית העסק רשאי לסיים את ההתקשרות בכל עת, בהודעה בכתב לחברה. סליקת עסקאות חדשות תופסק בתוך יום עסקים ממועד הודעת
                    הסיום. סיום ניהול החשבון בשירות יבוצע בתום 5 ימי עסקים מהמועד שבו השלים בית העסק את הפעולות הדרושות לסגירת החשבון, כפי שנמסרו לו על ידי החברה.
                </span>
            </div>

            <div>
                <span>
                    •<span> </span>סיום ביוזמת החברה: החברה רשאית לסיים את ההתקשרות לפי שיקול דעתה הבלעדי, בהודעה בכתב לבית העסק, 45 ימים מראש. בנסיבות חריגות המפורטות
                    בתקנון, תורשה החברה לסיים את ההתקשרות באופן מידי.
                </span>
            </div>

            <div>
                <span>
                    9.<span> </span>אחריות לשירות: אחריות החברה לשירות מוגבלת אך ורק לעניינים שבשליטתה. החברה ו/או ספקיה השונים לא יהיו אחראים לכל נזק, ישיר או עקיף,
                    שעלול להיגרם למשתמש או לכל צד שלישי כלשהו כתוצאה מהשימוש בשירות. כמו כן, החברה ו/או ספקיה השונים לא יהיו אחראים לכל נזק או הוצאה שיגרמו בגין פעילותה
                    לפי התקנון בקשר עם סירוב, עצירה, עיכוב או ביטול תשלומי לקוחות, או לכל טיפול חריג בפעילות בית העסק בחשבון, זאת בכפוף להוראות כל דין. מבלי לגרוע מכלליות
                    האמור לעיל ובכפוף להוראות כל דין, אחריותה של החברה ו/או מי מטעמה, והסעד היחיד לו יהיה זכאי בית העסק, מוגבלים עד לסכום עמלת הסליקה ששולמה על ידי בית
                    העסק בשלושת החודשים שקדמו למחלוקת ו/או לתביעה, לפי העניין.&nbsp;
                </span>
            </div>
        </div>
    );
};

export default TermsOfUse;
