import { isValidationPathAsCurrent } from "app/functions";
import { validateRegistrationData } from "constants/data-validation";
import { useSelector } from "react-redux";

const useValidate = (validationObj) => {
    const { defaultFormData, formData, setFormData, location } = validationObj;
    const signupForm = useSelector((store) => store.signupForm);
    const loaderState = useSelector((store) => store.loaderState);

    const validateForm = (isEntireForm = true) => {
        // * IF LOADER IS ON => RETURN FALSE IN ORDER TO BLOCK CONTINUING TO NEXT STEP
        if (loaderState) {
            return false;
        }

        let validationResult = validateRegistrationData(signupForm, location, isEntireForm);

        // * CHECK WETHER THE VALIDATION PATH IS SAME AS CURRENT
        if (isValidationPathAsCurrent(validationResult, location)) {
            let newState = { ...formData };

            for (let input in formData) {
                // * LOOPS THROUGH THE FORM OBJ AND CHECKS IF INPUT HAS AN ERROR
                if (validationResult.failedInputs[input]) {
                    let validationError = validationResult.failedInputs[input];
                    newState[input].isValid = validationError;
                } else {
                    newState[input].isValid = {};
                }
            }

            setFormData(newState);
        } else {
            // * IF FORM HAS NO ERRORS - SET IT BACK TO DEFAULT
            setFormData(defaultFormData);
            return validationResult;
        }
    };

    return validateForm;
};

export default useValidate;
