import Api from "api/requests";
import { generateDefaultFormData, isNumber, navigateToNextStep } from "app/functions";
import useApiError from "app/hooks/useApiError";
import useTranslate from "app/hooks/useTranslate";
import useValidate from "app/hooks/useValidate";
import closeIcon from "assets/images/close-icon.svg";
import signaturePen from "assets/images/signature-pen.svg";
import AutoComplete from "components/forms/autocomplete/highlighted-query";
import Button from "components/forms/button";
import RadioButton from "components/forms/radio-button";
import TextInput from "components/forms/textInput";
import HeaderTitle from "components/header_title";
import { BOOLEAN_VALUES, BUSINESS_INFO_ADDRESS, DELAY_DEBOUNCE } from "constants/input-fields";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Actions from "redux/actions";
import "./index.scss";

const MIN_CHARS_QUERY_FALLBACK = 2;
const SEARCH_DEBOUNCE = 500; // * HOW LONG TO WAIT BEFORE EXECUTING A NEW SEARCH CALL - IN MS

const BusinessAddress = ({ fields, location }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const signupForm = useSelector((store) => store.signupForm);
    const gdParams = useSelector((store) => store.gd.ErrorsLangParam.parameters);
    const translate = useTranslate();
    const handleServerError = useApiError();

    // minimum characters to send a request for available addresses
    const minCharsForQuery = parseInt(gdParams.business_address_min_chars_query ?? MIN_CHARS_QUERY_FALLBACK);

    let defaultFormData = generateDefaultFormData(fields);
    defaultFormData = {
        ...defaultFormData,
        [fields.businessAddress.name]: {
            addressNotFound: {
                text: translate(fields.businessAddress.addressNotFound.text),
                inputName: fields.businessAddress.addressNotFound.name,
            },
            inputKey: "business_address",
        },
    };

    const [isManualAddress, setIsManualAddress] = useState(false);
    const [firstTry, setFirstTry] = useState(true);
    const [formData, setFormData] = useState(defaultFormData);
    const validationObj = { defaultFormData, formData, setFormData, location };
    const validateForm = useValidate(validationObj);

    const [didStartRequest, setDidStartRequest] = useState(false);
    const [addressOptions, setAddressOptions] = useState([]);
    const [addressTimeout, setAddressTimeout] = useState(null);

    useEffect(() => {
        const isAddressValid = BUSINESS_INFO_ADDRESS.businessAddress.validationRules?.[0]().isValid;

        if (isAddressValid) {
            const combinedAddressName = buildAddressName(signupForm);
            dispatch(Actions.updateSignupForm({ [fields.businessAddress.name]: combinedAddressName }));
        }
    }, []);

    useEffect(() => {
        const delayDebounce = setTimeout(() => {
            validateForm(false);
        }, DELAY_DEBOUNCE);

        return () => clearTimeout(delayDebounce);
    }, [signupForm]);

    useEffect(() => {
        if (isManualAddress) {
            dispatch(Actions.updateSignupForm({ [fields.isManualAddress.key]: BOOLEAN_VALUES.true }));
            setFirstTry(true);
        }
    }, [isManualAddress]);

    const handleInputChange = (e) => {
        let type = e?.target?.type;
        let name = e.target.name;
        let val = e.target.value;
        let key = formData[name]?.inputKey;

        if (type === "autocomplete") {
            let addressText = e.data.text;
            let addressComponents = e.data.comps;

            key = fields.businessAddress.key;
            val = addressText;
            dispatch(Actions.updateSignupForm({ ...addressComponents }));
        }

        // * IF KEYBOARD IS SET TO ACCEPT NUMBERS ONLY => DON'T ACCEPT OTHER VALUES
        if (formData[name]?.keyboardType === "tel" && !isNumber(val) && val?.length > 0) {
            return;
        } else if (val === "") {
            if (name === fields.businessAddress.name) {
                const addressKeysToRemove = Object.values(BUSINESS_INFO_ADDRESS.businessAddress.requiredKeys);
                dispatch(Actions.removeFromSignupForm(addressKeysToRemove));
                setAddressOptions([]);
            }

            dispatch(Actions.removeFromSignupForm([key]));
            return;
        }

        if (name === fields.businessSellingType.name) {
            val = parseInt(val);
        } else if (name === fields.businessAddress.name) {
            clearTimeout(addressTimeout);
            setDidStartRequest(true);

            const newAddressTimeout = setTimeout(() => {
                getAddressList(val);
            }, SEARCH_DEBOUNCE);
            setAddressTimeout(newAddressTimeout);

            const addressKeysToRemove = Object.values(BUSINESS_INFO_ADDRESS.businessAddress.requiredKeys);
            dispatch(Actions.removeFromSignupForm(addressKeysToRemove));
            setAddressOptions([]);
        }

        dispatch(Actions.updateSignupForm({ [key]: val }));
    };

    const buildAddressName = (data) => {
        const cityKey = BUSINESS_INFO_ADDRESS.addressCity.key;
        const addressStreetKey = BUSINESS_INFO_ADDRESS.addressStreet.key;
        const addressNumberKey = BUSINESS_INFO_ADDRESS.addressNumber.key;

        const cityValue = data[cityKey];
        const addressStreetValue = data[addressStreetKey];
        const addressNumberValue = String(data[addressNumberKey]);

        return `${addressStreetValue}${addressNumberValue === "0" ? "" : " " + addressNumberValue} ${cityValue}`;
    };

    const getAddressList = (searchValue = "") => {
        if (searchValue.length < minCharsForQuery) {
            return;
        }

        const onSuccess = (response) => {
            if (response.status === 1) {
                setDidStartRequest(false);
                const addressesList = response.data ?? [];
                const newAddressArray = addressesList.map((item, idx) => ({ id: idx, text: buildAddressName(item), comps: item }));
                setAddressOptions(newAddressArray);
            }
        };

        const onFailure = (response) => {
            if (response.status === 0) {
                setDidStartRequest(false);
                setAddressOptions([]);
                handleServerError(response);
            }
        };

        const props = {
            onSuccess,
            onFailure,
            payload: {
                [fields.businessAddress.apiKey]: searchValue,
            },
        };

        Api.getAddresses(props);
    };

    const handleNextButtonClick = (e) => {
        if (e) {
            e.preventDefault();
        }

        setFirstTry(false);

        let validationResult = validateForm(true);

        if (validationResult) {
            let payload;

            payload = {
                [fields.addressCity.key]: signupForm[fields.addressCity.key],
                [fields.addressStreet.key]: signupForm[fields.addressStreet.key],
                [fields.addressNumber.key]: signupForm[fields.addressNumber.key],
                [fields.addressPostalCode.key]: String(signupForm[fields.addressPostalCode.key]),
            };

            upsertAddress(payload, validationResult);
        }
    };

    const upsertAddress = (payload, validationResult) => {
        const onSuccess = (response) => {
            if (response.status === 1) {
                dispatch(Actions.removeFromSignupForm([fields.addressPostalCode.isPostalRequiredKey]));
                navigateToNextStep(validationResult, location, navigate);
            }
        };

        const onFailure = (response) => {
            if (response.status === 0) {
                handleServerError(response);
            }
        };

        const props = {
            onSuccess,
            onFailure,
            payload: {
                ...payload,
                [fields.businessSellingType.key]: signupForm[fields.businessSellingType.key],
            },
        };

        Api.upsertAddress(props);
    };

    const handleManualAddressClose = (e) => {
        if (e) {
            e.preventDefault();
        }

        dispatch(Actions.removeFromSignupForm([fields.isManualAddress.key]));
        setIsManualAddress(false);
        setFirstTry(true);
    };

    const openManualAddress = () => {
        setIsManualAddress(true);
        dispatch(Actions.removeFromSignupForm([fields.businessAddress.name]));
    };

    const addressNotFound = () => {
        const addressSearchQuery = signupForm[fields.businessAddress.key] || "";

        // show 'address not found' option only if no request is on queue and the query is longer then 2 letters
        if (!didStartRequest && addressSearchQuery.length >= minCharsForQuery) {
            return (
                <div className="address-not-found">
                    <span className="not-found-text">{formData[fields.businessAddress.name].addressNotFound.text}</span>

                    <div className="not-found-icon">
                        <img src={signaturePen} alt="" />
                    </div>
                </div>
            );
        }

        return null;
    };

    return (
        <form className="business-info-address-wrapper" onSubmit={handleNextButtonClick}>
            <div className="business-info-address-data">
                <HeaderTitle>{translate(fields.headerTitle.label)}</HeaderTitle>

                {!isManualAddress ? (
                    <AutoComplete
                        className="field"
                        options={addressOptions}
                        query={signupForm[fields.businessAddress.key] || ""}
                        onChange={handleInputChange}
                        onSelect={handleInputChange}
                        value={signupForm[fields.businessAddress.key] || ""}
                        name={fields.businessAddress.name}
                        label={translate(fields.businessAddress.label)}
                        placeholder={translate(fields.businessAddress.placeholder)}
                        showError={!firstTry && formData[fields.businessAddress.name].isValid?.valid === false}
                        errorMessage={formData[fields.businessAddress.name].isValid?.errMsg}
                        lastItemElement={addressNotFound()}
                        lastItemCallback={openManualAddress}
                        noFilter
                        noDropdown
                        alwaysShowResults
                    />
                ) : (
                    <div className="business-info-address-manual-address" onSubmit={handleNextButtonClick}>
                        <button type="button" className="business-info-address-manual-address-close" onClick={handleManualAddressClose}>
                            <img src={closeIcon} alt={translate("close_button_image_alt")} />
                        </button>

                        <TextInput
                            label={translate(fields.addressCity.label)}
                            name={fields.addressCity.name}
                            value={signupForm[fields.addressCity.key] || ""}
                            onChange={handleInputChange}
                            showError={!firstTry && formData[fields.addressCity.name].isValid?.valid === false}
                            errorMessage={formData[fields.addressCity.name].isValid?.errMsg}
                        />

                        <TextInput
                            label={translate(fields.addressStreet.label)}
                            name={fields.addressStreet.name}
                            value={signupForm[fields.addressStreet.key] || ""}
                            onChange={handleInputChange}
                            showError={!firstTry && formData[fields.addressStreet.name].isValid?.valid === false}
                            errorMessage={formData[fields.addressStreet.name].isValid?.errMsg}
                        />

                        <div className="business-info-address-manual-address-wrapper">
                            <TextInput
                                label={translate(fields.addressNumber.label)}
                                name={fields.addressNumber.name}
                                value={signupForm[fields.addressNumber.key] || ""}
                                onChange={handleInputChange}
                                keyboardType={fields.addressNumber.keyboardType}
                                showError={!firstTry && formData[fields.addressNumber.name].isValid?.valid === false}
                                errorMessage={formData[fields.addressNumber.name].isValid?.errMsg}
                            />

                            <TextInput
                                label={translate(fields.addressPostalCode.label)}
                                name={fields.addressPostalCode.name}
                                value={signupForm[fields.addressPostalCode.key] || ""}
                                onChange={handleInputChange}
                                keyboardType={fields.addressPostalCode.keyboardType}
                                showError={!firstTry && formData[fields.addressPostalCode.name].isValid?.valid === false}
                                errorMessage={formData[fields.addressPostalCode.name].isValid?.errMsg}
                            />
                        </div>
                    </div>
                )}

                <RadioButton
                    type={fields.businessSellingType.type}
                    label={translate(fields.businessSellingType.header)}
                    options={fields.businessSellingType.options}
                    name={fields.businessSellingType.name}
                    value={signupForm[fields.businessSellingType.key]}
                    showError={!firstTry && formData[fields.businessSellingType.name].isValid?.valid === false}
                    errorMessage={formData[fields.businessSellingType.name].isValid?.errMsg}
                    onChange={handleInputChange}
                />
            </div>

            <Button isNext={true} onClick={handleNextButtonClick}></Button>
        </form>
    );
};

export default BusinessAddress;
