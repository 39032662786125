import { createSlice } from "@reduxjs/toolkit";

let Slices = [];
let formReducers = {};
let formActions = {};

export const contactSlice = createSlice({
    name: "contactForm",
    initialState: {},
    reducers: {
        updateContactForm: (state, action) => {
            return { ...state, ...action.payload };
        },
        resetContactForm: () => {
            return {};
        },
    },
});
Slices.push(contactSlice);

export const formSlice = createSlice({
    name: "signupForm",
    initialState: {},
    reducers: {
        updateSignupForm: (state, action) => {
            return { ...state, ...action.payload };
        },
        removeFromSignupForm: (state, action) => {
            let newState = { ...state };

            for (let key of action.payload) {
                delete newState[key];
            }

            return newState;
        },
    },
});
Slices.push(formSlice);

export const occupationAreaSlice = createSlice({
    name: "occupationAreaData",
    initialState: {
        autocomplete: {},
        documents: [],
        figure: {},
        services: [],
        validation: {},
        questions: {},
    },
    reducers: {
        setOccupationAreaData: (state, action) => {
            return { ...state, ...action.payload };
        },
        resetOccupationAreaData: () => {
            return {
                autocomplete: {},
                documents: [],
                figure: {},
                services: [],
                validation: {},
                questions: {},
            };
        },
        setAutoCompleteSelection: (state, action) => {
            return { ...state, autocomplete: { ...state.autocomplete, [action.payload.key]: action.payload.id }, services: [...state.services, action.payload.id] };
        },
        removeAutoCompleteSelection: (state, action) => {
            const valueToRemove = state.autocomplete[action.payload];
            const filteredAutocomplete = Object.fromEntries(Object.entries({ ...state.autocomplete }).filter(([key]) => key !== action.payload));
            const filteredServices = [...state.services].filter((service) => service !== valueToRemove);
            const filteredValidations = Object.fromEntries(
                Object.entries({ ...state.validation }).filter((validation) => {
                    return parseInt(validation[0].split("_").filter((part) => parseInt(part) > 0)) !== valueToRemove;
                })
            );

            return { ...state, autocomplete: filteredAutocomplete, services: filteredServices, validation: filteredValidations };
        },
        setOccupationAreaForm: (state, action) => {
            return { ...state, form: { ...state.form, ...action.payload } };
        },
        setOccupationAreaQuestion: (state, action) => {
            return { ...state, questions: { ...state.questions, [action.payload.key]: action.payload.val } };
        },
        setOccupationAreaFigure: (state, action) => {
            return { ...state, figure: { ...state.figure, ...action.payload } };
        },
        setOccupationAreaDocuments: (state, action) => {
            if (state.documents.includes(action.payload)) {
                const filteredArray = state.documents.filter((document) => document.id !== action.payload);
                return { ...state, documents: filteredArray };
            }

            const clonedArray = [...state.documents, action.payload];
            return { ...state, documents: clonedArray };
        },
        removeOccupationAreaDocumentsById: (state, action) => {
            const filteredArray = state.documents.filter((document) => (document?.id ?? document) !== action.payload);
            return { ...state, documents: filteredArray };
        },
        setOccupationAreaServices: (state, action) => {
            if (Array.isArray(action.payload)) {
                return { ...state, services: action.payload };
            }

            if (state.services.includes(action.payload)) {
                const filteredArray = state.services.filter((service) => service !== action.payload);
                return { ...state, services: filteredArray };
            }

            const clonedArray = [...state.services, action.payload];
            return { ...state, services: clonedArray };
        },
        setSubAreasValidations: (state, action) => {
            return { ...state, validation: { ...state.validation, ...action.payload } };
        },
        updateSubAreasValidations: (state, action) => {
            const key = Object.keys(action.payload)[0];
            const value = Object.values(action.payload)[0];

            return {
                ...state,
                validation: {
                    ...state.validation,
                    [key]: { ...state.validation[key], ...value },
                },
            };
        },
        removeSubAreasValidation: (state, action) => {
            const filteredState = Object.fromEntries(Object.entries({ ...state.validation }).filter(([key]) => key !== action.payload));

            return { ...state, validation: filteredState };
        },
    },
});
Slices.push(occupationAreaSlice);

//build export objects
for (const Slice of Slices) {
    formActions = { ...formActions, ...Slice.actions };
    let reducer = { [Slice.name]: Slice.reducer };
    formReducers = { ...formReducers, ...reducer };
}

export { formActions };
export { formReducers };
