import useTranslate from "app/hooks/useTranslate";
import Button from "components/forms/button";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Actions from "redux/actions";
import "./index.scss";

const DocumentPreviewPopup = ({ payload }) => {
    const translate = useTranslate();
    const dispatch = useDispatch();
    const [animationClass, setAnimationClass] = useState("");
    const [fileBase64, setFileBase64] = useState(false);

    const fileType = payload.file.type;
    const isPdfType = fileType === "application/pdf";
    const hidePdfToolbar = isPdfType ? "#toolbar=0" : "";

    let animationFrame;

    useEffect(() => {
        convertToBase64(payload.file);
        animateIn();

        return () => {
            cancelAnimationFrame(animationFrame);
        };
    }, []);

    const animateIn = () => {
        animationFrame = requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                setAnimationClass("active");
            });
        });
    };

    const completeAnimation = () => {
        if (animationClass !== "exit" && animationClass !== "done") {
            setAnimationClass("done");
        }
    };

    /* first remove the active class for exit animation then call the callback and remove the popup */
    const animateOut = () => {
        setAnimationClass("exit");

        setTimeout(() => {
            cancelAnimationFrame(animationFrame);
            dispatch(Actions.removePopup());
        }, 200);
    };

    const convertToBase64 = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64File = reader.result;
            setFileBase64(base64File);
        };
    };

    return (
        <div className={"backdrop document-preview " + animationClass} onClick={animateOut} onTransitionEnd={completeAnimation}>
            <div className={"popup_wrapper " + animationClass} onClick={(e) => e.stopPropagation()}>
                <div className="popup_content">{fileBase64 && <embed className="preview-data" type={fileType} src={`${fileBase64}${hidePdfToolbar}`} />}</div>

                <Button onClick={animateOut}>{translate("upload_other_documents_preview_close_button")}</Button>
            </div>
        </div>
    );
};

export default DocumentPreviewPopup;
