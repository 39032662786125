export const mainRootRoutes = {
    introduction: "introduction",
    otpValidation: "customer-validation",
    businessInfo: "business-info",
    contactInfo: "contact-info",
    occupationAreas: "occupation",
    productInfo: "regarding-product",
    financeInfo: "finance-info",
    uploadDocuments: "documents",
    agreementTerm: "agreement-term",
};