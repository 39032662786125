import { generateDefaultFormData, navigateToNextStep } from "app/functions";
import useTranslate from "app/hooks/useTranslate";
import useValidate from "app/hooks/useValidate";
import Button from "components/forms/button";
import Hint from "components/forms/hint";
import RadioButton from "components/forms/radio-button";
import TextInput from "components/forms/textInput";
import HeaderTitle from "components/header_title";
import { BOOLEAN_VALUES, DELAY_DEBOUNCE } from "constants/input-fields";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Actions from "redux/actions";
import Api from "api/requests";
import "./index.scss";
import useApiError from "app/hooks/useApiError";

const BusinessName = ({ fields, location }) => {
    const defaultFormData = generateDefaultFormData(fields);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const translate = useTranslate();
    const signupForm = useSelector((store) => store.signupForm);
    const params = useSelector((store) => store.gd.ErrorsLangParam.parameters);
    const maxCharsLength = params.number_of_max_name_characters ?? 25;

    const [firstTry, setFirstTry] = useState(true);
    const [formData, setFormData] = useState(defaultFormData);
    const validationObj = { defaultFormData, formData, setFormData, location };
    const validateForm = useValidate(validationObj);
    const handleServerError = useApiError();

    useEffect(() => {
        const delayDebounce = setTimeout(() => {
            validateForm(false);
        }, DELAY_DEBOUNCE);

        return () => clearTimeout(delayDebounce);
    }, [signupForm]);

    const handleInputChange = (e) => {
        let type = e?.target?.type;
        let name = e?.target?.name || e?.name;
        let val = e?.target?.value || e?.value;
        let key = formData[name]?.inputKey;

        if (type === "checkbox") {
            let isChecked = e.target.checked;
            key = e.target.name;

            if (!isChecked) {
                dispatch(Actions.removeFromSignupForm([key]));
                return;
            }

            val = BOOLEAN_VALUES[isChecked];
        } else if (type === "radio") {
            dispatch(Actions.removeFromSignupForm([fields.differentName.key]));
            let isTrue = Number(val) === BOOLEAN_VALUES.true;
            val = BOOLEAN_VALUES[isTrue];
        }

        if (formData[name]?.keyboardType === "tel" && !isNumber(val) && val?.length > 0) {
            return;
        }

        dispatch(Actions.updateSignupForm({ [key]: val }));
    };

    const handleNextButtonClick = (e) => {
        if (e) {
            e.preventDefault();
        }

        setFirstTry(false);

        let validationResult = validateForm(true);

        if (validationResult) {
            const onSuccess = (response) => {
                if (response.status === 1) {
                    navigateToNextStep(validationResult, location, navigate);
                }
            };

            const onFailure = (response) => {
                if (response.status === 0) {
                    handleServerError(response);
                }
            };

            const props = {
                onSuccess,
                onFailure,
                payload: {
                    [fields.businessName.key]: signupForm[fields.businessName.key],
                    [fields.isSameName.key]: signupForm[fields.isSameName.key],
                    [fields.businessNameEnglish.key]: signupForm[fields.businessNameEnglish.key],
                    ...(signupForm[fields.isSameName.key] === BOOLEAN_VALUES.false && { [fields.differentName.key]: signupForm[fields.differentName.key] }),
                },
            };

            Api.updateBusinessName(props);
        }
    };

    return (
        <form className="business-info-name-wrapper" onSubmit={handleNextButtonClick}>
            <div className="business-info-name-data">
                <HeaderTitle>{translate(fields.headerTitle.label)}</HeaderTitle>

                <TextInput
                    placeholder={translate(fields.businessName.placeholder)}
                    name={fields.businessName.name}
                    value={signupForm[fields.businessName.key] || ""}
                    onChange={handleInputChange}
                    showError={!firstTry && formData[fields.businessName.name].isValid?.valid === false}
                    errorMessage={formData[fields.businessName.name].isValid?.errMsg}
                    isShort
                />

                <RadioButton
                    type={fields.isSameName.type}
                    label={translate(fields.isSameName.header)}
                    options={fields.isSameName.options}
                    name={fields.isSameName.name}
                    value={signupForm[fields.isSameName.key]}
                    showError={!firstTry && formData[fields.isSameName.key].isValid?.valid === false}
                    errorMessage={formData[fields.isSameName.key].isValid?.errMsg}
                    onChange={handleInputChange}
                />

                <Hint>
                    <div className="business-info-name-hint-header">{translate(fields.differentName.hintHeader)}</div>
                    <div className="business-info-name-hint-text">{translate(fields.differentName.hintText)}</div>
                </Hint>

                {signupForm[fields.isSameName.key] == BOOLEAN_VALUES.false && (
                    <TextInput
                        label={translate(fields.differentName.label)}
                        placeholder={translate(fields.differentName.placeholder)}
                        name={fields.differentName.name}
                        value={signupForm[fields.differentName.key] || ""}
                        onChange={handleInputChange}
                        showError={!firstTry && formData[fields.differentName.name].isValid?.valid === false}
                        errorMessage={formData[fields.differentName.name].isValid?.errMsg}
                    />
                )}

                <div className="business-info-name-english-wrapper">
                    <TextInput
                        label={translate(fields.businessNameEnglish.label)}
                        placeholder={translate(fields.businessNameEnglish.placeholder).replace("{{chars}}", maxCharsLength)}
                        name={fields.businessNameEnglish.name}
                        value={signupForm[fields.businessNameEnglish.key] || ""}
                        onChange={handleInputChange}
                        showError={!firstTry && formData[fields.businessNameEnglish.name].isValid?.valid === false}
                        errorMessage={formData[fields.businessNameEnglish.name].isValid?.errMsg}
                    />
                </div>
            </div>

            <Button isNext={true} onClick={handleNextButtonClick} />
        </form>
    );
};

export default BusinessName;
