import "./index.scss";

const HeaderTitle = ({ children }) => {
    if (typeof children === "string" && children?.trim()?.length === 0) {
        return <></>;
    }

    return <h1 className="header-title">{children}</h1>;
};

export default HeaderTitle;
